import React, { useState, useCallback } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./AdicionarHousekeeping.module.css";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarHousekeeping() {

  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [firstLoadTipos, setFirstLoadTipos] = useState(true);
  const [tipos, setTipos] = useState([]);
  const [nextTipos, setNextTipos] = useState("");
  const [tipoValue, setTipoValue] = useState("");
  const urlTipos = Url+"/housekeeping/housekeeping-types/?start=0&limit=500";

  const [firstLoadLocals, setFirstLoadLocals] = useState(true);
  const [locais, setLocais] = useState([]);
  const [nextLocais, setNextLocais] = useState("");
  const [localValue, setLocalValue] = useState("");
  const urlLocais = Url + "/locals?start=0&limit=15";

  const [descriptionValue, setDescriptionValue] = useState("");

  const [urgency, setUrgency] = useState({ id: 1,  urgencia: "baixa" });

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getHousekeepingDetails = useCallback((id) => {
    axios.get(Url+"/housekeeping/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      if (res.data.status.name === "Fechada"){
        navigate(-1);
      }
      if (res.data.created_by.id === EncryptDecryptStorage("decrypt").user.id || (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.DELETE)) {
        setLocalValue(res.data.local);
        setTipoValue(res.data.housekeeping_types);
        setDescriptionValue(res.data.description);
        setUrgency({id: res.data.priority.id, urgencia: res.data.priority.id === 1  ? "baixa" : res.data.priority.id === 2 ? "normal" : res.data.priority.id === 3 ? "alta" : res.data.priority.id === 4 ? "urgente" : ""});
        await setLoading(false);
      }
      else {
        navigate("/404");
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getHousekeepingDetails(id) : window.location.reload();
      }
      await setLoading(false);
    })
  },[navigate]);

  const getLocais = async (urlLocais, search) => {
    await axios.get(urlLocais, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setNextLocais(res.data.next);
      if (search){
        setLocais(res.data.detail);
        setFirstLoadLocals(true);
      }
      else {
        if (firstLoadLocals){
          setLocais(res.data.detail);
          setFirstLoadLocals(false);
        }
        else {
          setLocais(prevLocals => [...prevLocals, ...res.data.detail]);
        }
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLocais(urlLocais, search) : window.location.reload();
      }
      else {
        setLocais([]);
        setNextLocais("");
      }
    })
  }

  const getTipos = async (urlTipos, search) => {
    axios.get(urlTipos, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setNextTipos(res.data.next);
      if (search){
        setTipos(res.data.detail);
        setFirstLoadTipos(true);
      }
      else {
        if (firstLoadTipos){
          setTipos(res.data.detail);
          setFirstLoadTipos(false);
        }
        else {
          setTipos(prevTipos => [...prevTipos, ...res.data.detail]);
        }
      }
      if (!id)
        setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTipos(urlTipos, search) : window.location.reload();
      }
      else {
        setTipos([]);
        setNextTipos("");
      }
      if (!id)
        setLoading(false);
    })
  };

  useEffect(() => {
    if (id){
      getHousekeepingDetails(id);
    }
  }, [id, getHousekeepingDetails]);

  useEffect(() => {
    getLocais(urlLocais);
  }, [urlLocais]);
  
  useEffect(() => {
    getTipos(urlTipos);
  }, [urlTipos]);


  const addHousekeeping = async () => {
    if (tipoValue === "" || localValue === ""){
      return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .post(Url + "/housekeeping", {
        local_id: localValue.local.id,
        housekeeping_type_id: tipoValue.id ? tipoValue.id : null,
        priority_id: urgency.id,
        description: descriptionValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Pedido criado com sucesso</Alert>);
        setTimeout(() => {
          window.location = "/housekeeping";
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addHousekeeping() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar pedido</Alert>); 
        }
      });
  }

  const editHousekeeping = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .put(Url + "/housekeeping/"+id, {
        local_id: localValue.id ? localValue.id : localValue.local.id,
        housekeeping_types_id: tipoValue.id ? tipoValue.id : null,
        priority_id: urgency.id,
        description: descriptionValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Pedido editado com sucesso</Alert>);
        setTimeout(() => {
          navigate(0);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editHousekeeping(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao editar pedido</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "housekeeping #"+id : "housekeeping"}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/housekeeping" state={ state ? {from: (state.from === "detalhes" ? "" : state.from), page: state.page ? state.page : "", next: state.next ? state.next : "", previous: state.previous ? state.previous : ""} : ""}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <Dropdown
              label={"Local do pedido"}
              placeholder={"Escolher local do pedido"}
              itemsWidth={"40vw"}
              items={locais}
              onUrlChange={getLocais}
              value={localValue}
              onValueChange={setLocalValue}
              nextUrl={nextLocais}
              search={true}
              required={true}
              urlSearch={"/locals"}
            />
            <Dropdown 
              label={"Tipo de pedido"}
              placeholder={"Escolher tipo de pedido"}
              itemsWidth={"40vw"}
              value={tipoValue}
              onValueChange={setTipoValue}
              items={tipos}
              onUrlChange={getTipos}
              search={true}
              required={true}
              urlSearch={"/housekeeping/housekeeping-types/"}
            />
            <div className={styles.radios}>
                <button className={urgency.id === 1 ? styles.baixa : styles.defaultButton} onClick={() => { setUrgency({ id: 1,  urgencia: "baixa" }); }}>Baixa</button>
                <button className={urgency.id === 2 ? styles.normal : styles.defaultButton} onClick={() => { setUrgency({ id: 2,  urgencia: "normal" }); }}>Normal</button>
                <button className={urgency.id === 3 ? styles.alta : styles.defaultButton} onClick={() => { setUrgency({ id: 3,  urgencia: "alta" }); }}>Alta</button>
                <button className={urgency.id === 4 ? styles.urgente : styles.defaultButton} onClick={() => { setUrgency({ id: 4,  urgencia: "urgente" }); }}>Urgente</button>
            </div>
            <div className={styles.formField}>
                <p className={styles.descriptionFormfield}>Descrição</p>
                <textarea
                  defaultValue={descriptionValue}
                  rows={3}
                  placeholder="Descreva o pedido"
                  onChange={(value) => setDescriptionValue(value.target.value)}
                />
            </div>
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editHousekeeping(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addHousekeeping()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarHousekeeping;