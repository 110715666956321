function GetDate(date, onlyDate) {
    const moment = require("moment");
    const formattedDate = moment(date, "YYYY-MM-DD, HH:mm").toDate();
    var newDate = "";
    if (onlyDate)
        newDate = moment(formattedDate).format("DD/MM/YYYY");
    else
        newDate = moment(formattedDate).format("DD/MM/YYYY, HH:mm");
    return newDate;
}

export default GetDate;