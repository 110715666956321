import React, { useState, useCallback, useRef } from "react";
import Layout from "../../Components/Layout/Layout";
import styles from "./DutyReport.module.css";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import LoadingAnimationOnly from "../../Components/LoadingAnimationOnly/LoadingAnimationOnly";
import Alert from "../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp } from "react-icons/io5";
import { MdAdd, MdDownload } from "react-icons/md";
import axios from "axios";
import { Url } from "../../Constants/globals";
import RefreshToken from "../../RefreshToken/RefreshToken";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import { HiOutlineCheck, HiOutlineXMark, HiOutlineInformationCircle } from "react-icons/hi2";
import EncryptDecryptStorage from "../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Duty() {

  const navigate = useNavigate();
  
  const scrollRecordsRef = useRef();
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingModal, setLoadingModal] = useState("");
  const [alert, setAlert] = useState(false);

  const [modalExport, setModalExport] = useState(false);

  const [firstLoadRecords, setFirstLoadRecords] = useState(true);
  const [urlRecords, setUrlRecords] = useState(Url+"/duty/records/dates/?start=0&limit=10");
  const [records, setRecords] = useState([]);
  const [nextRecords, setNextRecords] = useState("");

  const urlGroups = Url+"/duty/groups/";
  const [groups, setGroups] = useState([]);
  const [groupValue, setGroupValue] = useState("");
  const [deleteModalGroup, setDeleteModalGroup] = useState(false);

  const [done, setDone] = useState(false);
  const [referenceValue, setReferenceValue] = useState("");
  const [arrivalsValue, setArrivalsValue] = useState("0");
  const [departuresValue, setDeparturesValue] = useState("0");
  const [adultsValue, setAdultsValue] = useState("0");
  const [childrenValue, setChildrenValue] = useState("0");
  const [occupationValue, setOccupationValue] = useState("0");
  const [managerValue, setManagerValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [complaintsValue, setComplaintsValue] = useState("");

  const [updatedDuties, setUpdatedDuties] = useState("");

  const [groupData, setGroupData] = useState({});

  const formattedDateTime = `${pad(new Date().getDate())}/${pad(
    new Date().getMonth() + 1
  )}/${new Date().getFullYear()} - ${pad(new Date().getHours())}:${pad(
    new Date().getMinutes()
  )}`;

  function pad(num) {
    return num.toString().padStart(2, "0");
  }

  const handleScrollData = (e) => {
    e.preventDefault();
    if (modalExport && nextRecords && scrollRecordsRef) {
      const endOfDiv = Math.round(e.target.scrollTop + e.target.clientHeight);
      if ((endOfDiv >= e.target.scrollHeight) && (nextRecords !== "")){
        setUrlRecords(nextRecords);
        setTimeout(() => {
          const scrollContainer = scrollRecordsRef.current;
          const desiredScrollPosition = scrollContainer.scrollHeight - scrollContainer.clientHeight - 10;
          scrollContainer.scrollTo({
            top: desiredScrollPosition,
            behavior: "smooth"
          });
        }, 500);
      }
    }
  }
  

  const getGroups = (urlGroups) => {
    axios.get(urlGroups, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setGroups(res.data.detail);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getGroups(urlGroups) : window.location.reload();
      }
      else {
        setGroups([]);
        setLoading(false);
      }
    })
  };

  const getRecords = async (urlRecords) => {
    axios.get(urlRecords, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      if (firstLoadRecords) {
        setRecords(res.data.detail);
        setFirstLoadRecords(false);
      } else {
        setRecords(prevRecords => [...prevRecords, ...res.data.detail]);
      }
      setNextRecords(res.data.next);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getRecords(urlRecords) : window.location.reload();
      }
      else {
        setRecords([]);
      }
    })
  };

  const getGroupId = useCallback((id) => {
    setLoadingReport(true);
    axios.get(Url+"/duty/records/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setReferenceValue(res.data.id);
      setArrivalsValue(res.data.arrivals);
      setDeparturesValue(res.data.departures);
      setOccupationValue(res.data.occupation);
      setAdultsValue(res.data.adults.toString());
      setChildrenValue(res.data.children.toString());
      setComplaintsValue(res.data.complaints);
      setGroupData(res.data);
      setUpdatedDuties(res.data.duty_areas);
      setDateValue(res.data.date ? res.data.date : formattedDateTime);
      setDone(res.data.done);
      setManagerValue(res.data.users ? res.data.users : (EncryptDecryptStorage("decrypt").user.first_name+" "+EncryptDecryptStorage("decrypt").user.last_name));
      setLoading(false);
      setLoadingReport(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getGroupId(id) : window.location.reload();
      }
      else {
        setGroupData([]);
        setLoading(false);
        setLoadingReport(false);
      }
    })
  }, [formattedDateTime]);

  const deleteGroup = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/duty/groups/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Duty report eliminado com sucesso</Alert>);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteGroup(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao eliminar duty report</Alert>);
        }
      });
  }

  const saveDuty = async (done) => {
    await setAlert();
    let arrayDuties = [];
    for (let a = 0; a < groupData.duty_areas.length; a++) {
      for (let b = 0; b < groupData.duty_areas[a].duties.length; b++) {
        arrayDuties.push(
          {
            duty_id: groupData.duty_areas[a].duties[b].id,
            done: groupData.duty_areas[a].duties[b].values.done,
            complaints: groupData.duty_areas[a].duties[b].values.complaints,
            duty_area_id: groupData.duty_areas[a].id
          }
        );
      }
    }
    setSpinner(true);
    await axios
      .put(Url + "/duty/records/"+referenceValue+"/group/"+groupValue.id, {
        manager_on_duty: {
          arrivals: arrivalsValue,
          departures: departuresValue,
          occupation: occupationValue,
          pax: (parseInt(childrenValue) && parseInt(adultsValue)) ? parseInt(childrenValue)+parseInt(adultsValue) : (childrenValue === "0" && adultsValue === "0") ? "0" : (childrenValue === "0" && adultsValue !== "0") ? adultsValue : (childrenValue !== "0" && adultsValue === "0") ? childrenValue : "",
          adults: adultsValue,
          children: childrenValue,
          complaints: complaintsValue,
          done: done
        },
        duties: arrayDuties,
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>{done ? "Duty report concluído com sucesso" : "Duty report guardado com sucesso"}</Alert>);
        if (done){
          getRecords(urlRecords);
          getGroups(urlGroups);
          await getGroupId(groupValue.id);
        }
        setSpinner(false);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? saveDuty(done) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : done ? "Erro ao concluir duty report" : "Erro ao gravar duty report"}</Alert>);
        }
      });
  }

  const exportRecords = async (date, downloadLink) => {
    await setSpinner(true);
    await setAlert("");
    try {
      const response = await fetch(downloadLink, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `MOD_${date}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      await setSpinner(false);
    } catch (error) {
      if (error.request && error.request.status === 401){
        var newToken = await RefreshToken(error);
        newToken ? exportRecords(date, downloadLink) : window.location.reload();
      }
      console.error(error);
      await setSpinner(false);
      return setAlert(<Alert type={"error"}>Ocorreu um erro ao exportar o documento</Alert>);
    }
  };

  useEffect(() => {
    if (groupValue)
      getGroupId(groupValue.id);
  }, [groupValue]);

  useEffect(() => {
    getRecords(urlRecords);
  }, [urlRecords]);

  useEffect(() => {
    if (EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.GET){
      getGroups(urlGroups);
    }
    else {
      navigate("/404")
    }
  }, [urlGroups]);

  const ModalExport = () => {
    return (
      <div className={styles.modalBody}>
        <div className={styles.backgroundModal}>
          <div className={styles.recordWrapper} ref={scrollRecordsRef} onScroll={(e) => handleScrollData(e)}>
            <div className={styles.recordContainer}>
              <h4>Data</h4>
            </div>
            {
              records.length > 0 ?
                records.map((record, index) =>
                  <div key={index} >
                      { index === 0 ? "" : <hr style={{color:"var(--background-content)"}}/>}
                      <div className={styles.recordContainer2}>
                          <p className={styles.recordText}>{record.date}</p>
                          <MdDownload style={{cursor: "pointer"}} onClick={() => exportRecords(record.date, Url + `/files/duty?date=${record.date}`)}/>
                      </div>
                  </div>
                )
              :
              <p>Sem registos</p>
            }
          </div>
          <div className={styles.btnsModal}>
            <button onClick={() => setModalExport(false)}>
              Regressar
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Layout tab={"duty report"}>
        {alert}
        {modalExport ? <ModalExport/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
          {deleteModalGroup ? <DeleteModal onClose={setDeleteModalGroup} headerText={"ELIMINAR DUTY REPORT"} confirmationText={"Deseja eliminar "+groupValue.name+" ?"} deleteFunction={deleteGroup} objectId={groupValue.id}/> : ""}
          <div className={styles.titleIcon}>
            <h1 className={styles.dutyTitle}>Manager on duty report</h1>
          </div>

          <div className={styles.addAndGrid}>
            <div style={{display: "flex", gap: "20px"}}>
              {
                EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE ?
                <NavLink className={styles.addDuty} to={"/backoffice/duty/grupos/adicionar"}>
                  <MdAdd className={styles.addIcon} size={22} />
                  Criar duty reports
                </NavLink>
                :""
              }
              <div className={styles.addDuty} onClick={() => setModalExport(true)}>
                <MdDownload className={styles.addIcon} size={20} />
                Exportar reports
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
            <div className={styles.gridReports}>
              {
                groups.map((group) =>
                <div key={group.id} title={group.done ? "Este duty já foi realizado hoje!" : ""} onClick={() => setGroupValue(group)} style={group.done ? {color: "#888"}: {}} className={groupValue === group ? styles.selectedReport : styles.chooseReport}>
                  <h5 className={styles.chooseReportName}>{group.name} {group.done ?<HiOutlineCheck size={16} color={"#21ba4590"} /> : ""}</h5>
                  <div className={styles.icons}>
                    { EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE ? <IoPencilSharp onClick={() => navigate("/backoffice/duty/grupos/editar/"+group.id)} className={styles.edit}/> : ""}
                    { EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE ? <IoTrashSharp onClick={async () => {await setGroupValue(group); await setDeleteModalGroup(true)}} className={styles.delete}/> : ""}
                  </div>
                </div>
                )
              }
            </div>
            {
              loadingReport ?
              <LoadingAnimationOnly/>
              :
                groupValue ?
                <div className={styles.areasContainer}>
                  <div className={styles.gridFields}>
                    <div className={styles.pForm}>
                      <p className={ referenceValue ? styles.inputLabel : styles.inputLabelNull}>Reference</p>
                      <input
                        className={referenceValue ? styles.input : styles.inputNull}
                        disabled
                        defaultValue={referenceValue}
                        rows={3}
                      />
                    </div>
                    <div className={styles.pForm}>
                      <p className={ managerValue ? styles.inputLabel : styles.inputLabelNull}>Manager on duty</p>
                      <input
                        className={managerValue ? styles.input : styles.inputNull}
                        disabled
                        defaultValue={managerValue}
                        rows={3}
                        onChange={(value) => setManagerValue(value.target.value)}
                      />
                    </div>
                    <div className={styles.pForm}>
                      <p className={styles.inputLabel}>Date - Hour</p>
                      <input
                        className={styles.input}
                        disabled
                        defaultValue={dateValue}
                        rows={3}
                      />
                    </div>
                    
                    <div className={styles.pForm}>
                      <p className={ arrivalsValue ? styles.inputLabel : styles.inputLabelNull}>Arrivals</p>
                      <input
                        disabled={done}
                        className={arrivalsValue ? styles.input : styles.inputNull}
                        defaultValue={arrivalsValue}
                        rows={3}
                        onChange={(value) => setArrivalsValue(value.target.value)}
                      />
                    </div>
                    <div className={styles.pForm}>
                      <p className={ departuresValue ? styles.inputLabel : styles.inputLabelNull}>Departures</p>
                      <input
                        disabled={done}
                        className={departuresValue ? styles.input : styles.inputNull}
                        defaultValue={departuresValue}
                        rows={3}
                        onChange={(value) => setDeparturesValue(value.target.value)}
                      />
                    </div>
                    <div className={styles.pForm}>
                      <p className={styles.inputLabel}>OCC (%)</p>
                      <div className={styles.inputWithSuffix}>
                        <input
                          disabled={done}
                          className={ done ? styles.inputSuffixNull : styles.inputSuffix }
                          defaultValue={occupationValue}
                          rows={3}
                          onChange={(value) => setOccupationValue(value.target.value)}
                        />
                        <p className={done ? styles.suffixNull : styles.suffix}>%</p>
                      </div>
                    </div>
                    
                    <div className={styles.pForm}>
                      <p className={ adultsValue ? styles.inputLabel : styles.inputLabelNull}>Total adults</p>
                      <input
                        disabled={done}
                        className={adultsValue ? styles.input : styles.inputNull}
                        defaultValue={adultsValue}
                        rows={3}
                        onChange={(value) => setAdultsValue(value.target.value)}
                      />
                    </div>
                    <div className={styles.pForm}>
                      <p className={ childrenValue ? styles.inputLabel : styles.inputLabelNull}>Total children</p>
                      <input
                        disabled={done}
                        className={childrenValue ? styles.input : styles.inputNull}
                        defaultValue={childrenValue}
                        rows={3}
                        onChange={(value) => setChildrenValue(value.target.value)}
                      />
                    </div>
                    <div className={styles.pForm}>
                      <p className={styles.inputLabel}>Pax</p>
                      <input
                        className={styles.input}
                        disabled
                        value={(parseInt(childrenValue) && parseInt(adultsValue)) ? parseInt(childrenValue)+parseInt(adultsValue) : (childrenValue === "0" && adultsValue === "0") ? "0" : (childrenValue === "0" && adultsValue !== "0") ? adultsValue : (childrenValue !== "0" && adultsValue === "0") ? childrenValue : ""}
                        rows={3}
                      />
                    </div>
                  </div>


                  <div style={{marginBottom: "20px"}}>
                  {
                    groupData.duty_areas && groupData.duty_areas.length > 0 ?
                    groupData.duty_areas.map((area) =>
                    <div key={area.id}>
                      <h3 className={styles.areaName}>{area.name}</h3>
                      <div className={styles.dutyWholeContainer}>
                        {
                          area.duties.length > 0 ?
                          area.duties.map((duty) => (
                            duty.values ?
                            <div className={styles.dutyFormGrid} key={duty.id}>
                              <p className={styles.dutyName}>{duty.name}</p>
                              <div className={styles.checkboxes}>
                                <div onClick={() => {if (!done) duty.values.done = true; setUpdatedDuties([...updatedDuties]);}} style={done ? {cursor: "default"}:{}} className={ duty.values.done === true ? styles.checkboxTrue : styles.checkbox}><HiOutlineCheck size={14} color={duty.values.done === true ? "#21ba45" : "#888"}/></div>
                                <div onClick={() => {if (!done) duty.values.done = false; setUpdatedDuties([...updatedDuties]);}} style={done ? {cursor: "default"}:{}} className={ duty.values.done === false ? styles.checkboxFalse : styles.checkbox}><HiOutlineXMark size={14} color={duty.values.done === false ? "#ff4f5a" : "#888"}/></div>
                              </div>
                              {
                                done ?
                                <p className={styles.inputDuty}>{duty.values.complaints ? duty.values.complaints : "-"}</p>
                                :
                                <input disabled={done} className={styles.inputDuty} placeholder="Observações" value={duty.values.complaints ? duty.values.complaints : ""} onChange={(value) => {duty.values.complaints = value.target.value; setUpdatedDuties([...updatedDuties])}}/>
                              }
                            </div>
                            : ""
                          ))
                          : 
                          <div style={{display: "flex", justifyContent: "center"}}>
                            <div className={styles.noData}>
                              <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                              <p className={styles.noDataText}>Esta área não apresenta duties, pode adicioná-los no BackOffice.</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    )
                    : ""
                  }
                  </div>


                  <div style={{marginBottom: "20px"}}>
                    <h3 className={styles.areaName}>complaints</h3>
                    { done ?
                    <p className={styles.complaintsInput}>{complaintsValue}</p>
                    :
                    <textarea disabled={done} className={styles.complaintsInput} placeholder="Escreva aqui notas relevantes..." defaultValue={complaintsValue} onChange={(value) => setComplaintsValue(value.target.value)} rows={"5"}></textarea>
                    }
                  </div>
                </div>
                : ""
            }
            </div>
          </div>
          
            {
              groupValue && !done && !loadingReport ?
              <div className={styles.buttons}>
                <button className={styles.submitBtn} onClick={() => saveDuty(false)} type="">Gravar</button>
                <button className={styles.submitBtn} onClick={() => saveDuty(true)} type="">Concluir</button>
              </div>
              : ""
            }
        </div>
        }
      </Layout>
    </>
  );
}

export default Duty;