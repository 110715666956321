import React from "react";

import styles from "./NotFound.module.css";

import { HiArrowLongLeft } from 'react-icons/hi2';
import { NavLink } from "react-router-dom";
import { BsGearWide } from "react-icons/bs";

function NotFound() {
  
  return (
    <div className={styles.body}>
      <div className={styles.center}>
        <div className={styles.column}>
          <BsGearWide className={styles.gearLogo} color={"var(--graph-text)"} size={90}/>
          <h1>404</h1>
          <p>
            Página não encontrada
          </p>
          <p>Clique em baixo para voltar ao Solmant</p>
          <NavLink to={"/"} className={styles.circle}><HiArrowLongLeft color={"var(--button-textcolor)"} size={"2rem"}/></NavLink>
        </div>
      </div>
    </div>
  );
}

export default NotFound;