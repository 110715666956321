import styles from "./MultiSelect.module.css";
import { GoTriangleRight } from "react-icons/go";
import { IoSearch, IoCloseOutline, IoCheckmarkSharp, IoArrowForwardCircleOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Url } from "../../Constants/globals";
import { useDebounce } from "../Debounce/Debounce";

function MultiSelect({disabled, label, placeholder, itemsWidth, value, onValueChange, items, onUrlChange, nextUrl, previousUrl, pagination, search, required, urlSearch, dropdownWidth}) {

  const dropdownRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);

  // useCallback atualiza o valor passado na outra página
  const handleChange = useCallback( async newValue => {
    if (JSON.stringify(value).includes(JSON.stringify(newValue))){
        popSelectedItem(newValue);
    }
    else {
        await onValueChange([...value, newValue]);
    }
  }, [onValueChange, value]);

  useEffect(() => {
    if (searchName.length > 0) {
      if (debouncedSearchName.length > 0) {
        onUrlChange(Url+urlSearch+"?name="+debouncedSearchName+"&start=0&limit=16");
      }
      else {
        onUrlChange(Url+urlSearch+"?start=0&limit=16");
      }
    }
  }, [debouncedSearchName]);

  const handlePageChange = useCallback( value => {
    onUrlChange(value);
  }, [onUrlChange]);

  const searchData = useCallback(name => {
    if (name.length === 0) {
      onUrlChange(Url+urlSearch+"?start=0&limit=16");
    }
    setSearchName(name);
  });

  const popSelectedItem = useCallback( async val => {
    const newArr = await value.filter((item) => JSON.stringify(item) !== JSON.stringify(val));
    await onValueChange(newArr);
  }, [onValueChange, value]);

  return (
        <div style={{width: "100%"}} className={styles.form}>
        {
          openDropdown && !disabled ?
        <div className={styles.bodyMulti}>
            <div className={styles.dropdownItems}>
                <div ref={dropdownRef} className={styles.items} style={{ width: itemsWidth }}>
                <h4 className={styles.title}>{placeholder}</h4>
                {
                    search ?
                    <div className={styles.search}>
                    <IoSearch className={styles.searchIcon}  />
                    <input autoFocus placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
                    </div>
                    : ""
                }
                    <div className={styles.containerItems}>
                      {
                          items.map((v) => (
                          <div key={v.id ? v.id : v.local ? v.local.id : ""} className={JSON.stringify(value).includes(JSON.stringify(v)) ? styles.chosenItem : styles.item} onClick={() => handleChange(v) }>
                              <div className={JSON.stringify(value).includes(JSON.stringify(v)) ? styles.checkedSquare : styles.checkSquare}>
                                  {JSON.stringify(value).includes(JSON.stringify(v)) ? <IoCheckmarkSharp color={"var(--background)"}/> : ""}
                              </div>
                              <p style={{fontSize: "12pt"}}>{v.reference ? v.reference+ " - " : ""} {v.name} {v.unit ? "("+v.unit.name+")" : ""}</p>
                              {
                                label === "Locais" ?
                                <label>{v.building.name} {v.block ? " • "+v.block.name : ""} {v.floor ? " • "+v.floor.name : ""}</label>
                                :""
                              }
                          </div>
                          ))
                      }
                    </div>
                    { pagination ?
                      <div className={styles.changePages}>
                      {previousUrl ? <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previousUrl) }}/> : <IoArrowBackCircleOutline color={"var(--input-background)"} size={40}/>}
                      {nextUrl ? <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(nextUrl) }}/> : <IoArrowForwardCircleOutline color={"var(--input-background)"} size={40}/>}
                    </div>
                    : ""
                    }
                    <button className={styles.confirmBtn} onClick={() => {setOpenDropdown(false);}}>
                        Voltar
                    </button>
                </div>
            </div>
        </div>
        :
        <></>
        }
        <div style={{width: dropdownWidth ? dropdownWidth : ""}} className={(value.length === 0 && required) ? styles.dropdownNull : styles.dropdown}>
            { label === false ? "" : <p style={{borderBottom: value.length > 0 ? 0 : "", borderBottomLeftRadius: value.length > 0 ? 0 : ""}}>{label}</p> }
            <div className={styles.dropdownLabel} style={{color: value === "" ? "" : "var(--text-primary)", cursor: disabled ? "default" : "pointer", borderBottomLeftRadius: !label ? "4px" : "", borderTopLeftRadius: !label ? "4px" : "", borderBottom: value.length > 0 ? 0 : "", borderBottomRightRadius: value.length > 0 ? 0 : "" }} onClick={() => {setOpenDropdown(true)}}>
                { placeholder }
                <GoTriangleRight size={14} color={ disabled ? "var(--placeholder-color)" : "var(--text-primary)"} />
            </div>
        </div>
        
            {
                value.length > 0 ? 
                <div className={styles.results}>
                {(
                    value.map((val) => (
                        <div key={val.id ? val.id : val.local ? val.local.id : ""} className={styles.result}>{val.name} {val.unit ? val.unit.name : ""} <IoCloseOutline style={{cursor: "pointer"}} onClick={() => {popSelectedItem(val)}}/></div>
                    ))
                )}
                </div>
                : ""
            }
        </div>
  );
}

export default MultiSelect;
