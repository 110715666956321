import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AddFormula.module.css";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import { HiX } from "react-icons/hi";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import { FaMinus, FaPlus, FaDivide, FaTimes } from "react-icons/fa";
import { IoSearch, IoArrowBackCircleOutline, IoArrowForwardCircleOutline } from "react-icons/io5";
import Alert from "../../../../Components/Alert/Alert";

function AddFormula() {

  const { id } = useParams();
  const [alert, setAlert] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [addNumberValue, setAddNumberValue] = useState();
  const [formulas, setFormulas] = useState([]);
  const [previousFormulas, setPreviousFormulas] = useState("");
  const [nextFormulas, setNextFormulas] = useState("");
  const [formula, setFormula] = useState([]);
  const [selectedFormulaIndex, setSelectedFormulaIndex] = useState({});
  const [tasks, setTasks] = useState([]);
  const [previousTasks, setPreviousTasks] = useState("");
  const [nextTasks, setNextTasks] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchNameFormulas, setSearchNameFormulas] = useState("");
  const operations = [
    {
      name: "Tarefa"
    },
    {
      name: "Número"
    },
    {
      name: "Fórmula"
    },
    {
      name: "Operadores matemáticos"
    }
  ];
  const [selectedOperation, setSelectedOperation] = useState("Tarefa");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  function getTasks (page, search) {
    axios.get(`${Url}/formulas/tasks?page=${page ? page : 1}&per_page=5${search ? `&search=${search}` : ""}`, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setTasks(res.data.data);
      setNextTasks(res.data.next_page);
      setPreviousTasks(res.data.prev_page);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTasks(page, search) : window.location.reload();
      }
      setTasks([]);
      setNextTasks("");
      setPreviousTasks("");
      setLoading(false);
    })
  };

  function getFormulaDetails () {
    axios.get(`${Url}/apiV2/formulas/${id}`, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      const array = JSON.parse(res.data.formula);
      let finalArray = [];
      setNameValue(res.data.name);
      for(const f of array){
        finalArray.push(
          {
            type: f.type,
            value: f.value,
            label: f.label,
            deleted: false,
          }
        )
      }
      setSelectedOperation("Operadores matemáticos");
      setFormula(finalArray);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getFormulaDetails() : window.location.reload();
      }
    })
  };

  function getFormulas (page, search) {
    axios.get(`${Url}/apiV2/formulas?page=${page ? page : 1}&per_page=5${search ? `&search=${search}` : ""}`, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setFormulas(res.data.data);
      setNextFormulas(res.data.next_page);
      setPreviousFormulas(res.data.prev_page);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getFormulas(page, search) : window.location.reload();
      }
      setFormulas([]);
      setNextFormulas("");
      setPreviousFormulas("");
    })
  };

  async function addFormula() {
    await setAlert("");
    if (nameValue.trim() === "") return setAlert(<Alert type="error">Indique o nome da fórmula</Alert>);
    let finalArray = [];
    for (const f of formula) {
      if (f.deleted === false) finalArray.push({type: f.type, value: f.value, label: f.label});
    }
    if (finalArray.length === 0) return setAlert(<Alert type="error">A fórmula não pode ser vazia</Alert>)
    if (finalArray[finalArray.length - 1].type === "symbol") return setAlert(<Alert type="error">Fórmula incorreta. A fórmula não pode terminar com um operador.</Alert>)
    for (let i = 0; i < finalArray.length; i++) {
      if (i % 2 === 0){
        if (finalArray[i].type === "symbol") return setAlert(<Alert type="error">Fórmula incorreta. Verifique se a sequência de elementos está correta.</Alert>);
      }
      else {
        if (finalArray[i].type !== "symbol") return setAlert(<Alert type="error">Fórmula incorreta. Verifique se a sequência de elementos está correta.</Alert>);
      }
    }
    await axios.post(`${Url}/apiV2/formulas`, {
      name: nameValue,
      formula: JSON.stringify(finalArray)
    }, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(() => {
      setAlert(<Alert type={"success"}>Fórmula gravada com sucesso</Alert>);
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? addFormula() : window.location.reload();
      }
      else {
        setAlert(<Alert type={"error"}>Erro ao gravar fórmula</Alert>);
      }
    });
  }

  async function editFormula() {
    await setAlert("");
    if (nameValue.trim() === "") return setAlert(<Alert type="error">Indique o nome da fórmula</Alert>);
    let finalArray = [];
    for (const f of formula) {
      if (f.deleted === false) finalArray.push({type: f.type, value: f.value, label: f.label});
    }
    if (finalArray.length === 0) return setAlert(<Alert type="error">A fórmula não pode ser vazia</Alert>)
    if (finalArray[finalArray.length - 1].type === "symbol") return setAlert(<Alert type="error">Fórmula incorreta. A fórmula não pode terminar com um operador.</Alert>)
    for (let i = 0; i < finalArray.length; i++) {
      if (i % 2 === 0){
        if (finalArray[i].type === "symbol") return setAlert(<Alert type="error">Fórmula incorreta. Verifique se a sequência de elementos está correta.</Alert>);
      }
      else {
        if (finalArray[i].type !== "symbol") return setAlert(<Alert type="error">Fórmula incorreta. Verifique se a sequência de elementos está correta.</Alert>);
      }
    }
    await axios.put(`${Url}/apiV2/formulas/${id}`, {
      name: nameValue,
      formula: JSON.stringify(finalArray),
      is_active: true
    }, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(() => setAlert(<Alert type={"success"}>Fórmula gravada com sucesso</Alert>))
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? editFormula() : window.location.reload();
      }
      else setAlert(<Alert type={"error"}>Erro ao gravar fórmula</Alert>);
    });
  }

  useEffect(() => {
    if (EncryptDecryptStorage("decrypt").user.permissions.costs && EncryptDecryptStorage("decrypt").user.permissions.costs.GET) {
      if (id) getFormulaDetails();
      getFormulas();
      getTasks();
    }
    else navigate("/404")
  }, []);

  async function addValueToFormula(value, type, n){
    await setAlert("");
    if (type === "Número"){
      if (formula[formula.length - 1]?.value === "/" && formula[formula.length - 1]?.deleted === false && value === "0") return setAlert(<Alert type="error">Não é possível dividir por 0</Alert>)
      setFormula([...formula, {
        type: "int",
        value: value,
        label: value,
        deleted: false
      }]);
      setSelectedOperation("Operadores matemáticos");
      setAddNumberValue("");
    }
    else if (type === "Operadores matemáticos"){
      setFormula([...formula, {
        type: "symbol",
        value: value,
        label: value,
        deleted: false
      }])
      setSelectedOperation("Tarefa");
    }
    else if (type === "Tarefa"){
      const finalValue = `${value.id},${value.intervention.id},${value.job.id}`
      setFormula([...formula, {
        type: "tarefas",
        value: {
          value: finalValue,
          type: n
        },
        label: `${value.name} (${n})`,
        deleted: false
      }])
      setSelectedOperation("Operadores matemáticos");
    }
    else if (type === "Fórmula"){
      setFormula([...formula, {
        type: "consumos",
        value: value.id,
        label: value.name,
        deleted: false
      }])
      setSelectedOperation("Operadores matemáticos");
    }
  }

  function lastPositionNotDeleted(form){
    const array = form ? form : formula;
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].deleted === false) return array[i];
    }
  }

  function isOperationPossible(operation){
    if ((isFormulaEmpty() || lastPositionNotDeleted()?.type === "symbol") && operation.name === "Operadores matemáticos") return false;
    else if (["int", "consumos", "tarefas"].includes(lastPositionNotDeleted()?.type) && ["Número", "Tarefa", "Fórmula"].includes(operation.name)) return false;
    return true;
  }

  function handleSelectionFormulaValue(index){
    setSelectedFormulaIndex(index);
  }

  async function removePosition(formulaArray, index, position){
    if (position === "last" && formulaArray[index]){
      for (let i = index; i >= 0; i--) {
        if (formulaArray[i].deleted === false) return formulaArray[i].deleted = true;
      }
    }
    else if (position === "next" && formulaArray[index]) {
      for (let i = index; i < formulaArray.length; i++) {
        if (formulaArray[i].deleted === false) return formulaArray[i].deleted = true; 
      }
    }
    return false;
  }

  async function removeFormulaValue(){
    let form = [...formula];
    if (formula[selectedFormulaIndex]?.type !== "symbol"){
      const removed = await removePosition(form, selectedFormulaIndex - 1, "last");
      if (!removed) await removePosition(form, selectedFormulaIndex + 1, "next");
    }
    form[selectedFormulaIndex].deleted = true;
    setFormula(form);
    if (lastPositionNotDeleted(form)?.type && lastPositionNotDeleted(form)?.type !== "symbol") setSelectedOperation("Operadores matemáticos");
    else setSelectedOperation("Número");
  }

  function isFormulaEmpty(){
    if (formula.length === 0) return true;
    for (const val of formula){
      if (val.deleted === false) return false;
    }
    return true;
  }

  const searchData = (name) => {
    setSearchName(name);
  }

  const searchDataFormulas = (name) => {
    setSearchNameFormulas(name);
  }

  function showFormula(f){
    let final = ``;
    const jsonF = JSON.parse(f);
    for (const fo of jsonF){
      final += `${fo.label} `;
    }
    return final;
  }

  return (
    <>
      <Layout tab={"estatísticas - trabalhos"}>
        {alert}
        { 
          loading ? <></> :
            <div className={styles.content}>
              <NavLink className={styles.goBack} to={"/estatisticas/trabalhos"}>
                <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
              </NavLink>
              <div className={styles.formulaContainer}>                
                <div className={styles.chooseValues} style={selectedOperation ? {} : {borderBottom: 0, marginBottom: 0}}>
                  {
                    operations.map((ope, index) =>
                      <div key={index} style={ isOperationPossible(ope) ? {} : {opacity: 0.4, cursor: "default"}} className={ selectedOperation === ope.name ? styles.activeStatus : styles.status } onClick={() => {if (isOperationPossible(ope)) setSelectedOperation(ope.name); }}>
                        {ope.name}
                      </div>
                    )
                  }
                </div>
                {
                  selectedOperation === "Número" ?
                    <div className={styles.formOperation}>
                      <div className={styles.formField} style={{width: "min(500px, 100%)", justifyContent: "center"}}>
                        <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Número</p>
                        <input
                          type="number"
                          className={styles.contaNameInput}
                          value={addNumberValue}
                          style={{width: "100%"}}
                          onChange={(e) => setAddNumberValue(e.target.value)}
                          placeholder="Insira um número para adicionar à fórmula"
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && addNumberValue) return addValueToFormula(addNumberValue, selectedOperation);
                            else return;
                          }}
                        />
                      </div>
                      {
                        addNumberValue ? <button className={styles.submitBtn} onClick={() => addValueToFormula(addNumberValue, selectedOperation)}>ADICIONAR À FÓRMULA</button> : ""
                      }
                    </div>
                  :""
                }
                {
                  selectedOperation === "Operadores matemáticos" ?
                    <div className={styles.formOperation}>
                      <div className={styles.operations}>
                        <button className={styles.buttonOperation} onClick={() => addValueToFormula("+", selectedOperation)}>
                          <FaPlus size={28}/>
                        </button>
                        <button className={styles.buttonOperation} onClick={() => addValueToFormula("-", selectedOperation)}>
                          <FaMinus size={28}/>
                        </button>
                        <button className={styles.buttonOperation} onClick={() => addValueToFormula("*", selectedOperation)}>
                          <FaTimes size={28}/>
                        </button>
                        <button className={styles.buttonOperation} onClick={() => addValueToFormula("/", selectedOperation)}>
                          <FaDivide size={28}/>
                        </button>
                      </div>
                    </div>
                  :""
                }
                {
                  selectedOperation === "Tarefa" ?
                    <div className={styles.formOperation}>
                      <p className={styles.taskTitle} style={{marginTop: "15px"}}>
                        Tarefas
                      </p>
                      <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
                        <div className={styles.search}>
                          <IoSearch className={styles.searchIcon}  />
                          <input
                            placeholder={"Enter para pesquisar"}
                            onChange={(e) => { searchData(e.target.value) }}
                            onKeyDown={(e) => {if (e.key === "Enter") getTasks("", searchName)}}
                          />
                        </div>
                      </div>
                      <div className={styles.dropdown}>
                        <p className={styles.taskLabel}><b>VMR</b> - Valor Mais Recente</p>
                        <p className={styles.taskLabel}><b>2VMR</b> - 2º Valor Mais Recente</p>
                        {
                          tasks.length > 0 ?
                            tasks.map((task, index) =>
                              <div key={index} className={styles.tasksContainer}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                  <p className={styles.taskName}>
                                    {task.name}
                                  </p>
                                  <p className={styles.labelTask}>
                                    {task.intervention.name} · {task.job.name}
                                  </p>
                                </div>
                                <div className={styles.tasksActions}>
                                  <div className={styles.btnAddTask} onClick={() => addValueToFormula(task, selectedOperation, "VMR")}>VMR</div>
                                  <div className={styles.btnAddTask} onClick={() => addValueToFormula(task, selectedOperation, "2VMR")}>2VMR</div>
                                </div>
                              </div>
                            )
                          :""
                        }
                      </div>
                      <div className={styles.changePages}>
                        {previousTasks ? <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { getTasks(previousTasks, searchName) }}/> : ""}
                        {nextTasks ? <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { getTasks(nextTasks, searchName) }}/> : ""}
                      </div>
                    </div>
                  :""
                }
                {
                  selectedOperation === "Fórmula" ?
                    <div className={styles.formOperation}>
                      <p className={styles.taskTitle} style={{marginTop: "15px"}}>
                        Fórmulas
                      </p>
                      <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
                        <div className={styles.search}>
                          <IoSearch className={styles.searchIcon}  />
                          <input
                            placeholder={"Enter para pesquisar"}
                            onChange={(e) => { searchDataFormulas(e.target.value) }}
                            onKeyDown={(e) => {if (e.key === "Enter") getFormulas("", searchNameFormulas)}}
                          />
                        </div>
                      </div>
                      <div className={styles.dropdown}>
                      {
                        formulas.length > 0 ?
                          formulas.map((formula, index) =>
                            <div key={index} className={styles.tasksContainer}>
                              <div style={{display: "flex", flexDirection: "column"}}>
                                <p title={showFormula(formula.formula)} className={styles.taskName} style={{fontWeight: "normal", margin: "14px 0"}}>
                                  {formula.name}
                                </p>
                              </div>
                              <div className={styles.tasksActions}>
                                <div className={styles.btnAddTask} onClick={() => addValueToFormula(formula, selectedOperation)}>Adicionar</div>
                              </div>
                            </div>
                          )
                        :""
                      }
                      </div>
                      <div className={styles.changePages}>
                        {previousFormulas ? <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { getFormulas(previousFormulas, searchNameFormulas) }}/> : ""}
                        {nextFormulas ? <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { getFormulas(nextFormulas, searchNameFormulas) }}/> : ""}
                      </div>
                    </div>
                  :""
                }
              </div>
              <div className={styles.aroundFormula}>
                <div className={styles.formula}>
                  <div className={styles.formField}>
                    <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome</p>
                    <input
                      style={{fontSize: "14pt"}}
                      className={styles.contaNameInput}
                      value={nameValue}
                      onChange={(e) => setNameValue(e.target.value)}
                      placeholder="E.g: Consumo de gás (kg)"
                    />
                  </div>
                  <h3 className={styles.contaName}>=</h3>
                  <div className={styles.conta}>
                    {
                      isFormulaEmpty()
                      ?
                        <p style={{margin: "5px 10px", color: "var(--placeholder-color)", cursor: "default"}}>(Adicione um elemento em cima)</p>
                      :
                        formula.map((val, index) => 
                          !val.deleted ?
                            <div style={{position: "relative"}}>
                              <p key={index} className={index === selectedFormulaIndex ? styles.selectedFormulaValue : styles.formulaValue} style={{color: val.type === "tarefas" ? "cadetblue" : val.type === "consumos" ? "darkseagreen" : ""}} onClick={() => handleSelectionFormulaValue(index, val)}>{val.label}</p>
                              {
                                selectedFormulaIndex === index ?
                                  <div className={styles.removeValue} onClick={() => removeFormulaValue()}>
                                    <HiX size={12} color={"red"}/>
                                  </div>
                                :""
                              }
                            </div>
                          :""
                        )
                    }
                    {/* <button className={styles.addValue}>
                      <HiPlus size={18}/>
                    </button> */}
                  </div>
                </div>
                <div style={{display: "flex", justifyContent: "center", width: "calc(100% - 240px)"}}>
                <button className={styles.submitBtn} onClick={() => id ? editFormula() : addFormula()}>
                  Guardar fórmula
                </button>
              </div>
              </div>
            </div>
        }
      </Layout>
    </>
  );
}

export default AddFormula;