import React from "react";
import styles from "./Alert.module.css";

function Alert({ children, type }) {
  switch (type) {
    case "error":
      return (
        <div className={`${styles.alert} ${styles.error}`}>
          <p className={styles.alertContent}>{children}</p>
        </div>
      );
    case "success":
      return (
        <div className={`${styles.alert} ${styles.success}`}>
          <p className={styles.alertContent}>{children}</p>
        </div>
      );

    default:
      return (
        <div className={`${styles.alert} ${styles.error}`}>
          <p className={styles.alertContent}>{children}</p>
        </div>
      );
  }
}

export default Alert;
