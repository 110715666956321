import React, { useState, useRef } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./ChangePassword.module.css";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { useParams } from "react-router-dom";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import { NavLink, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function ChangePassword() {

  const divRef = useRef(null);
  const navigate = useNavigate();

  const { id } = useParams();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState("");


  const changePassword = async () => {
    await setAlert("");
    if (oldPassword === "" || password === "" || confirmPassword === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos em branco</Alert>);
    }
    else if (password.length < 8){
      return setAlert(<Alert type={"error"}>A palavra-passe tem de ter pelo menos 8 caracteres</Alert>);
    }
    else if (password !== confirmPassword){
      return setAlert(<Alert type={"error"}>A nova palavra-passe e a sua confirmação não coincidem</Alert>);
    }
    axios.put(Url + "/change-password",
    {
      old_password: oldPassword,
      new_password: password,
      user_id:EncryptDecryptStorage("decrypt").user.id
    },
    {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
      }).then(async(res) => {
        setAlert(<Alert type={"success"}>Palavra-passe alterada com sucesso</Alert>);
      }).catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? changePassword() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.request && err.request.response ? JSON.parse(err.request.response).detail : "Erro ao mudar a palavra-passe"}</Alert>);
        }
      })
  }

  const changePasswordSomeoneElse = async () => {
    await setAlert("");
    if (password === "" || confirmPassword === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos em branco</Alert>);
    }
    else if (password.length < 8){
      return setAlert(<Alert type={"error"}>A palavra-passe tem de ter pelo menos 8 caracteres</Alert>);
    }
    else if (password !== confirmPassword){
      return setAlert(<Alert type={"error"}>A nova palavra-passe e a sua confirmação não coincidem</Alert>);
    }
    axios.put(Url + "/users/"+id,
    {
      password: password,
    },
    {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
      }).then(async(res) => {
        setAlert(<Alert type={"success"}>Palavra-passe alterada com sucesso</Alert>);
      }).catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? changePasswordSomeoneElse() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.request && err.request.response ? JSON.parse(err.request.response).detail : "Erro ao mudar a palavra-passe"}</Alert>);
        }
      })
  }

  useEffect(() => {
    if (id){
      if (EncryptDecryptStorage("decrypt").user.permissions.users && EncryptDecryptStorage("decrypt").user.permissions.users.PUT){
        setLoading(false);
      }
      else {
        navigate("/404");
      }
    }
    else {
      setLoading(false);
    }
  }, [loading])

  return (
    <>
      <Layout tab={id ? "backoffice utilizador #"+id : "backoffice utilizadores"}>
        {alert}
        { 
        loading ? <></> :
        <div ref={divRef} className={styles.content}>
          <NavLink className={styles.goBack} onClick={() => navigate(-1)}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
                    {
                      id ? "":
                      <div className={styles.formField}>
                        <p className={ oldPassword ? styles.inputLabel : styles.inputLabelNull}>Palavra-passe atual</p>
                        <input
                            type="password"
                            rows={3}
                            placeholder="Digite a palavra-passe atual..."
                            onChange={(value) => setOldPassword(value.target.value)}
                        />
                      </div>
                    }
                    <div className={styles.formField}>
                        <p className={ password ? styles.inputLabel : styles.inputLabelNull}>Nova palavra-passe</p>
                        <input
                            type="password"
                            rows={3}
                            placeholder="Digite a nova palavra-passe..."
                            onChange={(value) => setPassword(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={confirmPassword ? styles.inputLabel : styles.inputLabelNull}>Confirmar nova palavra-passe</p>
                        <input
                            type="password"
                            rows={3}
                            placeholder="Confirme a nova palavra-passe..."
                            onChange={(value) => setConfirmPassword(value.target.value)}
                        />
                    </div>
                {
                  id ?
                  <button className={styles.submitBtn} onClick={() => changePasswordSomeoneElse()} type="">Alterar</button>
                  :
                  <button className={styles.submitBtn} onClick={() => changePassword()} type="">Alterar</button>
                }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default ChangePassword;