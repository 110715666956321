import { Url } from "../../Constants/globals";
import axios from "axios";
import RefreshToken from "../../RefreshToken/RefreshToken";
import { useCallback, useState } from "react";
import modal from "../Modal/Modal.module.css"
import Alert from "../Alert/Alert";
import { useNavigate } from "react-router";
import EncryptDecryptStorage from "../EncryptDecryptStorage/EncryptDecryptStorage";

function ChangeStatusPage({malfunctionId, status, showModal, setShowModal, url, type}) {

    const [reason, setReason] = useState("");
    const [alert, setAlert] = useState("");
    const navigate = useNavigate();

    function changeStatus(id, status) {
        setAlert();
        var jason = EncryptDecryptStorage("decrypt");

        let body = {
            status_id: status,
            reason: reason
        };

        if ((status === 3 && reason === "" && type === "avaria") || (status === 6 && reason === "" && type === "avaria")){
            return setAlert(<Alert type={"error"}>Indique a razão da mudança de estado</Alert>);
        }
        else {
            axios
            .put(Url + url+id, 
            body, {
                headers: {
                Authorization: "Bearer "+jason.token,
                }
            },)
            .then(async (res) => {
                navigate(0);
            })
            .catch(async (err) => {
                if (err.request && err.request.status === 401){
                    var newToken = await RefreshToken(err);
                    newToken ? changeStatus(id, status) : window.location.reload();
                }
                else {
                    setAlert(<Alert type={"error"}>{err.response.data.detail ? err.response.data.detail : "Erro ao mudar estado"}</Alert>);
                }
            });
        }
    }

    function joinMalfunction(id) {
        var jason = EncryptDecryptStorage("decrypt");

        axios
        .post(Url + url +id+"/join", 
        {}, {
            headers: {
            Authorization: "Bearer "+jason.token,
            }
        },)
        .then(async (res) => {
            navigate(0);
        })
        .catch(async (err) => {
            if (err.request && err.request.status === 401){
                var newToken = await RefreshToken(err);
                newToken ? joinMalfunction(id) : window.location.reload();
            }
            else {
                return "error";
            }
        });
    }

    const handlePopOut =  useCallback( value => {
        setShowModal([value, malfunctionId, status]);
    })

    return (
        <>
        {alert}
        {
            <div className={showModal[0] ? modal.body : modal.hide}>
                <div className={modal.box}>
                    <div className={modal.header}>
                        <p>{status === 2 ? "aprovar" : status === 3 ? "pausar" : status === 4 ? "fechar" : status === 5 ? "retomar" : status === 69 ? "juntar" : status === 6 ? "reabrir" : ""}</p>
                    </div>
                    <div className={modal.content}>
                        <div>
                        <p>Deseja {status === 2 ? "aprovar" : status === 3 ? "pausar" : status === 4 ? "fechar" : status === 5 ? "retomar" : status === 69 ? (type === "avaria" ? "juntar-se à" : "juntar-se ao") : status === 6 ? "reabrir" : ""} {type}?</p>
                        </div>
                        {
                            (status === 3 || (status === 4 && type === "avaria") || (status === 6 && type === "avaria")) ?
                            <textarea placeholder={status === 3 ? "Indique a razão para a pausa..." : status === 4 ? type === "avaria" ? "Indique como procedeu à resolução da avaria..." : "Indique como procedeu à resolução do pedido..." : status === 6 ? "Indique a razão da reabertura..." : ""} className={ ((status === 3 && reason === "" && type === "avaria") || (status === 6 && reason === "")) ? modal.reasonNull : modal.reason} onChange={(e) => {setReason(e.target.value)}}/>
                            : ""
                        }
                    </div>
                    <div className={modal.btns}>
                        <button onClick={() => handlePopOut(false)}>
                            Não, regressar
                        </button>
                        <button onClick={() => { status === 69 ? joinMalfunction(malfunctionId) : changeStatus(malfunctionId, status) }}>Sim, confirmar</button>
                    </div>
                </div>
            </div>
        }
        </>
    );
  }

export default ChangeStatusPage;