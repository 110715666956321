const getLocal = (building, block, floor, local) => {
    if (block)
        block = ", "+block;
    else
        block = "";

    if (floor)
        floor = ", "+floor;
    else
        floor = "";

    if (local)
        local = ", "+local;
    else
        local = "";
    
    return building + block  + floor + local;
  }

  export default getLocal;