import React from "react";
import Loader from "react-spinners/PropagateLoader";
import styles from "./LoadingAnimationOnly.module.css"

function LoadingAnimationOnly() {
  return (
    <div className={styles.background}>
      <Loader color="var(--text-primary)"/>
    </div>
  );
}

export default LoadingAnimationOnly;
