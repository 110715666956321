import EncryptDecryptStorage from "../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import { Url } from "../Constants/globals";
import axios from "axios";

async function RefreshToken(err) {
    var jason = EncryptDecryptStorage("decrypt");
    if ((err && err === 401) || (err.request && err.request.status === 401)){
      try {
        const res = await axios
        .get(Url + "/refresh-token", {
          headers: {
            Authorization: "Bearer "+jason.refresh_token,
          },
        });
        jason.token = res.data;
        EncryptDecryptStorage("encrypt", jason);
        return true;
      }
      catch(err) {
        await localStorage.removeItem("user");
        window.location = "/login";
      }
    }
}

export default RefreshToken;