import React, { useState, useCallback } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./DetalhesHousekeeping.module.css";
import GetDate from "../../../Components/GetDate/GetDate";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { NavLink, useParams,useLocation } from "react-router-dom";
import { HiChevronLeft, HiClipboardDocumentList, HiPencilSquare } from "react-icons/hi2";
import { MdOutlineHistory } from "react-icons/md";
import { IoLocationSharp, IoPersonCircle, IoCheckmarkCircle, IoTime } from "react-icons/io5";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import ProgressBar from "@ramonak/react-progress-bar";
import ChangeStatusPage from "../../../Components/ChangeStatus/ChangeStatus";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function DetalhesHousekeeping() {

  const { id } = useParams();
  const location = useLocation();
  const { state } = location;

  const userId = EncryptDecryptStorage("decrypt").user.id;
  const [housekeeping, setHousekeeping] = useState([]);
  const [users, setUsers] = useState([]);
  const [joinedAlready, setJoinedAlready] = useState(false);

  const [changeStatusModal, setChangeStatusModal] = useState([false, 0, 0]);
  const [loading, setLoading] = useState(true);

  const getHousekeeping = useCallback(() => {
    axios.get(Url+"/housekeeping/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      
      setHousekeeping(res.data);
      for(let t=0; t< res.data.users.length; t++){
        if (res.data.users[t].id === userId){
          setJoinedAlready(true);
        }
      }
      setUsers(res.data.users);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getHousekeeping() : window.location.reload();
      }
      setLoading(false);
    })
  },[id, userId]);

  useEffect(() => {
    getHousekeeping();
  }, [getHousekeeping]);

  return (
    <>
      <Layout tab={"housekeeping #"+id}>
        {changeStatusModal[0] ? <ChangeStatusPage malfunctionId={changeStatusModal[1]} status={changeStatusModal[2]} showModal={changeStatusModal} setShowModal={setChangeStatusModal} url={"/housekeeping/"} type={"pedido"} /> : ""}
        {
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/housekeeping" state={state ? {from: state.from ? state.from : "", page: state.page ? state.page : "", previous: state.previous ? state.previous : "", next: state.next ? state.next : ""}: ""}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.history} style={{top: ((housekeeping.created_by.id === EncryptDecryptStorage("decrypt").user.id || (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT)) && housekeeping.status.id !== 4) ? "calc(45vh - 34px)" : (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.DELETE) ? "45vh" : "0"}}>
            {
             ((housekeeping.created_by.id === EncryptDecryptStorage("decrypt").user.id || (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.DELETE)) && housekeeping.status.id !== 4) ?
              <NavLink title={"Editar"} to={"/housekeeping/editar/"+id} state={{from: "detalhes", page: state ? state.page ? state.page : "" : "", previous: state ? state.previous ? state.previous : "" : "", next: state ? state.next ? state.next : "" : ""}}>
                <HiPencilSquare size={54} color={"var(--goback-btn)"}/>
            </NavLink>
            : ""
            }
            {
              EncryptDecryptStorage("decrypt").user.permissions.housekeeping &&
              EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT ?
              <NavLink title={"Histórico"} to={"/housekeeping/logs/"+id} state={state ? {from: state.from ? state.from : "", page: state.page ? state.page : "", previous: state.previous ? state.previous : "", next: state.next ? state.next : ""}: ""}>
                <MdOutlineHistory size={54} color={"var(--goback-btn)"}/>
            </NavLink>
            : ""
            }
          </div>
          <div className={styles.iconArea}>
            <div className={styles.housekeepingInfo}>
              <h4>
                <div className={styles.priority} style={{backgroundColor: housekeeping.priority ? housekeeping.priority.color : ""}}>
                  {housekeeping.priority ? housekeeping.priority.name : ""}
                </div>
                {housekeeping.housekeeping_types ? housekeeping.housekeeping_types.name : ""}
              </h4>
              <div className={styles.iconsAndLabels}>
                <div className={styles.icons}>
                  <IoLocationSharp className={styles.icon} title={"Local"} size={24}/>
                  <IoPersonCircle className={styles.icon} title={"Criado por"} size={24}/>
                  { users.length > 0 ? <IoCheckmarkCircle className={styles.icon} title={"Realizado por"} size={24}/> : ""}
                  <IoTime className={styles.icon} title={"Data de criação"} size={24}/>
                </div>
                <div className={styles.iconsLabels}>
                  <h5 title={"Local"}>{housekeeping.local ? housekeeping.local.local_name : ""}</h5>
                  <h5 title={"Criado por"}>{housekeeping.created_by ? housekeeping.created_by.full_name : ""}</h5>
                  { users.length > 0 ? <h5 title={"Realizado por"}>{users.map((item, index) => index !== users.length -1 ? item.first_name+" "+item.last_name+", ": item.first_name+" "+item.last_name )}</h5> : ""}
                  { housekeeping.created_at ? <h5 title={"Data de criação"}>{GetDate(housekeeping.created_at)}</h5> : ""}
                </div>
              </div>
              {
                housekeeping.status ?
                <p style={{color: housekeeping.status.color}}>{housekeeping.status.name === "Nova" ? "À espera de aprovação ..." : housekeeping.status.name === "Aberta" ? "Em curso ..." : housekeeping.status.name}</p>
              : ""
              }
              {
                housekeeping.status ?
                <ProgressBar completed={housekeeping.status.percentage*100} height={"7px"} labelSize={"0px"} bgColor={housekeeping.status.color} />
              : ""
              }
              <div className={styles.description}>
                <p>{housekeeping.description ? housekeeping.description : ""}</p>
              </div>
            </div>
          </div>
          {
            ((EncryptDecryptStorage("decrypt").user.permissions.housekeeping &&
            EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT) || (housekeeping.created_by.id === EncryptDecryptStorage("decrypt").user.id)) ?
            housekeeping.status ?
            (housekeeping.status.name === "Nova" && (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT) ?
            <div className={styles.buttons} style={{ width: "20vw" }}>
              <div className={styles.btn} onClick={() => setChangeStatusModal([true, id, 2])} type="">APROVAR</div>
            </div>
            :
            (housekeeping.status.name === "Fechada" && (joinedAlready || housekeeping.created_by.id === EncryptDecryptStorage("decrypt").user.id)) ?
            <div className={styles.buttons} style={{ width: "20vw" }}>
              <div className={styles.btn} onClick={() => { setChangeStatusModal([true, id, 6])  }} type="">REABRIR</div>
            </div>
            :
            <div className={styles.buttons} style={{ width: (housekeeping.status.name !== "Pausada" && joinedAlready) ? "40vw" : (housekeeping.status.name !== "Pausada" && !joinedAlready) ? "60vw" : "20vw", justifyContent: (housekeeping.status.name !== "Fechada" && !joinedAlready) ? "center" : "space-between" }}>
              { (housekeeping.status.name !== "Fechada" && !joinedAlready && (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT)) ? <div className={styles.btn} onClick={() => setChangeStatusModal([true, id, 69])} type="">JUNTAR-SE</div> : ""}
              { joinedAlready ? <div className={styles.btn} onClick={() => { housekeeping.status.name === "Pausada" ? setChangeStatusModal([true, id, 5]) : setChangeStatusModal([true, id, 3]) }} type="">{housekeeping.status.name === "Pausada" ? "RETOMAR" : "PAUSAR"}</div>:""}
              { (housekeeping.status.name !== "Pausada" && joinedAlready) ? <div className={styles.btn} onClick={() => setChangeStatusModal([true, id, 4])} type="">FECHAR</div> : ""}
            </div>
            )
            : ""
            : ""
          }
        </div>
        }
      </Layout>
    </>
  );
}

export default DetalhesHousekeeping;