import styles from "./Dropdown.module.css";
import { GoTriangleDown } from "react-icons/go";
import { IoSearch } from "react-icons/io5";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Url } from "../../Constants/globals";
import { useDebounce } from "../Debounce/Debounce";

function Dropdown({position, disabled, label, placeholder, itemsWidth, value, onValueChange, onValueChangeFormFalse, items, onUrlChange, nextUrl, callbackFunction, callbackLink, callGetFloors, callGetBlocks, callGetSubcategories, callGetMalfunctionTypes, search, required, urlSearch, dropdownWidth, handleTechnicalProfile, callGetLocals, emptyLocalValue}) {

  const dropdownRef = useRef(null);
  const labelRef = useRef(null);
  useOutsideAlerter(dropdownRef, labelRef);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);
  const [bottomPosition, setBottomPosition] = useState("");
  const [bottomPage, setBottomPage] = useState(window.innerHeight + window.scrollY);

  // useCallback atualiza o valor passado na outra página
  const handleChange = (newValue) => {
      onValueChange(newValue);
      if (onValueChangeFormFalse)
        onValueChangeFormFalse(false);
      if (callGetSubcategories)
        callGetSubcategories(callbackLink+newValue.id);
      if (callbackFunction)
        callbackFunction(callbackLink+newValue.id+"?start=0&limit=15");
      if (callGetBlocks)
        callGetBlocks(Url+"/clients/building/"+newValue.id+"/blocks/?start=0&limit=15");
      if (callGetFloors)
        callGetFloors(Url+"/clients/building/"+newValue.id+"/floors/?start=0&limit=15");
      if (callGetMalfunctionTypes)
        callGetMalfunctionTypes(callbackLink+newValue.id+"?start=0&limit=500");
      if (callGetLocals){
        if(newValue.id !== value.id){
          emptyLocalValue("");
          callGetLocals(callbackLink+"?page=1&per_page=15", true, false, newValue);
        }
        else callGetLocals(callbackLink+"?page=1&per_page=15", false, false);
      }
      if (handleTechnicalProfile && newValue.technical_profile)
        handleTechnicalProfile(newValue.technical_profile);
      setOpenDropdown(false);
  };

  const handleScrollData = useCallback( e => {
    if (nextUrl) {
      if ((e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) && (nextUrl !== "")){
        if (urlSearch === "/building/locals") onUrlChange(`${Url}${urlSearch}?page=${nextUrl}&per_page=15${searchName ? `&name=${searchName}` : ""}`, false, true)
        else onUrlChange(nextUrl, false);
        e.target.scrollTo({
          top: e.target.scrollHeight - e.target.clientHeight - 10,
          behavior: "smooth"
        })
      }
    }
  }, [onUrlChange]);

  // Deteta quando clica fora do dropdown para fechá-lo
  function useOutsideAlerter(ref, labelRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && labelRef.current && !labelRef.current.contains(event.target)) {
          setOpenDropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const searchData = (name) => {
    if (name.length === 0){
      onUrlChange(Url+urlSearch+"?start=0&limit=15", true);
    }
    setSearchName(name);
  };

  useEffect(() => {
    if (searchName.length > 0) {
      if (debouncedSearchName.length > 0) {
        if (urlSearch === "/building/locals") onUrlChange(Url+urlSearch+"?name="+debouncedSearchName+"&page=1&per_page=15", true);
        else onUrlChange(Url+urlSearch+"?name="+debouncedSearchName+"&start=0&limit=15", true);
      }
      else {
        if (urlSearch === "/building/locals") onUrlChange(Url+urlSearch+"?page=1&per_page=15", true);
        else onUrlChange(Url+urlSearch+"?start=0&limit=15", true);
      }
    }
  }, [debouncedSearchName]);

  return (
    <div className={styles.form}>
      <div style={{width: dropdownWidth ? dropdownWidth : ""}} className={(value === "" && required) ? styles.dropdownNull : styles.dropdown}>
          { label === false ? "" : <p>{label}</p> }
          <div ref={labelRef}className={styles.dropdownLabel} style={{color: value === "" ? "" : "var(--text-primary)", cursor: disabled ? "default" : "pointer", borderBottomLeftRadius: !label ? "4px" : "", borderTopLeftRadius: !label ? "4px" : "" }} onClick={async () => { if (openDropdown === false && !disabled) { await setOpenDropdown(true); await setBottomPage(window.innerHeight + window.scrollY); await setBottomPosition(dropdownRef.current.offsetTop + dropdownRef.current.offsetHeight);}}}>
              {value === "" ? placeholder : value.name}
              <GoTriangleDown size={14} color={ disabled ? "var(--placeholder-color)" : "var(--text-primary)"} />
          </div>
      </div>
      {
          openDropdown && !disabled ?
      <div style={{position: position ? position : ""}} className={styles.dropdownItems}>
          <div ref={dropdownRef} className={bottomPosition >= bottomPage ? styles.itemsTop : styles.items} style={{ width: itemsWidth }} onScroll={(e) => { handleScrollData(e) }  }>
          {
            search ?
            <div className={styles.search}>
              <IoSearch className={styles.searchIcon}  />
              <input autoFocus placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
            </div>
            : ""
          }
          {
            items.map((value, index) => (
              <div key={index} className={styles.item} onClick={() => handleChange(value) }>
                {value.name}
                {
                  value.building_name === undefined ? "" :
                  <div>
                    <label>{value.floor_name ? value.floor_name+ " ‣ " : ""} </label> 
                    <label>{value.block_name ? value.block_name+ " ‣ " : ""}</label>
                    <label>{value.building_name}</label>
                  </div>
                }
              </div>
            ))
          }
          {
            nextUrl ?
            <div className={styles.loadMore}>
              <p onClick={() => {
                if (urlSearch === "/building/locals") onUrlChange(`${Url}${urlSearch}?page=${nextUrl}&per_page=15${searchName ? `&name=${searchName}` : ""}`, false, true)
                else onUrlChange(nextUrl, false)
              }}>
                Carregar mais...
              </p>
            </div>
          : ""
          }
          </div>
      </div>
      :
      <></>
      }
    </div>
  );
}

export default Dropdown;
