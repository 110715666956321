import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarLinenCountTypes.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft, HiPlus, HiMinus, HiTrash } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import DeleteModal from "../../../../Components/DeleteModal/DeleteModal";

function AdicionarLinenCountTypes() {

  const { id } = useParams();
  const { groupId } = useParams();
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");
  const [referenceValue, setReferenceValue] = useState("");
  const [weightValue, setWeightValue] = useState("");
  const [price, setPrice] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [deleteModalSupplier, setDeleteModalSupplier] = useState(false);
  const [nameSupplierValue, setNameSupplierValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [addSupplierForm, setAddSupplierForm] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getSuppliers = () => {
    axios.get(Url+"/linen-suppliers", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setSuppliers(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getSuppliers() : window.location.reload();
      }
    })
  }

  const getTypeDetails = (id) => {
    axios.get(Url+"/linen-group/"+groupId+"/linen-type/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setNameValue(res.data.name);
        setReferenceValue(res.data.reference);
        setWeightValue(res.data.weight);
        setPrice(res.data.supplier?.price ? res.data.supplier.price : "");
        setSelectedSupplier(res.data.supplier ? res.data.supplier : "");
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTypeDetails(id) : window.location.reload();
      }
      await setLoading(false);
    })
  }

  useEffect(() => {
    getSuppliers();
    if (id){
      if (EncryptDecryptStorage("decrypt").user.permissions["linen-count"] && EncryptDecryptStorage("decrypt").user.permissions["linen-count"].GET){
        getTypeDetails(id);
      }
      else {
        navigate("/404");
      }
    }
    else {
      if (EncryptDecryptStorage("decrypt").user.permissions["linen-count"] && EncryptDecryptStorage("decrypt").user.permissions["linen-count"].GET){
        setLoading(false)
      }
      else {
        navigate("/404");
      }
    }
  }, [id]);

  const deleteSupplier = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/linen-suppliers/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Fornecedor eliminado com sucesso</Alert>);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteSupplier(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao eliminar fornecedor</Alert>);
        }
      });
  }


  const addSupplier = async () => {
    await setAlert();
    if (nameSupplierValue === ""){
      return setAlert(<Alert type={"error"}>Indique o nome do fornecedor</Alert>);
    }
    await axios
      .post(`${Url}/linen-suppliers`, {
        name: nameSupplierValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Fornecedor criado com sucesso</Alert>);
        setAddSupplierForm(false);
        getSuppliers();
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addType() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao adicionar fornecedor</Alert>);
        }
    });
  }

  const addType = async () => {
    await setAlert();
    if (nameValue === "" || referenceValue === "" || selectedSupplier === ""){
      return setAlert(<Alert type={"error"}>Existem campos que não podem ser vazios</Alert>);
    }
    setSpinner(true);
    await axios
      .post(`${Url}/linen-group/${groupId}/linen-type`, {
        linen_type: {
            name: nameValue,
            reference: referenceValue,
            weight: weightValue ? weightValue : null
        },
        linen_type_supplier: {
            supplier_id: selectedSupplier.id,
            price: price ? price : null
        }
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setSpinner(false);
        setAlert(<Alert type={"success"}>Tipo de roupa criado com sucesso</Alert>);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addType() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar tipo de roupa</Alert>);
        }
    });
  }

  const editType = async (id) => {
    await setAlert();
    if (nameValue === "" || referenceValue === "" || selectedSupplier === ""){
        return setAlert(<Alert type={"error"}>Existem campos que não podem ser vazios</Alert>);
      }
    setSpinner(true);
    await axios
      .put(`${Url}/linen-group/${groupId}/linen-type/${id}`, {
        linen_type: {
            name: nameValue,
            reference: referenceValue,
            weight: weightValue ? weightValue : null
        },
        linen_type_supplier: {
            supplier_id: selectedSupplier.id,
            price: price ? price : null
        }
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tipo de roupa editado com sucesso</Alert>);
        setTimeout(() => {
            getSuppliers();
            setSpinner(false);
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editType(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
        }
    });
  }

  return (
    <>
      <Layout tab={id ? "housekeeping - contagem de roupa - tipo #"+id : "housekeeping - contagem de roupa - tipo"}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
            {deleteModalSupplier ? <DeleteModal onClose={setDeleteModalSupplier} headerText={"ELIMINAR FORNECEDOR"} confirmationText={"Deseja eliminar "+selectedSupplier.name+" ?"} deleteFunction={deleteSupplier} objectId={selectedSupplier.id} warningText={"Este fornecedor será eliminado de todos os tipos de roupa associados"}/> : ""}
          <NavLink className={styles.goBack} to="/housekeeping/contagem">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome</p>
                <input
                    defaultValue={nameValue}
                    rows={3}
                    placeholder={"Indique o nome do tipo de roupa..."}
                    onChange={(value) => setNameValue(value.target.value)}
                />
            </div>
            <div className={styles.formField}>
                <p className={ referenceValue ? styles.inputLabel : styles.inputLabelNull}>Referência</p>
                <input
                    defaultValue={referenceValue}
                    rows={3}
                    placeholder={"Indique a referência do tipo de roupa..."}
                    onChange={(value) => setReferenceValue(value.target.value)}
                />
            </div>
            <div className={styles.formField}>
                <p className={styles.inputLabel}>Peso (kg)</p>
                <input
                    defaultValue={weightValue}
                    rows={3}
                    placeholder={"Indique o peso do tipo de roupa..."}
                    onChange={(value) => setWeightValue(value.target.value)}
                />
            </div>
            <div className={styles.formField}>
                <p className={styles.inputLabel}>Preço (€)</p>
                <input
                    defaultValue={price}
                    rows={3}
                    placeholder={"Indique o preço do tipo de roupa..."}
                    onChange={(e) => setPrice(e.target.value)}
                />
            </div>
            <div className={styles.dropdownAdd}>
                <Dropdown
                    label={"Fornecedor"}
                    placeholder={"Escolha um fornecedor"}
                    dropdownWidth={"40vw"}
                    itemsWidth={"40vw"}
                    items={suppliers}
                    value={selectedSupplier}
                    onValueChange={setSelectedSupplier}
                    required={true}
                />
                <div className={styles.backgroundAddIcon} onClick={() => setAddSupplierForm(!addSupplierForm)}>
                    {
                        addSupplierForm ?
                        <HiMinus className={styles.addIcon} size={22} />
                        :
                        <HiPlus className={styles.addIcon} size={22} />
                    }
                </div>
                {
                    selectedSupplier ?
                    <div className={styles.backgroundAddIcon} onClick={() => setDeleteModalSupplier(true)}>
                        <HiTrash className={styles.addIcon} size={22} />
                    </div>
                    :""
                }
            </div>
            {
                addSupplierForm ?
                <div className={styles.formField}>
                <p className={ nameSupplierValue ? styles.inputLabel : styles.inputLabelNull}>Nome do fornecedor</p>
                <input
                    defaultValue={nameSupplierValue}
                    rows={3}
                    placeholder="Indique o nome do fornecedor..."
                    onChange={(value) => setNameSupplierValue(value.target.value)}
                />
                <button className={styles.submitSupplier} onClick={() => addSupplier()}>ADICIONAR FORNECEDOR</button>
            </div>
            : ""
            }
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editType(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addType()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarLinenCountTypes;