import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./LeftBar.module.css";
import { Url, v } from "../../Constants/globals";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import modal from "../Modal/Modal.module.css";
import Dropdown from "../Dropdown/Dropdown";
import RefreshToken from "../../RefreshToken/RefreshToken";
import {
    IoIosArrowDown
} from "react-icons/io";
import {
  BsGearWide
} from "react-icons/bs";
import {
  MdOutlinePassword
} from "react-icons/md";
import {
  HiBuildingOffice2, HiChevronDown
} from "react-icons/hi2";
import {
  HiLogout
} from "react-icons/hi";
import EncryptDecryptStorage from "../EncryptDecryptStorage/EncryptDecryptStorage";

function LeftBar({tab}) {

  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState(false);

  const [hora, setHora] = useState(0);
  const [modalLogout, setModalLogout] = useState(false);
  const [modalChangeClient, setModalChangeClient] = useState(false);
  const [clientValue, setClientValue] = useState("");
  const [clients, setClients] = useState([]);

  const [openDuty, setOpenDuty] = useState(false);
  const [openAvarias, setOpenAvarias] = useState(false);
  const [openHousekeeping, setOpenHousekeeping] = useState(false);
  const [openManutencao, setOpenManutencao] = useState(false);
  const [openEstatisticas, setOpenEstatisticas] = useState(false);
  const [openBackoffice, setOpenBackoffice] = useState(false);

  const [scrollIntervalId, setScrollIntervalId] = useState(null);
  const [animationRequestId, setAnimationRequestId] = useState(null);
  const componentRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function handleMouseOver() {
    const intervalId = setInterval(() => {
      if (componentRef.current) { 
        componentRef.current.scrollLeft += 3;
      }
    }, 10);
    setScrollIntervalId(intervalId);
  }

  function handleMouseOut() {
    clearInterval(scrollIntervalId);
    setScrollIntervalId(null);
    cancelAnimationFrame(animationRequestId);
    if (componentRef.current) {
      setAnimationRequestId(requestAnimationFrame(scrollBackToStart));
    }
  }

  function scrollBackToStart() {
    if (componentRef.current) {
      const scrollLeft = componentRef.current.scrollLeft;
      if (scrollLeft > 0) {
        componentRef.current.scrollLeft -= 6;
        setAnimationRequestId(requestAnimationFrame(scrollBackToStart));
      }
    }
  }

  useEffect(() => {
    setHora(new Date().getHours());
    if (localStorage.getItem("user")){
    }
    else {
      localStorage.removeItem("user");
      window.location = "/login";
    }
    if (clients.length === 0)
      getClients();
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const getClients = async () => {
    setClients(EncryptDecryptStorage("decrypt").user.many_clients);
  }

  const handleClientChange = () => {
    localStorage.removeItem("malfunctionsBuilding");
    var jason = EncryptDecryptStorage("decrypt");
    jason.user.client = clientValue;
    axios.put(Url + "/users/"+EncryptDecryptStorage("decrypt").user.id, {
      client_id: clientValue.id
    }, {
      headers: {
        Authorization: "Bearer "+EncryptDecryptStorage("decrypt").token,
      }
    }).then(async() => {
      EncryptDecryptStorage("encrypt", jason);
      window.location = "/avarias";
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? handleClientChange() : window.location.reload();
      }
    })
  }

  const ModalChangeClient = () => {
    return (
      <div className={modalChangeClient ? modal.body : modal.hide}>
        <div className={modal.box}>
          <div className={modal.header}>
            <p>Mudar de hotel</p>
          </div>
          <div className={modal.content}>
            <div>
              <p style={{margin: "10px 0"}}>Alterar para:</p>
              <Dropdown
                position={"inherit"}
                dropdownWidth={"20vw"}
                label={false}
                placeholder={"Escolher hotel"}
                itemsWidth={"20vw"}
                items={clients}
                onUrlChange={getClients}
                value={clientValue}
                onValueChange={setClientValue}
                required={true}
                nextUrl={false}
              />
            </div>
          </div>
          <div className={modal.btns}>
            <button onClick={() => setModalChangeClient(false)}>
              Regressar
            </button>
            <button onClick={() => handleClientChange()}>Confirmar</button>
          </div>
        </div>
      </div>
    );
  };

  const ModalLogout = () => {
    return (
      <div className={modalLogout ? modal.body : modal.hide}>
        <div className={modal.box}>
          <div className={modal.header}>
            <p>Sair</p>
          </div>
          <div className={modal.content}>
            <div>
              <p>Deseja terminar sessão?</p>
            </div>
          </div>
          <div className={modal.btns}>
            <button onClick={() => setModalLogout(false)}>
              Não, regressar
            </button>
            <button onClick={() => logout()}>Sim, confirmar</button>
          </div>
        </div>
      </div>
    );
  };


  const logout = () => {
    localStorage.removeItem("user");
    window.location = "/";
  }

  return (
    <>
      <ModalLogout />
      <ModalChangeClient />
      <div className={styles.navbar}>
        <div className={styles.logo}>
          <h1 style={{display: "flex", alignItems: "center"}}>S<BsGearWide className={styles.gearLogo} color={"var(--graph-text)"} size={30}/>LMANT</h1>
          <p>{v}</p>
        </div>
        <Accordion className={styles.scrollNav}>
          { 
            EncryptDecryptStorage("decrypt").user.permissions.duty &&
            EncryptDecryptStorage("decrypt").user.permissions.duty.GET ?
              <AccordionItem className={styles.ul}>
                <AccordionHeader onClick={() => { setOpenDuty(!openDuty); setOpenAvarias(false); setOpenHousekeeping(false); setOpenManutencao(false); setOpenEstatisticas(false); setOpenBackoffice(false);}} className={tab.includes("duty report") || openDuty ? styles.ulHeaderActive : styles.ulHeader}>
                  <div className={styles.tabIconText}>
                    <img src={tab.includes("duty report") ? "/duty_active.svg"  : "/duty.svg"} width={24} alt="duty" />
                    <h3>Duty</h3>
                  </div>
                  <IoIosArrowDown className={openDuty ? styles.rotatedArrowIn : styles.rotatedArrowOut} size={22}/>
                </AccordionHeader>
                <AccordionBody>
                  <NavLink className={styles.navlink} to={"/duty"}>
                    <div className={ tab.includes("duty report") ?styles.ulBodyActive : styles.ulBody}>
                      Duty report
                    </div>
                  </NavLink>
                </AccordionBody>
              </AccordionItem>
            : ""
          }

          <AccordionItem className={styles.ul}>
            <AccordionHeader onClick={() => { setOpenDuty(false); setOpenAvarias(!openAvarias); setOpenHousekeeping(false); setOpenManutencao(false); setOpenEstatisticas(false); setOpenBackoffice(false);}} className={(tab === "avarias" || tab.includes("avaria #") || openAvarias) ? styles.ulHeaderActive : styles.ulHeader}>
              <div className={styles.tabIconText}>
                <img src={ (tab === "avarias" || tab.includes("avaria #")) ? "/avarias_active.svg"  : "/avarias.svg"} width={24} alt="avarias" />
                <h3>Avarias</h3>
              </div>
              <IoIosArrowDown className={openAvarias ? styles.rotatedArrowIn : styles.rotatedArrowOut} size={22}/>
            </AccordionHeader>
            <AccordionBody>
              <NavLink className={styles.navlink} to={"/avarias"}>
                <div className={ (tab === "avarias" || tab.includes("avaria #")) ?styles.ulBodyActive : styles.ulBody}>
                  Lista de avarias
                </div>
              </NavLink>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem className={styles.ul}>
            <AccordionHeader onClick={() => { setOpenDuty(false); setOpenAvarias(false); setOpenHousekeeping(!openHousekeeping); setOpenManutencao(false); setOpenEstatisticas(false); setOpenBackoffice(false);}} className={(tab === "housekeeping" || tab.includes("housekeeping - contagem de roupa") || tab.includes("housekeeping #") || openHousekeeping) ? styles.ulHeaderActive : styles.ulHeader}>
              <div className={styles.tabIconText}>
                <img src={(tab === "housekeeping" || tab.includes("housekeeping - contagem de roupa") || tab.includes("housekeeping #")) ? "/housekeeping_active.svg"  : "/housekeeping.svg"} alt="housekeeping" width={24} />
                <h3>Housekeeping</h3>
              </div>
              <IoIosArrowDown className={openHousekeeping ? styles.rotatedArrowIn : styles.rotatedArrowOut} size={22}/>
            </AccordionHeader>
            <AccordionBody>
              <NavLink className={styles.navlink} to={"/housekeeping"}>
                <div className={ (tab === "housekeeping" || tab.includes("housekeeping #")) ? styles.ulBodyActive : styles.ulBody}   >
                  Lista de pedidos
                </div>
              </NavLink>
              {/* {
                EncryptDecryptStorage("decrypt").user.permissions["linen-count"] &&
                (EncryptDecryptStorage("decrypt").user.permissions["linen-count"].GET) ?
                <NavLink className={styles.navlink} to={"/housekeeping/contagem"}>
                  <div className={ tab.includes("housekeeping - contagem de roupa") ? styles.ulBodyActive : styles.ulBody}   >
                    Contagem de roupa
                  </div>
                </NavLink>
                :""
              } */}
            </AccordionBody>
          </AccordionItem>

          {
            EncryptDecryptStorage("decrypt").user.permissions.jobs &&
            (EncryptDecryptStorage("decrypt").user.permissions.jobs.GET || EncryptDecryptStorage("decrypt").user.permissions.equipments.GET) ?
              <AccordionItem className={styles.ul}>
                <AccordionHeader onClick={() => { setOpenDuty(false); setOpenAvarias(false); setOpenHousekeeping(false); setOpenManutencao(!openManutencao); setOpenEstatisticas(false); setOpenBackoffice(false);}} className={tab.includes("manutenção") || openManutencao ? styles.ulHeaderActive : styles.ulHeader}>
                  <div className={styles.tabIconText}>
                    <img src={tab.includes("manutenção") ? "/eventos_active.svg"  : "/eventos.svg"} width={24} alt="manutencao" />
                    <h3>Manutenção</h3>
                  </div>
                  <IoIosArrowDown className={openManutencao ? styles.rotatedArrowIn : styles.rotatedArrowOut} size={22}/>
                </AccordionHeader>
                <AccordionBody>
                {
                  EncryptDecryptStorage("decrypt").user.permissions.jobs &&
                  EncryptDecryptStorage("decrypt").user.permissions.jobs.GET ?
                  <NavLink className={styles.navlink} to={"/manutencao/trabalhos"}>
                    <div className={ (tab.includes("manutenção - trabalhos") || tab.includes("manutenção - trabalho"))  ? styles.ulBodyActive : styles.ulBody}  >
                      Trabalhos
                    </div>
                  </NavLink>
                  : ""
                }
                {
                  EncryptDecryptStorage("decrypt").user.permissions.equipments &&
                  EncryptDecryptStorage("decrypt").user.permissions.equipments.GET ?
                  <NavLink className={styles.navlink} to={"/manutencao/equipamentos"}>
                    <div className={ (tab.includes("manutenção - equipamentos") || tab.includes("manutenção - equipamento") || tab.includes("manutenção - ativos") || tab.includes("manutenção - ativo"))  ? styles.ulBodyActive : styles.ulBody}  >
                      Equipamentos
                    </div>
                  </NavLink>
                  : ""
                }
                </AccordionBody>
              </AccordionItem>
            : ""
          }

          {
            EncryptDecryptStorage("decrypt").user.permissions.statistics &&
            EncryptDecryptStorage("decrypt").user.permissions.statistics.GET ?
              <AccordionItem className={styles.ul}>
                <AccordionHeader onClick={() => { setOpenDuty(false); setOpenAvarias(false); setOpenHousekeeping(false); setOpenManutencao(false); setOpenEstatisticas(!openEstatisticas); setOpenBackoffice(false);}} className={tab.includes("estatísticas") || openEstatisticas ? styles.ulHeaderActive : styles.ulHeader}>
                  <div className={styles.tabIconText}>
                    <img src={tab.includes("estatísticas") ? "/estatisticas_active.svg"  : "/estatisticas.svg"} alt="estatisticas" width={24} />
                    <h3>Estatísticas</h3>
                  </div>
                  <IoIosArrowDown className={openEstatisticas ? styles.rotatedArrowIn : styles.rotatedArrowOut} size={22}/>
                </AccordionHeader>
                <AccordionBody>
                  <NavLink className={styles.navlink} to={"/estatisticas/avarias"}>
                    <div className={ tab.includes("estatísticas - avarias")  ? styles.ulBodyActive : styles.ulBody}  >
                      Avarias
                    </div>
                  </NavLink>
                  <NavLink className={styles.navlink} to={"/estatisticas/housekeeping"}>
                    <div className={ tab.includes("estatísticas - housekeeping")  ? styles.ulBodyActive : styles.ulBody}  >
                      Housekeeping
                    </div>
                  </NavLink>
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.costs &&
                    (EncryptDecryptStorage("decrypt").user.permissions.costs.GET) ?
                    <NavLink className={styles.navlink} to={"/estatisticas/trabalhos"}>
                      <div className={ tab.includes("estatísticas - trabalhos")  ? styles.ulBodyActive : styles.ulBody} >
                        Trabalhos
                      </div>
                    </NavLink>
                    :""
                  }
                </AccordionBody>
              </AccordionItem>
            :""
          }

          {
            (
              (EncryptDecryptStorage("decrypt").user.permissions.users &&
              EncryptDecryptStorage("decrypt").user.permissions.users.GET) ||
              (EncryptDecryptStorage("decrypt").user.permissions.duty &&
              (EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE)) ||
              (EncryptDecryptStorage("decrypt").user.permissions.malfunctions &&
              (EncryptDecryptStorage("decrypt").user.permissions.malfunctions.PUT ||
              EncryptDecryptStorage("decrypt").user.permissions.malfunctions.DELETE)) ||
              (EncryptDecryptStorage("decrypt").user.permissions.housekeeping &&
              (EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT ||
              EncryptDecryptStorage("decrypt").user.permissions.housekeeping.DELETE)) ||
              (EncryptDecryptStorage("decrypt").user.permissions.jobs &&
              (EncryptDecryptStorage("decrypt").user.permissions.jobs.DELETE)) ||
              (EncryptDecryptStorage("decrypt").user.permissions.locals &&
              (EncryptDecryptStorage("decrypt").user.permissions.locals.POST ||
              EncryptDecryptStorage("decrypt").user.permissions.locals.PUT ||
              EncryptDecryptStorage("decrypt").user.permissions.locals.DELETE))
            ) ?
              <AccordionItem className={styles.ul}>
                <AccordionHeader onClick={() => { setOpenDuty(false); setOpenAvarias(false); setOpenHousekeeping(false); setOpenManutencao(false); setOpenEstatisticas(false); setOpenBackoffice(!openBackoffice);}} className={tab.includes("backoffice") || openBackoffice ? styles.ulHeaderActive : styles.ulHeader}>
                    <div className={styles.tabIconText}>
                      <img src={tab.includes("backoffice") ? "/utilizadores_active.svg"  : "/utilizadores.svg"} alt="utilizadores" width={24} />
                      <h3>BackOffice</h3>
                    </div>
                  <IoIosArrowDown className={openBackoffice ? styles.rotatedArrowIn : styles.rotatedArrowOut} size={22}/>
                </AccordionHeader>
                <AccordionBody>
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.users &&
                    (EncryptDecryptStorage("decrypt").user.permissions.users.GET) ?
                      <NavLink className={styles.navlink} to={"/backoffice/utilizadores"}>
                        <div className={ (tab.includes("utilizadores") || tab.includes("utilizador"))  ? styles.ulBodyActive : styles.ulBody}   >
                          Utilizadores
                        </div>
                      </NavLink>
                    :""
                  }
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.duty &&
                    (EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE) ?
                      <NavLink className={styles.navlink} to={"/backoffice/duty"}>
                        <div className={ tab.includes("backoffice duty") ? styles.ulBodyActive : styles.ulBody}   >
                          Duty
                        </div>
                      </NavLink>
                    : ""
                  }
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.malfunctions &&
                    (EncryptDecryptStorage("decrypt").user.permissions.malfunctions.PUT || EncryptDecryptStorage("decrypt").user.permissions.malfunctions.DELETE) ?
                      <NavLink className={styles.navlink} to={"/backoffice/avarias"}>
                        <div className={ tab.includes("backoffice avarias") ? styles.ulBodyActive : styles.ulBody}   >
                          Avarias
                        </div>
                      </NavLink>
                    : ""
                  }
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.housekeeping &&
                    (EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT || EncryptDecryptStorage("decrypt").user.permissions.housekeeping.DELETE) ?
                      <NavLink className={styles.navlink} to={"/backoffice/housekeeping"}>
                        <div className={ tab.includes("backoffice housekeeping") ? styles.ulBodyActive : styles.ulBody} >
                          Housekeeping
                        </div>
                      </NavLink>
                    : ""
                  }
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.jobs &&
                    (EncryptDecryptStorage("decrypt").user.permissions.jobs.DELETE) ?
                      <NavLink className={styles.navlink} to={"/backoffice/trabalhos"}>
                        <div className={ (tab.includes("backoffice trabalho")) ? styles.ulBodyActive : styles.ulBody} >
                          Trabalhos
                        </div>
                      </NavLink>
                    :""
                  }
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.locals &&
                    (EncryptDecryptStorage("decrypt").user.permissions.locals.POST || EncryptDecryptStorage("decrypt").user.permissions.locals.PUT || EncryptDecryptStorage("decrypt").user.permissions.locals.DELETE) ?
                      <NavLink className={styles.navlink} to={"/backoffice/locais"}>
                        <div className={ (tab.includes("locais") || tab.includes("local")) ? styles.ulBodyActive : styles.ulBody} >
                          Locais
                        </div>
                      </NavLink>
                    :""
                  }
                </AccordionBody>
              </AccordionItem>
            :""
          }
          <div className={styles.boxUser}>
            <h2 className={styles.date}>{(hora < 12 && hora >= 7) ? "Bom dia," : (hora >= 12 && hora < 20) ? "Boa tarde," : "Boa noite," }</h2>
            <div>
              <div className={styles.opacityContainerRight}></div>
              <div className={styles.opacityContainerLeft}></div>
              <h2 ref={componentRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={styles.userNames}>{EncryptDecryptStorage("decrypt").user.first_name} {EncryptDecryptStorage("decrypt").user.last_name}!</h2>
            </div>
            <div className={styles.orderHotelNameLogout}>
              {
                EncryptDecryptStorage("decrypt").user.many_clients.length > 1 &&
                EncryptDecryptStorage("decrypt").user.permissions.users &&
                EncryptDecryptStorage("decrypt").user.permissions.users.PUT ?
                  <div className={styles.buttons}>
                    <button onClick={() => { setModalChangeClient(true); setClientValue(EncryptDecryptStorage("decrypt").user.client); }} >
                      <HiBuildingOffice2 size={13}/>
                      <label>{(EncryptDecryptStorage("decrypt").user.client.name).replace("Hotel", "")}</label>
                    </button>
                  </div>
                : 
                  <div style={{marginLeft: "15px"}}>
                    <p className={styles.hotel}>
                      <HiBuildingOffice2 size={13}/>
                      <label>{EncryptDecryptStorage("decrypt").user.client.name.replace("Hotel", "")}</label>
                    </p>
                  </div>
              }
              <div className={styles.threeDots} onClick={() => setDropdown(true)}><HiChevronDown size={15}/></div>
            </div>
            {
              dropdown ?
                <div ref={wrapperRef} className={styles.actionsModal}>
                  <div className={styles.actionsModalOption} onClick={() => {navigate("/backoffice/alterar-password")}}>
                    <MdOutlinePassword size={22}/>
                    <label>Alterar password</label>
                  </div>
                  <div className={styles.actionsModalOption} onClick={() => {setModalLogout(true); setDropdown(false);}}>
                    <HiLogout size={22}/>
                    <label>Sair</label>
                  </div>
                </div>
              : ""
            }
          </div>
        </Accordion>
      </div>
    </>
  );
}

export default LeftBar;
