import CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../../Constants/globals';

function EncryptDecryptStorage(mode, data) {
    if (mode === "encrypt"){
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
        return localStorage.setItem("user", encrypted);
    }
    if (mode === "decrypt" && localStorage.getItem("user")){
        try {
            const decrypted = CryptoJS.AES.decrypt(localStorage.getItem("user"), SECRET_KEY).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decrypted);
        } catch (error) {
            console.error("Invalid decryption:", error);
            localStorage.removeItem("user");
            return window.location = "/";
        }
    }
    else {
        return "";
    }
  }

export default EncryptDecryptStorage;