import React, { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./LinenCount.module.css";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { NavLink, useNavigate } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { IoArrowForwardCircleOutline, IoArrowBackCircleOutline, IoPencilSharp, IoTrashSharp } from "react-icons/io5";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import Alert from "../../../Components/Alert/Alert";
import Loading from "../../../Components/Loading/Loading";
import LoadingAnimationOnly from "../../../Components/LoadingAnimationOnly/LoadingAnimationOnly";
import { HiQueueList } from "react-icons/hi2";

function LinenCount() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [onlyLoading, setOnlyLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const maxDate = `${year}-${month}-${day}`;

  const [option, setOption] = useState("history");

  const [linenGroups, setLinenGroups] = useState([]);
  const [groupValue, setGroupValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [deleteModalGroup, setDeleteModalGroup] = useState(false);
  const [deleteModalType, setDeleteModalType] = useState(false);

  const [linenTypes, setLinenTypes] = useState([]);
  const [nextLinenTypes, setNextLinenTypes] = useState(null);
  const [currentLinenTypes, setCurrentLinenTypes] = useState(null);
  const [previousLinenTypes, setPreviousLinenTypes] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [countedLinen, setCountedLinen] = useState([]);

  const getLinenTypes = async (id, page) => {
    setOnlyLoading(true);
    var jason = EncryptDecryptStorage("decrypt");
    axios.get(`${Url}/linen-group/${id}/linen-type?page=${page}&per_page=20`, {
      headers: {
        Authorization: "Bearer "+jason.token,
      },
    }).then((res) => {
      const updatedArray = res.data.linen_types.map((item) => ({
        ...item,
        value: 0,
      }));
      setLinenTypes(updatedArray);
      setNextLinenTypes(res.data.next_page);
      setCurrentLinenTypes(res.data.current_page);
      setPreviousLinenTypes(res.data.prev_page);
    }).catch(async (err) => {
      setLinenTypes([]);
      setNextLinenTypes(null);
      setCurrentLinenTypes(null);
      setPreviousLinenTypes(null);
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLinenTypes(id, page) : window.location.reload();
      }
    }).finally(() =>
      setOnlyLoading(false))
  };

  const getLinenCounts = async (start, end) => {
    await setAlert("");
    if (start === "") setAlert(<Alert type={"error"}>Escolha uma data inicial</Alert>);
    if (end === "") setAlert(<Alert type={"error"}>Escolha uma data final</Alert>);
    var jason = EncryptDecryptStorage("decrypt");
    axios.get(`${Url}/linen-count?start_date=${start}&end_date=${end}`, {
      headers: {
        Authorization: "Bearer "+jason.token,
      },
    }).then((res) => {
      setCountedLinen(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLinenCounts() : window.location.reload();
      }
    })
  };
  
  const getLinenGroups = async () => {
    var jason = EncryptDecryptStorage("decrypt");
    axios.get(Url+"/linen-group", {
      headers: {
        Authorization: "Bearer "+jason.token,
      },
    }).then((res) => {
      setLinenGroups(res.data);
      setLoading(false);
    }).catch(async (err) => {
      setLoading(false);
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLinenGroups() : window.location.reload();
      }
    })
  };

  const deleteGroup = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/linen-group/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Grupo de roupas eliminado com sucesso</Alert>);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteGroup(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao eliminar grupo de roupas</Alert>);
        }
      });
  }

  const deleteType = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(`${Url}/linen-group/${groupValue.id}/linen-type/${id}`, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tipo de roupas eliminado com sucesso</Alert>);
        setTimeout(() => {
          getLinenTypes(groupValue.id, currentLinenTypes ? currentLinenTypes : 1);
          setSpinner(false);
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteType(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao eliminar tipo de roupas</Alert>);
        }
      });
  }

  useEffect(() => {
    if (EncryptDecryptStorage("decrypt").user.permissions["linen-count"] && EncryptDecryptStorage("decrypt").user.permissions["linen-count"].GET){
      getLinenGroups();
    }
    else {
      navigate("/404");
    }
  }, []);

  useEffect(() => {
    if (groupValue)
      getLinenTypes(groupValue.id, 1);
  }, [groupValue]);

  return (
    <>
      <Layout tab={"housekeeping - contagem de roupa"}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        option === "history" ?

        <div className={styles.content}>
          <div className={styles.options}>
            <button className={option === "history" ? styles.submitBtnActive : styles.submitBtn} onClick={() => setOption("history")}>Verificar histórico de contagens</button>
            <button className={option === "backoffice" ? styles.submitBtnActive : styles.submitBtn} onClick={() => setOption("backoffice")}>Alterar grupos e tipos de roupas</button>
          </div>
          {deleteModalGroup ? <DeleteModal onClose={setDeleteModalGroup} headerText={"ELIMINAR GRUPO DE ROUPAS"} confirmationText={"Deseja eliminar "+groupValue.name+" ?"} deleteFunction={deleteGroup} objectId={groupValue.id} warningText={"Todas as roupas deste grupo serão também eliminadas"}/> : ""}
          {deleteModalType ? <DeleteModal onClose={setDeleteModalType} headerText={"ELIMINAR TIPO DE ROUPAS"} confirmationText={"Deseja eliminar "+typeValue.name+" ?"} deleteFunction={deleteType} objectId={typeValue.id}/> : ""}
          <h4 style={{alignSelf: "center", margin: "0 20px"}}>Escolha o intervalo desejado</h4>
          <div className={styles.formField}>
            <input type="date" value={startDate} max={maxDate} onChange={(e) => {setStartDate(e.target.value)}}/>
            <input type="date" min={startDate} value={endDate} max={maxDate} onChange={(e) => setEndDate(e.target.value)}/>
            <button className={styles.submitBtn} onClick={() => getLinenCounts(startDate, endDate)}>Pesquisar</button>
          </div>
          {
            countedLinen.length > 0 ?
            <div className={styles.countings}>
              {
                countedLinen.map((linen, index) =>
                  <div key={index} className={styles.countedLinen}>
                    <p className={styles.linenTypeName}>{linen.type_name}</p>
                    <div className={styles.counts}>
                      <div className={styles.linenSectionsHeader}>
                        <p>Total</p>
                        <p>Danificado</p>
                        <p>Manchado</p>
                      </div>
                      <div className={styles.linenSections}>
                        <p>{linen.count.quantity}</p>
                        <p>
                          <div className={styles.dano1} />
                          <div className={styles.dano2} />
                          {linen.count.quantity_damaged}
                        </p>
                        <p>
                          <div className={styles.dano3} />
                          <div className={styles.dano4} />
                          {linen.count.quantity_stained}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            :""
          }
        </div>
        
        :
        option === "backoffice" ?
        <div className={styles.content}>
          <div className={styles.options}>
            <button className={option === "history" ? styles.submitBtnActive : styles.submitBtn} onClick={() => setOption("history")}>Verificar histórico de contagens</button>
            <button className={option === "backoffice" ? styles.submitBtnActive : styles.submitBtn} onClick={() => setOption("backoffice")}>Alterar grupos e tipos de roupas</button>
          </div>
          <div className={styles.gridAdicionarButton}>
            <NavLink className={styles.addGroup} to={"/housekeeping/contagem/grupos/adicionar"}>
              <MdAdd className={styles.addIcon} size={22} />
              Criar grupo de roupas
            </NavLink>
          </div>
          <div className={styles.gridGroups} style={{width: "calc(100% - 40px)"}}>
            {
              linenGroups.map((group) =>
              <div key={group.id} onClick={() => setGroupValue(group)} className={groupValue === group ? styles.selectedGroup : styles.chooseGroup}>
                <h5 className={styles.chooseGroupName}>{group.name}</h5>
                <div className={styles.icons}>
                  <IoPencilSharp onClick={() => {navigate("/housekeeping/contagem/grupos/"+group.id)}} className={styles.edit}/>
                  <IoTrashSharp onClick={async () => {await setGroupValue(group); await setDeleteModalGroup(true)}} className={styles.delete}/>
                </div>
              </div>
              )
            }
          </div>
            <div style={{width: "calc(100% - 40px)"}}>
            {
              groupValue ?
              <div style={{marginTop: "30px"}}>
                <div className={styles.gridAdicionarButton}>
                  <NavLink className={styles.addGroup} to={`/housekeeping/contagem/tipos/adicionar/${groupValue.id}`}>
                    <MdAdd className={styles.addIcon} size={22} />
                    Criar tipo de {groupValue.name}
                  </NavLink>
                </div>
                {
                  onlyLoading ? 
                  <LoadingAnimationOnly/>
                  :
                  linenTypes ?
                  <div className={styles.gridGroups}>
                    {
                      linenTypes.map((type) =>
                        <div key={type.id} className={styles.chooseType}>
                          <div className={styles.chooseTypeName}>
                            <h5 style={{margin: "3px 0"}}>
                              {type.name}
                            </h5>
                            <p className={styles.otherTypeFields}>
                              Ref: {type.reference}
                            </p>
                            {
                              type.weight ?
                                <p className={styles.otherTypeFields}>
                                  Peso: {type.weight} kg
                                </p>
                              :""
                            }
                            {
                              type.supplier?.price ?
                              <p className={styles.otherTypeFields}>
                                Preço: {type.supplier.price} €
                              </p>
                              : ""
                            }
                            {
                              type.supplier?.name ?
                              <p className={styles.otherTypeFields}>
                                Fornecedor: {type.supplier.name}
                              </p>
                              :
                              <p className={styles.otherTypeFields} style={{color: "#ff4f5a"}}>
                                Fornecedor em falta
                              </p>
                            }
                          </div>
                          <div className={styles.icons} style={{marginTop: "3px"}}>
                            <IoPencilSharp onClick={() => {navigate(`/housekeeping/contagem/tipos/${groupValue.id}/${type.id}`)}} className={styles.edit}/>
                            <IoTrashSharp onClick={async () => {await setTypeValue(type); await setDeleteModalType(true)}} className={styles.delete}/>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  :""
                }
                <div className={styles.changePages}>
                  {previousLinenTypes ? <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { getLinenTypes(groupValue.id, previousLinenTypes) }}/> : ""}
                  {nextLinenTypes ? <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { getLinenTypes(groupValue.id, nextLinenTypes) }}/> : ""}
                </div>
              </div>
              :""
            }
            </div>
        </div>
        :
        ""
        }
      </Layout>
    </>
  );
}

export default LinenCount;