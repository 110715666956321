import React, { useState, useEffect, useRef, useCallback } from "react";

import styles from "./EstatisticasAvarias.module.css";
import Alert from "../../../Components/Alert/Alert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Url } from "../../../Constants/globals";
import { HiChevronRight, HiChevronLeft, HiOutlineInformationCircle } from "react-icons/hi";
import { FiFilter, FiDownload } from "react-icons/fi";
import Layout from "../../../Components/Layout/Layout";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel, VictoryArea, VictoryPie } from 'victory';
import LoadingAnimationOnly from "../../../Components/LoadingAnimationOnly/LoadingAnimationOnly";
import Loading from "../../../Components/Loading/Loading";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function EstatisticasAvarias() {

    function getMonthDigits(m){
        return m.toString().length === 1 ? `0${m}` : m;
    }

    const navigate = useNavigate();

  const [estatisticasAvarias, setEstatisticasAvarias] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [alert, setAlert] = useState(false);

  const months = [{id: 1,name: "Janeiro"},{id: 2,name: "Fevereiro"},{id: 3,name: "Março"},{id: 4,name: "Abril"},{id: 5,name: "Maio"},{id: 6,name: "Junho"},{id: 7,name: "Julho"},{id: 8,name: "Agosto"},{id: 9,name: "Setembro"},{id: 10,name: "Outubro"},{id: 11,name: "Novembro"},{id: 12,name: "Dezembro"}];

  const [showModalFinalDate, setShowModalFinalDate] = useState(false);
  const [finalMonthValue, setFinalMonthValue] = useState(months.find(month => month.id === new Date().getMonth() + 1));
  const [finalYearValue, setFinalYearValue] = useState(new Date().getFullYear());

  const [initialYearValue, setInitialYearValue] = useState(new Date().getFullYear());
  const [showModalInitialDate, setShowModalInitialDate] = useState(false);
  const [initialMonthValue, setInitialMonthValue] = useState(months.find(month => month.id === new Date().getMonth() + 1));

  const [urlEstatisticasAvarias, setUrlEstatisticasAvarias] = useState(Url+"/statistics/malfunction?date_start="+initialYearValue+"-"+getMonthDigits(initialMonthValue.id)+"-01&date_end="+finalYearValue+"-"+getMonthDigits(finalMonthValue.id)+"-01");
  
  const years = [new Date().getFullYear()-5, new Date().getFullYear()-4, new Date().getFullYear()-3, new Date().getFullYear()-2,new Date().getFullYear()-1,new Date().getFullYear() ];

  const wrapperRefMonths = useRef(null);
  useOutsideAlerter(wrapperRefMonths);
  const wrapperRefYears = useRef(null);
  useOutsideAlerter(wrapperRefYears);

  // Deteta quando clica fora do modal de ações para fechá-lo
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowModalInitialDate(false);
          setShowModalFinalDate(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getEstatisticasAvarias = useCallback(() => {
    setLoading(true);
    setSpinner(true);
    axios.get(urlEstatisticasAvarias, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
      setEstatisticasAvarias(res.data);
      await setLoading(false);
      await setSpinner(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getEstatisticasAvarias() : window.location.reload();
      }
      else {
        setEstatisticasAvarias("");
        setLoading(false);
        await setSpinner(false);
      }
    })
}, [urlEstatisticasAvarias, setEstatisticasAvarias]);

  useEffect(() => {
    if (EncryptDecryptStorage("decrypt").user.permissions.statistics && EncryptDecryptStorage("decrypt").user.permissions.statistics.GET){
        getEstatisticasAvarias();
    }
    else {
        navigate("/404");
    }
  }, [urlEstatisticasAvarias, getEstatisticasAvarias, navigate]);

  const handleFilter = async () => {
    await setAlert("");
    if (initialYearValue > finalYearValue){
        return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    }
    else if (initialYearValue === finalYearValue && initialMonthValue.id > finalMonthValue.id){
        return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    }
    setUrlEstatisticasAvarias(Url+"/statistics/malfunction?date_start="+initialYearValue+"-"+getMonthDigits(initialMonthValue.id)+"-01&date_end="+finalYearValue+"-"+getMonthDigits(finalMonthValue.id)+"-01")
  }

  function getFileName() {
    const hotelName = EncryptDecryptStorage("decrypt").user.client.display_name;
    let initialMonth = initialMonthValue.id < 10 ? `0${initialMonthValue.id}` : initialMonthValue.id;
    let finalMonth = finalMonthValue.id < 10 ? `0${finalMonthValue.id}` : finalMonthValue.id;
    
    const finalDates = `${initialMonth}${initialYearValue}` === `${finalMonth}${finalYearValue}` ? `${initialYearValue}-${initialMonth}` : `${initialYearValue}-${initialMonth}_${finalYearValue}-${finalMonth}`;

    return `Avarias ${hotelName} ${finalDates}`;
  }

  const downloadExcelFile = async (downloadLink) => {
    await setLoadingDownload(true);
    await setAlert("");
    if (initialYearValue > finalYearValue){
        return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    }
    else if (initialYearValue === finalYearValue && initialMonthValue.id > finalMonthValue.id){
        return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    }
    try {
      const response = await fetch(downloadLink, {
        headers: {
            Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
    } 

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", getFileName());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      await setLoadingDownload(false);
    } catch (error) {
        if (error.request && error.request.status === 401){
            var newToken = await RefreshToken(error);
            newToken ? downloadExcelFile(downloadLink) : window.location.reload();
        }
      console.error(error);
      await setLoadingDownload(false);
      return setAlert(<Alert type={"error"}>Ocorreu um erro ao exportar o documento</Alert>);
    }
  };
  

  return (
    <>
        <Layout tab={"estatísticas - avarias"}>
            {spinner ? <LoadingAnimationOnly /> : ""}
            {loadingDownload ? <Loading/> : ""}
            {alert}
            {
                loading ? <></> :
                <div className={styles.content}>
                    <div className={styles.monthAndYear}>
                        <button className={styles.searchButton} title="Exportar Excel" onClick={() => { downloadExcelFile(Url+`/files/malfunction?date_start=${initialYearValue}-${initialMonthValue.id < 10 ? `0${initialMonthValue.id}` : initialMonthValue.id}-01&date_end=${finalYearValue}-${finalMonthValue.id < 10 ? `0${finalMonthValue.id}` : finalMonthValue.id}-01`);}}><FiDownload size={16}/>Exportar</button>
                        <div style={{display: "flex", gap: "10px"}}>
                            <div>
                                <p className={styles.labelMonths}>Mês inicial</p>
                                <div className={styles.chosenDate} onClick={() => setShowModalInitialDate(true)}>
                                    <p>{initialMonthValue.name} {initialYearValue}</p>
                                </div>
                            </div>
                            <div>
                                <p className={styles.labelMonths}>Mês final</p>
                                <div className={styles.chosenDate} onClick={() => setShowModalFinalDate(true)}>
                                    <p>{finalMonthValue.name} {finalYearValue}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{display: "flex"}}>
                            <button className={styles.searchButton} title="Filtrar" onClick={() =>  handleFilter() }><FiFilter size={16}/>Filtrar</button>
                        </div>
                    </div>
                    {
                        showModalInitialDate ?
                        <div ref={wrapperRefMonths} className={styles.modalInitialDate}>
                            <div className={styles.chooseYear}>
                                {
                                    (years.indexOf(initialYearValue) - 1) > 0 ?
                                    <HiChevronLeft style={{cursor: "pointer"}} onClick={() => setInitialYearValue(initialYearValue-1)}/>
                                    : <HiChevronLeft color={"var(--background)"}/>
                                }
                                <h4 className={styles.modalYear}>{initialYearValue}</h4>
                                {
                                    (years.indexOf(initialYearValue) + 1) < years.length ?
                                    <HiChevronRight style={{cursor: "pointer"}} onClick={() => setInitialYearValue(initialYearValue+1)}/>
                                    : <HiChevronRight color={"var(--background)"}/>
                                }
                            </div>
                            <div className={styles.modalGrid}>
                                {
                                    months.map((month) =>
                                    <p key={month.id} onClick={() => {setInitialMonthValue(month); setShowModalInitialDate(false);}}>{month.name}</p>
                                    )
                                }
                            </div>
                        </div>
                        :""
                    }
                    {
                        showModalFinalDate ?
                        <div ref={wrapperRefMonths} className={styles.modalFinalDate}>
                            <div className={styles.chooseYear}>
                                {
                                    (years.indexOf(finalYearValue) - 1) > 0 ?
                                    <HiChevronLeft style={{cursor: "pointer"}} onClick={() => setFinalYearValue(finalYearValue-1)}/>
                                    : <HiChevronLeft color={"var(--background)"}/>
                                }
                                <h4 className={styles.modalYear}>{finalYearValue}</h4>
                                {
                                    (years.indexOf(finalYearValue) + 1) < years.length ?
                                    <HiChevronRight style={{cursor: "pointer"}} onClick={() => setFinalYearValue(finalYearValue+1)}/>
                                    : <HiChevronRight color={"var(--background)"}/>
                                }
                            </div>
                            <div className={styles.modalGrid}>
                                {
                                    months.map((month) =>
                                    <p key={month.id} onClick={() => {setFinalMonthValue(month); setShowModalFinalDate(false);}}>{month.name}</p>
                                    )
                                }
                            </div>
                        </div>
                        :""
                    }
                    {
                        estatisticasAvarias ?
                        <div className={styles.graphs}>
                            <div>
                                <h4 className={styles.graphTitle}>Mais avarias criadas<br/><label>{estatisticasAvarias.dates.start} - {estatisticasAvarias.dates.end}</label><br/><label style={{textTransform: "none", color: "#888"}}>{"Total: "+estatisticasAvarias.created_malfunctions}</label></h4>
                                <div className={styles.graph}>
                                    {
                                        estatisticasAvarias.created_malfunctions_per_person.length > 0 ?
                                        <VictoryChart
                                            animate={{
                                                duration: 500,
                                                onLoad: { duration: 500 }
                                            }}
                                            domainPadding={{x: estatisticasAvarias.created_malfunctions_per_person.length <= 3 ? 100 : 30}}>
                                            <VictoryAxis
                                                style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato", angle:8}, axisLabel: {fontSize: "10", fill: "#888"} }}
                                            />
                                            <VictoryAxis
                                                style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10 }, }}
                                                dependentAxis
                                            />
                                            <VictoryBar
                                                barRatio={estatisticasAvarias.created_malfunctions_per_person.length === 1 ? 10: 0.8}
                                                style={{ data: { fill: "var(--text-primary)" }, labels: { fill: "#888", fontSize: "10" }}}
                                                labelComponent={<VictoryLabel dy={-3}/>}
                                                labels={({ datum }) => datum.created}
                                                data={estatisticasAvarias.created_malfunctions_per_person}
                                                x="user"
                                                y="created"
                                                cornerRadius={{
                                                    top: 3,
                                                }}
                                            />
                                        </VictoryChart>
                                        :
                                        <div className={styles.noData}>
                                            <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                            <p className={styles.noDataText}>Sem dados</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <h4 className={styles.graphTitle}>Mais avarias fechadas<br/>
                                    <label>{estatisticasAvarias.dates.start} - {estatisticasAvarias.dates.end}</label>
                                    <br/><label style={{textTransform: "none", color: "#888"}}>{"Total: "+estatisticasAvarias.done_malfunction}</label>
                                </h4>
                                <div className={styles.graph}>
                                    {
                                        estatisticasAvarias.done_malfunctions_per_person.length > 0 ?
                                        <VictoryChart
                                            animate={{
                                                duration: 500,
                                                onLoad: { duration: 500 }
                                            }}
                                            domainPadding={{x: estatisticasAvarias.done_malfunctions_per_person.length <= 3 ? 100 : 30}}>
                                            <VictoryAxis
                                                style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato", angle:8}, axisLabel: {fontSize: "10", fill: "#888"} }}
                                            />
                                            <VictoryAxis
                                                style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10 }, }}
                                                dependentAxis
                                            />
                                            <VictoryBar
                                                barRatio={estatisticasAvarias.done_malfunctions_per_person.length === 1 ? 10: 0.8}
                                                style={{ data: { fill: "var(--text-primary)" }, labels: { fill: "#888", fontSize: "10" }}}
                                                labelComponent={<VictoryLabel dy={-3}/>}
                                                labels={({ datum }) => datum.created}
                                                data={estatisticasAvarias.done_malfunctions_per_person}
                                                x="user"
                                                y="created"
                                                cornerRadius={{
                                                    top: 3,
                                                }}
                                            />
                                        </VictoryChart>
                                        :
                                        <div className={styles.noData}>
                                            <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                            <p className={styles.noDataText}>Sem dados</p>
                                        </div>
                                        }
                                </div>
                            </div>
                            <div>
                                <h4 className={styles.graphTitle}>Tipos de avarias mais frequentes<br/>
                                    <label>{estatisticasAvarias.dates.start} - {estatisticasAvarias.dates.end}</label>
                                </h4>
                                <div className={styles.graph}>
                                {
                                    estatisticasAvarias.malfunction_per_type.length > 0 ?
                                    <VictoryPie
                                        events={[{
                                            target: "data",
                                            eventHandlers: {
                                            onClick: () => {
                                                return [
                                                {
                                                    target: "labels",
                                                    mutation: ({ text, datum }) => {
                                                        return text === datum.name ? { text: datum.created } : { text: datum.name };
                                                    }
                                                }
                                                ];
                                            }
                                            }
                                        }]}
                                        padding={{bottom: 80, top: 80, left: 80, right: 80}}
                                        data={estatisticasAvarias.malfunction_per_type}
                                        x={(d) => d.name}
                                        y={(d) => d.created}
                                        innerRadius={50}
                                        labelPosition={"centroid"}
                                        style={{
                                            labels: {
                                            fontSize: 12, fill: "var(--text-primary)",
                                            padding: 20,
                                            display: "flex",
                                            flexWrap: "wrap"
                                            }
                                        }}
                                        colorScale={["var(--text-primary)", "var(--text-primary2)", "var(--text-primary3)", "var(--text-primary4)", "var(--text-primary5)" ]}
                                        />
                                        :
                                        <div className={styles.noData}>
                                            <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                            <p className={styles.noDataText}>Sem dados</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <h4 className={styles.graphTitle}>Áreas de avarias mais frequentes<br/>
                                    <label>{estatisticasAvarias.dates.start} - {estatisticasAvarias.dates.end}</label>
                                </h4>
                                <div className={styles.graph}>
                                {
                                    estatisticasAvarias.malfunctions_per_area.length > 0 ?
                                    <VictoryPie
                                        events={[{
                                            target: "data",
                                            eventHandlers: {
                                            onClick: () => {
                                                return [
                                                {
                                                    target: "labels",
                                                    mutation: ({ text, datum }) => {
                                                        return text === datum.name ? { text: datum.created } : { text: datum.name };
                                                    }
                                                }
                                                ];
                                            }
                                            }
                                        }]}
                                        padding={{bottom: 80, top: 80, left: 80, right: 80}}
                                        data={estatisticasAvarias.malfunctions_per_area}
                                        y={(d) => d.created}
                                        innerRadius={50}
                                        labelPosition={"centroid"}
                                        labels={({ datum }) => datum.name}
                                        fixLabelOverlap={true}
                                        style={{
                                            labels: {
                                            fontSize: 12, fill: "var(--text-primary)",
                                            padding: 20,
                                            }
                                        }}
                                        colorScale={["var(--text-primary)", "var(--text-primary2)", "var(--text-primary3)", "var(--text-primary4)", "var(--text-primary5)" ]}
                                        />
                                        :
                                        <div className={styles.noData}>
                                            <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                            <p className={styles.noDataText}>Sem dados</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                                <h4 className={styles.graphTitle}>Locais de avarias mais frequentes<br/>
                                    <label>{estatisticasAvarias.dates.start} - {estatisticasAvarias.dates.end}</label>
                                </h4>
                                <div className={styles.graph}>
                                    {
                                        estatisticasAvarias.malfunctions_per_local.length > 0 ?
                                        <VictoryChart
                                            width={900}
                                            animate={{
                                                duration: 500,
                                                onLoad: { duration: 500 }
                                            }}
                                            domainPadding={{x: estatisticasAvarias.malfunctions_per_local.length <= 3 ? 100 : 60}}>
                                            <VictoryAxis
                                                style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato", angle:3}, axisLabel: {fontSize: "10", fill: "#888"} }}
                                            />
                                            <VictoryAxis
                                                style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10 }, }}
                                                dependentAxis
                                            />
                                            <VictoryBar
                                                barRatio={estatisticasAvarias.malfunctions_per_local.length === 1 ? 10 : 0.8}
                                                style={{ data: { fill: "var(--text-primary)" }, labels: { fill: "#888", fontSize: "10" }}}
                                                labelComponent={<VictoryLabel dy={-3}/>}
                                                labels={({ datum }) => datum.created}
                                                data={estatisticasAvarias.malfunctions_per_local}
                                                x="name"
                                                y="created"
                                                cornerRadius={{
                                                    top: 3,
                                                }}
                                            />
                                        </VictoryChart>
                                        :
                                        <div className={styles.noData}>
                                            <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                            <p className={styles.noDataText}>Sem dados</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                                <h4 className={styles.graphTitle}>Avarias criadas e fechadas semanalmente<br/>
                                    <label>{estatisticasAvarias.dates.start} - {estatisticasAvarias.dates.end}</label><br/>
                                    <label style={{fontSize: "9pt", color: "var(--graph-text)"}}>• Criadas</label>
                                    <label style={{marginLeft: "10px", fontSize: "9pt"}}>• Fechadas</label>
                                </h4>
                                <div className={styles.graph}>
                                {
                                    estatisticasAvarias.weekly.length > 0 ?
                                    <VictoryChart
                                        width={900}
                                        domainPadding={40}
                                        >
                                        <VictoryArea
                                            style={{
                                                data: {
                                                    fill: "var(--graph-text)", fillOpacity: 0.4, stroke: "var(--graph-text)", strokeWidth: 2, strokeOpacity:0.6
                                                },
                                                labels: {
                                                    fontSize: 11,
                                                    fill: "var(--graph-text)",
                                                    padding: 10
                                                },
                                            }}
                                            data={estatisticasAvarias.weekly}
                                            y={(d) => d.created}
                                            x={(d) => d.date}
                                            labels={({ datum }) => datum.created}
                                            labelComponent={
                                                <VictoryLabel dx={-12}/>
                                            }
                                        />
                                        <VictoryArea
                                            style={{
                                                data: {
                                                    fill: "var(--text-primary)", fillOpacity: 0.4, stroke: "var(--text-primary)", strokeWidth: 2, strokeOpacity:0.6
                                                },
                                                labels: {
                                                    fontSize: 11,
                                                    fill: "var(--text-primary)",
                                                    padding: 10
                                                },
                                            }}
                                            data={estatisticasAvarias.weekly}
                                            y={(d) => d.done}
                                            x={(d) => d.date}
                                            labels={({ datum }) => datum.done}
                                            labelComponent={
                                                <VictoryLabel dx={12}/>
                                            }
                                        />
                                        <VictoryAxis
                                            style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, axisLabel: {fontSize: "11", fill: "var(--text-primary)", padding: 50 }, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10, strokeOpacity: 0.4 } }}
                                        />
                                        <VictoryAxis
                                            style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10, strokeOpacity: 0.4 }, }}
                                            dependentAxis
                                        />
                                    </VictoryChart>
                                    :
                                    <div className={styles.noData}>
                                        <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                        <p className={styles.noDataText}>Sem dados</p>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                        :""
                    }
                </div>
            }
        </Layout>
    </>
  );
}

export default EstatisticasAvarias;
