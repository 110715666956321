import { useState } from 'react'
import styles from "./ToggleButton.module.css"

export const ToggleButton = ({ toggled, onClick, title, titleActive }) => {
    const [isToggled, toggle] = useState(toggled);

    const callback = () => {
        toggle(!isToggled)
        onClick(!isToggled)
    }

    return (
        <label title={isToggled ? title : titleActive} className={styles.toggleLabel}>
            <input className={styles.toggleInput} type="checkbox" defaultChecked={isToggled} onClick={callback} />
            <span className={styles.toggleSpan} />
        </label>
    )
}