import React, { useState, useCallback } from "react";
import Layout from "../../Components/Layout/Layout";
import styles from "./Housekeeping.module.css";
import GetDate from "../../Components/GetDate/GetDate";
import { useRef, useEffect } from "react";
import axios from "axios";
import { Url } from "../../Constants/globals";
import { NavLink, useLocation, useNavigate  } from "react-router-dom";
import { IoPeopleCircleOutline, IoRefreshCircleOutline, IoCheckmarkCircleOutline, IoPlayCircleOutline, IoPerson, IoLocationSharp, IoSearch, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoPauseCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { HiPencilSquare, HiOutlineInformationCircle } from "react-icons/hi2";
import { MdAdd, MdSettings } from "react-icons/md";
import ProgressBar from "@ramonak/react-progress-bar";
import RefreshToken from "../../RefreshToken/RefreshToken";
import ChangeStatusPage from "../../Components/ChangeStatus/ChangeStatus";
import getLocal from "../../Components/GetLocalName/GetLocalName";
import { useDebounce } from "../../Components/Debounce/Debounce";
import LoadingAnimationOnly from "../../Components/LoadingAnimationOnly/LoadingAnimationOnly";
import EncryptDecryptStorage from "../../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import { AiOutlineCheckSquare, AiOutlineBorder } from "react-icons/ai";
import Sound from 'react-sound';

function Housekeeping() {

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [stopAnimation, setStopAnimation] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingAnimationOnly, setLoadingAnimationOnly] = useState(true);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [requestStatus, setRequestStatus] = useState(state ? state.from ? state.from : "novas" : "novas");
  const [requestStatusId, setRequestStatusId] = useState(state ? (state.from === "novas" ? "1" : state.from === "ativas" ? "2,5" : state.from === "pausadas" ? "3" : state.from === "fechadas" ? "4" : state.form === "my" ? "0" : "1") : "1");
  const [urlRequests, setUrlRequests] = useState(state ? (state.page ? state.page : (state.from  === "my" ? Url+"/housekeeping/only-me/" : Url+"/housekeeping?status="+requestStatusId+"&start=0&limit=15")): Url+"/housekeeping?status="+requestStatusId+"&start=0&limit=15");
  const [requestsData, setRequestsData] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const [next, setNext] = useState(state ? (state.next ? state.next : "") : "");
  const [previous, setPrevious] = useState(state ? (state.previous ? state.previous : "") : "");
  const [chosenIdModal, setChosenIdModal] = useState(null);
  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);
  const [changeStatusModal, setChangeStatusModal] = useState([false, 0, 0]);
  const [autoRefresh, setAutoRefresh] = useState(localStorage.getItem("autoRefreshHousekeeping") === "true" ? true : false);
  const [housekeepingFilters, setHousekeepingFilters] = useState(false);
  const [playSound, setPlaySound] = useState(false);

  // Deteta quando clica fora do modal de ações para fechá-lo
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setChosenIdModal(null);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getHousekeepingRequests = useCallback(() => {
    setLoadingAnimationOnly(true);
    setRequestCount("");
    axios.get(urlRequests, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
      setRequestCount(res.data.count);
      setRequestsData(res.data.detail);
      if(localStorage.getItem("lastHousekeepingId")){
        if (res.data.detail[0].status.name === "Nova" && res.data.detail[0].id > parseInt(localStorage.getItem("lastHousekeepingId")) && autoRefresh){
          setPlaySound(true);
        }
      }
      if (res.data.detail[0].status.name === "Nova" && debouncedSearchName.length === 0 && !res.data.previous)
        localStorage.setItem("lastHousekeepingId", res.data.detail[0].id);
      setNext(res.data.next);
      setPrevious(res.data.previous);
    }).catch(async (err) => {
      if (err.request.status === 404 && debouncedSearchName.length === 0 && !previous && requestStatus === "novas"){
        localStorage.setItem("lastHousekeepingId", 0);
      }
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getHousekeepingRequests() : window.location.reload();
      }
      else {
        setNext("");
        setPrevious("");
        setRequestsData([]);
        setRequestCount(0);
      }
    }).finally(async() => {
      setLoading(false);
      await setStopAnimation(false);
      await setTimeout(() => {
        setLoadingAnimationOnly(false);
      }, 300);
    });
  }, [urlRequests]);

  const searchData = (name) => {
    setStopAnimation(true);
    setSearchName(name);
  }

  useEffect(() => {
    if (!loading){
      if (requestStatus === "my"){
        if (debouncedSearchName.length > 0) {
          setUrlRequests(Url+"/housekeeping/only-me/?name="+debouncedSearchName+"&start=0&limit=15");
        }
        else {
          setUrlRequests(Url+"/housekeeping/only-me/?start=0&limit=15");
        }
      }
      else {
        if (debouncedSearchName.length > 0) {
          setUrlRequests(Url+"/housekeeping?status="+requestStatusId+"&name="+debouncedSearchName+"&start=0&limit=15");
        }
        else {
          setUrlRequests(Url+"/housekeeping?status="+requestStatusId+"&start=0&limit=15");
        }
      }
    }
  }, [debouncedSearchName, requestStatus, requestStatusId]);

  const handlePageChange = (url) => {
    setLoadingAnimationOnly(true);
    if (searchName.length > 0) {
        setUrlRequests(url+"&name="+searchName);
    }
    else {
        setUrlRequests(url);
    }
  }

  const handleStatusChange = (name, id) => {
    setStopAnimation(false);
    setRequestStatus(name);
    setRequestStatusId(id);
    if (name === "my"){
      setUrlRequests(Url+"/housekeeping/only-me/?start=0&limit=15");
    }
    else {
        setUrlRequests(Url+"/housekeeping?status="+id+"&start=0&limit=15");
    }
  }

  const handle3dots = (e, id) => {
    setStopAnimation(true);
    e.preventDefault();
    setChosenIdModal(id);
  }

  useEffect(() => {
    if (autoRefresh) {
      // Use useEffect to start the interval when the component mounts
      const intervalId = setInterval(() => {
        // Call getHousekeepingRequests here to run it at the specified interval
        getHousekeepingRequests();
      }, 10000); // 10,000 milliseconds = 10 seconds
  
      // Return a cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [autoRefresh, getHousekeepingRequests]);

  useEffect(() => {
    localStorage.removeItem("lastHousekeepingId");
  }, []);
  

  useEffect(() => {
    getHousekeepingRequests();
  }, [urlRequests]);

  const RequestsList = () => {
    return (
      <>
        <div className={styles.requestSection}>
          <p>Tarefa</p>
          <p>Local</p>
          <p>Detalhes</p>
        </div>
        {
          requestsData.map((request, index) => (
          <div key={index} className={stopAnimation ? "" : styles.fade}>
            <NavLink className={styles.requestContainer} to={"/housekeeping/detalhes/"+request.id} state={{from: requestStatus ? requestStatus : "", page: urlRequests, previous: previous, next: next}}>
              <div className={styles.iconArea}>
                <img src={(request.status.name === "Aberta" || request.status.name === "Reaberta") ? "/warning.png" : "/warning_red.png"} alt="" width={24} height={24}/>
                <div className={styles.requestInfo}>
                  <h4>{request.housekeeping_type ? request.housekeeping_type.name : ""}</h4>
                  <div className={styles.priority} style={{backgroundColor: request.priority.color}}>
                    {request.priority.name}
                  </div>
                  <p style={{color: request.status.color}}>{request.status.name === "Nova" ? "À espera de aprovação ..." : (request.status.name === "Aberta" || request.status.name === "Reaberta") ? "Em curso ..." : request.status.name}</p>
                  <ProgressBar completed={request.status.percentage*100} height={"7px"} labelSize={"0px"} bgColor={request.status.color} />
                </div>
              </div>
              <div className={styles.iconArea}>
                <div style={{width: "24px", display: "flex", justifyContent: "right", marginLeft: "10px"}}>
                  <IoLocationSharp size={19}/>
                </div>
                <div className={styles.requestInfo}>
                  <h4 style={{letterSpacing: "0.4px"}}>{getLocal(request.local.building, request.local.blocks, request.local.floors, request.local.local)}</h4>
                  <div className={styles.createdbyAndDate}>
                    <p>Criado por: {request.created_by.name}</p>
                    <p>{GetDate(request.created_at)}</p>
                  </div>
                </div>
              </div>
              <div className={styles.requestDetails}>
                <div className={styles.description}>
                  <p>{ request.description ? (request.description.length > 200 ? request.description.slice(0, 201)+"..." : request.description) : "" }</p>
                </div>
                <ThreeDotsModal request={request}/>
              </div>
            </NavLink>
          </div>
        ))
        }
        <div className={styles.changePages}>
            {previous === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previous) }}/>}
            {next === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(next) }}/>}
        </div>
      </>
    );
  }

  const ThreeDotsModal = ({request}) => {
    let modalOptions = [];
    if ((request.status.id === 2 || request.status.id === 5) && (request.is_user)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, request.id, 4])}>
          <IoCloseCircleOutline color="#21ba45" size={22}/>
          Fechar
        </div>
      );
    }
    if ((request.status.id === 2 || request.status.id === 5) && (request.is_user)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, request.id, 3])}>
          <IoPauseCircleOutline color="#ffab40" size={22}/>
          Pausar
        </div>
      );
    }
    if (request.status.id === 3 && (request.is_user)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, request.id, 5])}>
          <IoPlayCircleOutline color="#21ba45" size={22}/>
          Retomar
        </div>
      );
    }
    if ( request.status.id === 1 && (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, request.id, 2])}>
          <IoCheckmarkCircleOutline color="#21ba45" size={22}/>
          Aprovar
        </div>
      );
    }
    if ([2,3,5].includes(request.status.id) && (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT) && !request.is_user){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, request.id, 69])}>
          <IoPeopleCircleOutline color="rgb(40, 190, 225)" size={22}/>
          Juntar-se
        </div>
      );
    }
    if (request.status.id !== 4 && ((request.created_by.id === EncryptDecryptStorage("decrypt").user.id) || (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.DELETE))){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => navigate("/housekeeping/editar/"+request.id, { state:{from: requestStatus ? requestStatus : ""}})}>
          <HiPencilSquare color="#529eff" size={18} style={{margin: "2px"}}/>
          Editar
        </div>
      );
    }
    if (request.status.id === 4 && (request.is_user || (request.created_by.id === EncryptDecryptStorage("decrypt").user.id))){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, request.id, 6])}>
          <IoRefreshCircleOutline color="#529eff" size={22} style={{margin: "2px"}}/>
          Reabrir
        </div>
      );
    }
    if (modalOptions.length > 0){
      return (
        <div className={styles.actions} onClick={(e) =>  handle3dots(e, request.id) }>
          <img src="/3dots.svg" height={20} alt=""/>
          {
            chosenIdModal === request.id ?
            <div ref={wrapperRef} className={styles.actionsModal}>
              {modalOptions}
            </div>
          : <></>
          }
        </div>
      );
    }
    else {
      return (
        <div>
        </div>
      );
    }
  }

  return (
    <>
      <Layout tab={"housekeeping"}>
      {playSound && (
        <Sound
          url="/notification.mp3" // Update with the actual path
          playStatus={Sound.status.PLAYING}
          onFinishedPlaying={() => setPlaySound(false)}
        />
      )}
      {changeStatusModal[0] ? <ChangeStatusPage malfunctionId={changeStatusModal[1]} status={changeStatusModal[2]} showModal={changeStatusModal} setShowModal={setChangeStatusModal} url={"/housekeeping/"} type={"pedido"} /> : ""}
        { loading ? <></> :
        <div className={styles.content}>
          <div className={styles.searchBar}>
            <div className={styles.requestsStatus}>
              {
                EncryptDecryptStorage("decrypt").user.permissions.housekeeping &&
                EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT ?
                <div title="Os meus pedidos" className={ requestStatus === "my" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("my", "0"); }}>
                  {requestStatus === "my" ? requestCount + " " : ""}
                  <IoPerson style={{marginLeft: "3px"}}/>
                </div>
                : ""
              }
              <div className={ requestStatus === "novas" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("novas", "1"); }}>
                {requestStatus === "novas" ? requestCount + " " : ""}
                Novos
              </div>
              <div className={ requestStatus === "ativas" ? styles.activeStatus : styles.status } onClick={() => { localStorage.removeItem("lastHousekeepingId"); handleStatusChange("ativas", "2,5"); }}>
                {requestStatus === "ativas" ? requestCount + " " : ""}
                Ativos
              </div>
              <div className={ requestStatus === "pausadas" ? styles.activeStatus : styles.status } onClick={() => { localStorage.removeItem("lastHousekeepingId"); handleStatusChange("pausadas", "3"); }}>
                {requestStatus === "pausadas" ? requestCount + " " : ""}
                Pausados
              </div>
              <div className={ requestStatus === "fechadas" ? styles.activeStatus : styles.status } onClick={() => { localStorage.removeItem("lastHousekeepingId"); handleStatusChange("fechadas", "4"); }}>
                {requestStatus === "fechadas" ? requestCount + " " : ""}
                Fechados
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
              <NavLink className={styles.addRequest} to={"/housekeeping/adicionar"} state={{from: requestStatus ? requestStatus : ""}}>
                <MdAdd className={styles.addIcon} size={22} />
              </NavLink>
              <div className={styles.search}>
                <IoSearch className={styles.searchIcon}  />
                <input placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
              </div>
                <div className={styles.settingsHousekeeping} title="Definições" onClick={() => setHousekeepingFilters(!housekeepingFilters)}>
                  <MdSettings className={styles.addIcon} color={"var(--text-primary)"} size={22} />
                </div>
              </div>
              {
                housekeepingFilters ?
                <div>
                  <div style={{display: "flex", justifyContent: "flex-end" }}>
                    <p className={styles.checked} onClick={() => {localStorage.setItem("autoRefreshHousekeeping", !autoRefresh); setAutoRefresh(!autoRefresh);}}>Atualizar automaticamente a cada 10 segundos{autoRefresh ? <AiOutlineCheckSquare size={19} style={{transition: "0.3s ease-in-out"}}/> : <AiOutlineBorder size={19}/>}</p>
                  </div>
                </div>
                :""
              }
            </div>
          </div>
          {
            loadingAnimationOnly ? <LoadingAnimationOnly/> :
            requestsData.length === 0 ?
            <div className={styles.noData}>
              <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
              <p className={styles.noDataText}>Não existem pedidos {requestStatus === "novas" ? "novos" : requestStatus === "ativas" ? "ativos" : requestStatus === "pausadas" ? "pausados" : requestStatus === "my" ? "aprovados por si" : "fechados"}</p>
            </div>
            :
            <div style={{overflowY: "scroll", height: "calc(100vh - 2rem - 150px)"}}>
              <RequestsList/>
            </div>
          }
        </div>
        }
      </Layout>
    </>
  );
}

export default Housekeeping;