import React from "react";
import Loader from "react-spinners/PropagateLoader";
import styles from "./Loading.module.css"

function Loading({warningText}) {
  return (
    <div className={styles.background}>
      { warningText ? <p style={{color: "var(--text-primary)"}}>{warningText}</p> : ""}
      <Loader color="var(--text-primary)"/>
    </div>
  );
}

export default Loading;
