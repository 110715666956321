import React, { useState, useCallback, useRef } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./AdicionarAvarias.module.css";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft, HiOutlineCamera, HiOutlineXCircle } from "react-icons/hi2";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarAvarias() {
  
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [file, setFile] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  
  const [areas, setAreas] = useState([]);
  const [areaValue, setAreaValue] = useState("");
  const urlAreas = Url+"/malfunctions/malfunction-areas/?start=0&limit=200";
  
  const [tipos, setTipos] = useState([]);
  const [tipoValue, setTipoValue] = useState("");

  const [buildings, setBuildings] = useState([{id: 0, name: "Todos"}]);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const urlBuildings = Url + "/clients/buildings/?start=0&limit=50";
  const [firstLoadBuildings, setFirstLoadBuildings] = useState(true);

  const [firstLoadLocals, setFirstLoadLocals] = useState(true);
  const [locais, setLocais] = useState([]);
  const [nextLocais, setNextLocais] = useState("");
  const [localValue, setLocalValue] = useState("");
  const urlLocais = Url + "/building/locals?page=1&per_page=15";

  const [perfis, setPerfis] = useState([]);
  const [perfilValue, setPerfilValue] = useState({"name": "Sem perfil específico", "id": 0});
  const urlPerfis = Url + "/malfunctions/profiles/?start=0&limit=500";

  const [descriptionValue, setDescriptionValue] = useState("");

  const [urgency, setUrgency] = useState({ id: 1,  urgencia: "baixa" });

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getMalfunctionDetails = useCallback((id) => {
    axios.get(Url+"/malfunctions/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      if (res.data.status.name === "Fechada"){
        navigate(-1);
      }
      if (res.data.created_by.id === EncryptDecryptStorage("decrypt").user.id || (EncryptDecryptStorage("decrypt").user.permissions.malfunctions && EncryptDecryptStorage("decrypt").user.permissions.malfunctions.DELETE)) {
        setLocalValue(res.data.local);
        setAreaValue(res.data.malfunction_areas ? res.data.malfunction_areas : "");
        setTipoValue(res.data.malfunction_types ? res.data.malfunction_types : "");
        setPerfilValue(res.data.technical_profile ? res.data.technical_profile : {"name": "Sem perfil específico", "id": 0});
        setDescriptionValue(res.data.description);
        setUrgency({id: res.data.priority.id, urgencia: res.data.priority.id === 1  ? "baixa" : res.data.priority.id === 2 ? "normal" : res.data.priority.id === 3 ? "alta" : res.data.priority.id === 4 ? "urgente" : ""});
        await setLoading(false);
      }
      else {
        navigate("/404"); 
      }
    }).catch( async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getMalfunctionDetails(id) : window.location.reload();
      }
    })
  }, [navigate]);

  const getLocais = async (urlLocais, changedBuilding, pagination, building_drop) => {
    let building = building_drop ? building_drop : selectedBuilding;
    if (firstLoadBuildings){
      building = await getBuildings(urlBuildings);
      setSelectedBuilding(building);
      setFirstLoadBuildings(false);
    }
    console.log(`${urlLocais}${building?.id ? `&building_id=${building?.id}` : ""}`);
    await axios.get(`${urlLocais}${building?.id ? `&building_id=${building?.id}` : ""}`, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      if (firstLoadLocals || changedBuilding){
        setNextLocais(res.data.next);
        setLocais(res.data.detail);
        setFirstLoadLocals(false);
      }
      else if (pagination) {
        setNextLocais(res.data.next);
        setLocais(prevLocals => [...prevLocals, ...res.data.detail]);
      }
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLocais(urlLocais, changedBuilding, pagination, building_drop) : window.location.reload();
      }
      else if (err.request.status === 404){
        setLocais([]);
        setNextLocais("");
      }
      else {
        setLocais([]);
        setNextLocais("");
      }
      setLoading(false);
    })
  }

  const getAreas = async (urlAreas) => {
    axios.get(urlAreas, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setAreas(res.data.detail);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAreas(urlAreas) : window.location.reload();
      }
      else {
        setAreas([]);
        setTipos([]);
      }
    })
  };

  const getBuildings = async (urlBuildings) => {
    try {
      const res = await axios.get(urlBuildings, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }});
      setBuildings([...buildings, ...res.data.detail]);
      for (const building of res.data.detail){
        if (localStorage.getItem("malfunctionsBuilding") && building.id === parseInt(localStorage.getItem("malfunctionsBuilding"))){
          setSelectedBuilding(building);
          return building;
        }
      }
      setSelectedBuilding(res.data.detail[0]);
      return {id: 0, name: "Todos"};
    }
    catch (err) {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getBuildings(urlBuildings) : window.location.reload();
      }
      else {
        setBuildings([{id: 0, name: "Todos"}]);
      }
    }
  };

  const getTipos = async (url) => {
    setTipoValue("");
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        },
      }).then((res) => {
        setTipos(res.data.detail);
      }).catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? getTipos(url) : window.location.reload();
        }
        else {
          setTipos([]);
        }
      })
  };

  const getPerfilTecnico = async (urlPerfis) => {
    axios.get(urlPerfis, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setPerfis([{id: 0, name: "Sem perfil específico"}].concat(res.data.detail));
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getPerfilTecnico(urlPerfis) : window.location.reload();
      }
      else {
        setPerfis([]);
      }
    })
  }

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setFile(event.target.files[0]);
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    if (id){
      getMalfunctionDetails(id);
    }
  }, [id, getMalfunctionDetails]);

  useEffect(() => {
    getLocais(urlLocais);
  }, [urlLocais, selectedBuilding]);
  
  useEffect(() => {
    getAreas(urlAreas);
  }, [urlAreas]);

  useEffect(() => {
    getPerfilTecnico(urlPerfis);
  }, [urlPerfis]);

  const addMalfunction = async () => {
    await setAlert();
    if (areaValue === "" || localValue === "" || descriptionValue === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    setSpinner(true);
    await axios
      .post(Url + "/malfunctions", {
        local_id: localValue.local.id,
        malfunction_areas_id: areaValue.id,
        malfunction_types_id: tipoValue.id ? tipoValue.id : null,
        priority_id: urgency.id,
        technical_profile_id: perfilValue.id ? (perfilValue.id === 0 ? null : perfilValue.id) : null,
        description: descriptionValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Avaria criada com sucesso</Alert>);
        const addedImage = await addImage(res.data.id);
        if (addedImage){
          setTimeout(() => {
            window.location = "/avarias";
          }, 1000);
        }
        else {
          setAlert(<Alert type={"error"}>Ocorreu um erro ao adicionar a imagem</Alert>);
        }
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addMalfunction() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
        }
      });
  }

  const addImage = async (id) => {
    if (selectedFile){
      const formData = new FormData();
      formData.append('file', file);
      try {
        await axios.post(Url+'/malfunctions/images/?malfunction_id='+id, formData, {
          headers: {
            Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        return true;
      }
      catch (err) {
        return false;
      }
    }
    else return true;
  }

  const editMalfunction = async (id) => {
    await  setAlert();
    if (areaValue === "" || localValue === "" || descriptionValue === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    setSpinner(true);
    await axios
      .put(Url + "/malfunctions/"+id, {
        local_id: localValue.id ? localValue.id : localValue.local.id,
        malfunction_areas_id: areaValue.id,
        malfunction_types_id: tipoValue.id ? tipoValue.id : null,
        priority_id: urgency.id,
        technical_profile_id: perfilValue.id ? (perfilValue.id === 0 ? null : perfilValue.id) : null,
        description: descriptionValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      })
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Avaria editada com sucesso</Alert>);
        const addedImage = await addImage(res.data.id);
        if (addedImage){
          setTimeout(() => {
            navigate(`/avarias/detalhes/${id}`);
          }, 1000);
        }
        else {
          setAlert(<Alert type={"error"}>Ocorreu um erro ao adicionar a imagem</Alert>);
        }
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editMalfunction(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "avaria #"+id : "avarias"}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to={state ? (state.from === "detalhes" ? "/avarias/detalhes/"+id : "/avarias") : "/avarias"} state={ state ? {from: (state.from === "detalhes" ? "" : state.from), page: state.page ? state.page : "", next: state.next ? state.next : "", previous: state.previous ? state.previous : "", search: state.search ? state.search : ""} : ""}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            {
              buildings.length > 1 ?
                <Dropdown
                  label={"Edifício"}
                  placeholder={"Escolher edifício da avaria"}
                  itemsWidth={"40vw"}
                  items={buildings}
                  value={selectedBuilding}
                  onValueChange={setSelectedBuilding}
                  required={true}
                  callbackLink={urlLocais}
                  callGetLocals={getLocais}
                  emptyLocalValue={setLocalValue}
                />
              :""
            }
            <Dropdown
              label={"Local da avaria"}
              placeholder={"Escolher local da avaria"}
              itemsWidth={"40vw"}
              value={localValue}
              onUrlChange={getLocais}
              onValueChange={setLocalValue}
              items={locais}
              required={true}
              nextUrl={nextLocais}
              search={true}
              urlSearch={`/building/locals`}
            />
            <Dropdown 
              label={"Área da avaria"}
              placeholder={"Escolher área da avaria"}
              itemsWidth={"40vw"}
              value={areaValue}
              onValueChange={setAreaValue}
              items={areas}
              callGetMalfunctionTypes={getTipos}
              callbackLink={Url+"/malfunctions/malfunction-types/"}
              required={true}
            />
            <Dropdown 
              disabled={areaValue === ""}
              label={"Tipo de avaria"}
              placeholder={"Escolher tipo de avaria"}
              itemsWidth={"40vw"}
              value={tipoValue}
              onValueChange={setTipoValue}
              handleTechnicalProfile={setPerfilValue}
              items={tipos}
              onUrlChange={getTipos}
              required={true}
            />
            <Dropdown
              label={"Perfil técnico"}
              placeholder={"Escolher perfil técnico"}
              itemsWidth={"40vw"}
              value={perfilValue}
              onValueChange={setPerfilValue}
              items={perfis}
              onUrlChange={getPerfilTecnico}
            />
            <div className={styles.radios}>
                <button className={urgency.id === 1 ? styles.baixa : styles.defaultButton} onClick={() => { setUrgency({ id: 1,  urgencia: "baixa" }); }}>Baixa</button>
                <button className={urgency.id === 2 ? styles.normal : styles.defaultButton} onClick={() => { setUrgency({ id: 2,  urgencia: "normal" }); }}>Normal</button>
                <button className={urgency.id === 3 ? styles.alta : styles.defaultButton} onClick={() => { setUrgency({ id: 3,  urgencia: "alta" }); }}>Alta</button>
                <button className={urgency.id === 4 ? styles.urgente : styles.defaultButton} onClick={() => { setUrgency({ id: 4,  urgencia: "urgente" }); }}>Urgente</button>
            </div>
            <div className={styles.formField}>
                <p className={ descriptionValue === "" ? styles.descriptionFormfieldNull : styles.descriptionFormfield}>Descrição</p>
                <textarea
                  defaultValue={descriptionValue}
                  rows={3}
                  placeholder="Descreva a avaria"
                  onChange={(value) => setDescriptionValue(value.target.value)}
                />
            </div>
            {
              selectedFile ?
              <div className={styles.selectedFile}>
                <HiOutlineXCircle className={styles.goBackSelectedFile} color="#e34956" onClick={() => {setSelectedFile("")}}/>
                <img className={styles.foto} alt="" src={selectedFile ? selectedFile : ""}/>
              </div>
            :""}
            <div className={styles.imageDiv}>
              <div className={styles.changeImage} onClick={() => handleImageUpload()}>
                <HiOutlineCamera size={40} style={{minHeight: "50px"}} color="var(--text)"/>
                <p>Adicionar imagem</p>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e)}
                style={{display: "none"}}
              />
            </div>
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editMalfunction(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addMalfunction()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarAvarias;