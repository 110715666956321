import React, { useState, useEffect } from "react";

import styles from "./Login.module.css";
import Loading from "../../Components/Loading/Loading";
import Alert from "../../Components/Alert/Alert";
import axios from "axios";
import { Url } from "../../Constants/globals";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { BsGearWide } from "react-icons/bs";
import { useNavigate } from "react-router";
import EncryptDecryptStorage from "../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Login() {

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [wrongPassword, setWrongPassword] = useState(parseInt(localStorage.getItem("invalid")));
  const [timeLeft, setTimeLeft] = useState(parseInt(localStorage.getItem("invalid_time")) ? parseInt(localStorage.getItem("invalid_time")) : 60);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState();
  const [seePassword, setSeePassword] = useState(false);

    useEffect(() => {
      if (localStorage.getItem("invalid") === null || localStorage.getItem("invalid") === "" || localStorage.getItem("invalid") === undefined || localStorage.getItem("invalid") < 0 || localStorage.getItem("invalid") > 5){
        localStorage.setItem("invalid", 0);
        setWrongPassword(0);
      }
      else {
        setWrongPassword(parseInt(localStorage.getItem("invalid")));
      }
      if (parseInt(localStorage.getItem("invalid")) === 5){
        if (timeLeft === 0){
          setWrongPassword(0);
          localStorage.setItem("invalid", 0);
          return;
        }
        const intervalId = setInterval(() => {
          let timeLeft2 = timeLeft - 1;
          setTimeLeft(timeLeft2);
          localStorage.setItem("invalid_time", timeLeft2);
        }, 1000);
          return () => clearInterval(intervalId);
        }
    }, [timeLeft, wrongPassword]);

  const login = async (user, pass) => {
    await axios
      .post(Url + "/login", {
        username: user,
        password: pass,
      })
      .then(async (res) => {
        EncryptDecryptStorage("encrypt", res.data);
      });
  };

  const increasePunishment = (value) => {
    setWrongPassword(value);
    localStorage.setItem("invalid", value);
    if (value === 5){
      setTimeLeft(60);
    }
    return;
  }

  const handleLogin = async () => {
    setSpinner(true);
    setTimeout(() => {
      setAlert();
      login(username, password)
      .then(() => {
        localStorage.setItem("invalid", 0);
        localStorage.setItem("invalid_time", 0);
        window.location = "/avarias";
      })
      .catch((err) => {
        if (err?.response?.data?.detail === "Utilizador ou palavra-passe inválidos"){
          increasePunishment(wrongPassword+1);
        }
        setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Ocorreu um erro ao iniciar sessão"}</Alert>);
        setSpinner(false);
      });
    }, 1000);
  };

const handleEye = () => {
  setSeePassword(!seePassword)
}

  return (
    <>
      {spinner ? <Loading /> : ""}
      {alert}
      <div className={styles.content}>
          <h1 style={{display:"flex", alignItems:"center"}}>S<BsGearWide className={styles.gear} size={58} color={"var(--graph-text)"}/>LMANT</h1>
          <p className={styles.slogan}>Eu não estou na Savoy, eu sou a Savoy.</p>
          <div className={styles.form}>
            <div className={styles.label}>
              <input
              id="username"
                disabled={wrongPassword === 5}
                style={{width: "100%"}}
                type="text"
                placeholder="Utilizador"
                onChange={(value) => setUsername(value.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            </div>
            <div className={styles.label}>
                <input
                id="password"
                  disabled={wrongPassword === 5}
                  type= { seePassword ? "text" : "password"}
                  placeholder="Palavra-passe"
                  onChange={(value) => setPassword(value.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
              {seePassword ? <HiOutlineEye className={styles.eyeIcon} size={30} onClick={handleEye} /> : <HiOutlineEyeOff className={styles.eyeIcon} size={30} onClick={handleEye} />}
            </div>
            {
             wrongPassword === 5 ?
             <div>
              <p className={styles.timer}>Demasiadas tentativas erradas.</p>
              <p className={styles.timer}>Aguarde {timeLeft} segundos...</p>
             </div>
              :""
            }
            <div className={styles.centerBtn}>
              <button id="entrar" disabled={wrongPassword === 5} style={wrongPassword === 5 ? {cursor: "not-allowed"} : {}} className={styles.btnAdd} onClick={handleLogin}>
                Entrar
              </button>
            </div>
          </div>
        </div>
    </>
  );
}

export default Login;
