import React, { useState, useCallback } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./Utilizadores.module.css";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import { HiStar, HiBuildingOffice2, HiOutlineInformationCircle } from "react-icons/hi2";
import { MdPermContactCalendar, MdCalendarToday, MdAdd, MdOutlineHistory } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoPersonCircleOutline, IoPencilSharp, IoTrashSharp, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoSearch } from "react-icons/io5";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import { ToggleButton } from "../../../Components/ToggleButton/ToggleButton";
import modal from "../../../Components/Modal/Modal.module.css";
import GetDate from "../../../Components/GetDate/GetDate";
import { useDebounce } from "../../../Components/Debounce/Debounce";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Utilizadores() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteModalUser, setDeleteModalUser] = useState(false);
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [logsModal, setLogsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  const [urlUsers, setUrlUsers] = useState(Url+"/users?start=0&limit=30");
  const [users, setUsers] = useState([]);
  const [userValue, setUserValue] = useState("");
  const [previousUsers, setPreviousUsers] = useState("");
  const [nextUsers, setNextUsers] = useState("");
  const [userLogs, setUserLogs] = useState([]);
  const [urlLogs, setUrlLogs] = useState("");
  const [nextLogs, setNextLogs] = useState("");

  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);
  const [reason, setReason] = useState("");

  const getUsers = useCallback(async (urlUsers) => {
    axios.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setUsers(res.data.detail);
      setNextUsers(res.data.next);
      setPreviousUsers(res.data.previous);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getUsers(urlUsers) : window.location.reload();
      }
      else {
        setUsers([]);
        setNextUsers("");
        setPreviousUsers("");
        setLoading(false);
      }
    })
  }, [setUsers, setNextUsers, setPreviousUsers, setLoading]);

  const changeUserAvailability = async (id, value) => {
    if (reason === "")
        return;
    setAlert();
    setAvailabilityModal(false);
    setSpinner(true);
    await axios
      .put(Url + "/users/"+id, {
        is_active: !value,
        reason: reason
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Estado alterado com sucesso</Alert>);
        setTimeout(() => {
            setSpinner(false);
            navigate(0);
        }, 1000);
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? changeUserAvailability(id, value) : window.location.reload();
        }
        else {
            setSpinner(false);
            setAlert(<Alert type={"error"}>Erro ao alterar estado</Alert>);
        }
      });
  }

  const handleLogs = useCallback(async (url) => {
    await axios.get(url, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      
      setUserLogs(prevLogs => {
        if(prevLogs.length > 0 && !prevLogs.includes(res.data.details)){
          return prevLogs.concat(res.data.details);
        }
        else {
          return res.data.details;
        }
      });
      setNextLogs(res.data.next);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? handleLogs(url) : window.location.reload();
      }
      else {
        setUserLogs("");
      }
    })
  }, [setUserLogs, setNextLogs]);

  useEffect(() => {
    handleLogs(urlLogs);
  }, [urlLogs, handleLogs])
  

  const UsersList = () => {
    return (
        <>
        {
          users.map((user) => (
              <div className={(EncryptDecryptStorage("decrypt").user.permissions.users && (EncryptDecryptStorage("decrypt").user.permissions.users.DELETE )) ? styles.usersContainer : styles.usersContainerNoPermissions} key={user.id}>
                <div className={styles.iconNames}>
                    <IoPersonCircleOutline size={40}/>
                    <div className={styles.names}>
                        <p className={styles.fullname} style={{margin: 0}}>
                            {user.full_name}
                        </p>
                        <p className={styles.username}>
                            {user.username}
                        </p>
                    </div>
                </div>
                <div>
                    <p className={styles.fullname} title="Departamento">
                        <HiStar size={18}/>
                        {user.account_type}
                    </p>
                    <p className={styles.fullname} title="Hotel">
                        <HiBuildingOffice2 size={18}/>
                        {user.client.name}
                    </p>
                </div>
                <div>
                    <p className={styles.fullname} title="Data de criação de conta">
                        <MdPermContactCalendar size={18}/>
                        {GetDate(user.created_at)}
                    </p>
                    <p className={styles.fullname} title="Data do último login">
                        <MdCalendarToday size={18}/>
                        {user.last_login ? GetDate(user.last_login) : "Sem registo do último login"}
                    </p>
                </div>
                {
                  EncryptDecryptStorage("decrypt").user.permissions.users && (EncryptDecryptStorage("decrypt").user.permissions.users.DELETE) ?
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap:"3px"}}>
                    <div className={styles.usersActions}>
                        { EncryptDecryptStorage("decrypt").user.permissions.users && EncryptDecryptStorage("decrypt").user.permissions.users.DELETE ? <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/utilizadores/editar/"+user.id}><IoPencilSharp className={styles.hoverEdit} size={20}/></NavLink> : ""}
                        { EncryptDecryptStorage("decrypt").user.permissions.users && EncryptDecryptStorage("decrypt").user.permissions.users.DELETE ? <IoTrashSharp className={styles.hoverDelete} size={20} onClick={() => { setUserValue(user); setDeleteModalUser(true)}}/> : ""}
                        { EncryptDecryptStorage("decrypt").user.permissions.users && EncryptDecryptStorage("decrypt").user.permissions.users.DELETE ? <RiLockPasswordFill title={"Alterar password"} size={20} className={styles.hoverLogs} onClick={() => {navigate("/backoffice/utilizadores/alterar-password/"+user.id)}}/> : ""}
                        {/* { EncryptDecryptStorage("decrypt").user.permissions.users && EncryptDecryptStorage("decrypt").user.permissions.users.DELETE ? <MdOutlineHistory title={"Histórico"} size={20} className={styles.hoverLogs} onClick={() => {setSelectedUser([user.id, user.full_name, user.is_active]); setLogsModal(true); setUrlLogs(Url + "/users/"+user.id+"/logs?start=0&limit=15")}}/> : ""} */}
                    </div>
                    {EncryptDecryptStorage("decrypt").user.permissions.users && EncryptDecryptStorage("decrypt").user.permissions.users.DELETE ? <ToggleButton titleActive="Ativar utilizador" title="Desativar utilizador" toggled={user.is_active} onClick={() => {setAvailabilityModal(true); setSelectedUser([user.id, user.full_name, user.is_active]);}}/> : ""}
                </div>
                : ""
                }
              </div>
        ))
        }
      </>
    );
  }

  const searchData = (name) => {
    setTimeout(() => {
      setSearchName(name);
    }, 100);
  }

  const handlePageChange = (url) => {
    if (searchName.length > 0) {
      setUrlUsers(url+"&name="+searchName);
    }
    else {
      setUrlUsers(url);
    }
  }

  const deleteUser = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/users/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Utilizador apagado com sucesso</Alert>);
        setTimeout(() => {
          setUserValue("");
          navigate(0);
          setSpinner(false);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteUser(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar utilizador</Alert>);
      });
  }

  const handleScrollData = (e) => {
    if (nextLogs) {
      if ((e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) && (nextLogs !== "")){
        setUrlLogs(nextLogs);
      }
    }
  }
  
  useEffect(() => {
    if (debouncedSearchName.length > 0) {
      setUrlUsers(Url+"/users?name="+debouncedSearchName+"&start=0&limit=15");
    }
    else {
        setUrlUsers(Url+"/users?start=0&limit=15");
    }
  }, [debouncedSearchName])

  useEffect(() => {
    if (EncryptDecryptStorage("decrypt").user.permissions.users && EncryptDecryptStorage("decrypt").user.permissions.users.GET){
      getUsers(urlUsers);
    }
    else {
      navigate("/404");
    }
  }, [urlUsers, getUsers, navigate]);

  return (
    <>
      <Layout tab={"backoffice utilizadores"}>
        {alert}
        {availabilityModal ? 
        <div className={availabilityModal ? modal.body : modal.hide}>
            <div className={modal.box}>
                <div className={modal.header}>
                    <p>Estado de utilizador</p>
                </div>
                <div className={modal.content}>
                    <div>
                        <p>Deseja {selectedUser[2] ? "desativar" : "retomar"} a atividade de {selectedUser[1]} ?</p>
                        <textarea placeholder="Indique a razão..." onChange={(e) => setReason(e.target.value)} className={reason ? styles.reason : styles.reasonNull}></textarea>
                    </div>
                </div>
                <div className={modal.btns}>
                    <button onClick={() => setAvailabilityModal(false)}>
                        Não, regressar
                    </button>
                    <button onClick={() => changeUserAvailability(selectedUser[0], selectedUser[2])}>Sim, confirmar</button>
                </div>
            </div>
        </div>
        : ""}
        {logsModal ? 
        <div className={logsModal ? modal.body : modal.hide}>
            <div className={modal.box}>
                <div className={modal.header}>
                    <p>Histórico de {selectedUser[1]}</p>
                </div>
                {
                  userLogs.length > 0 && logsModal ?
                  <div className={modal.content} style={{overflowY: "scroll"}} onScroll={(e) => handleScrollData(e)}>
                      {
                        userLogs.map((log) => (
                        log ? <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <p>{log.date ? GetDate(log.date) : ""} - {log.log ?? ""} por {log.editor ?? ""}</p>
                          { log.reason ? <p className={styles.logReason}>{log.reason}</p> : ""}
                        </div>:
                        <p>Erro ao carregar log</p>
                        ))
                      }
                  </div>
                  : 
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <div className={styles.noData}>
                      <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                      <p className={styles.noDataText}>Sem histórico</p>
                    </div>
                  </div>
                }
                <div className={modal.regressar}>
                    <button onClick={() => { setUrlLogs(""); setLogsModal(false)}}>
                        Regressar
                    </button>
                </div>
            </div>
        </div>
        : ""}
        {deleteModalUser ? <DeleteModal onClose={setDeleteModalUser} headerText={"ELIMINAR UTILIZADOR"} confirmationText={"Deseja eliminar "+userValue.full_name+" ?"} deleteFunction={deleteUser} objectId={userValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
            <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
              {
                EncryptDecryptStorage("decrypt").user.permissions.users &&
                EncryptDecryptStorage("decrypt").user.permissions.users.POST ?
                <NavLink className={styles.addUser} to={"/backoffice/utilizadores/adicionar"}>
                  <MdAdd className={styles.addIcon} size={22} />
                </NavLink>
                : ""
              }
              <div className={styles.search}>
                <IoSearch className={styles.searchIcon}  />
                <input placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
              </div>
            </div>
            <div className={styles.dropdown}>
              {
                users.length > 0 ?
                <UsersList/>
                :
                <div style={{display: "flex", justifyContent: "center"}}>
                  <div className={styles.noData}>
                    <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                    <p className={styles.noDataText}>Utilizadores não encontrados</p>
                  </div>
                </div>
              }
            </div>
            <div className={styles.changePages}>
                {previousUsers === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previousUsers) }}/>}
                {nextUsers === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(nextUsers) }}/>}
            </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default Utilizadores;