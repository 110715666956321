import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarTarefas.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft, HiPlus, HiMinus, HiTrash } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import DeleteModal from "../../../../Components/DeleteModal/DeleteModal";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarTarefas() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");
  const [nameUnitValue, setNameUnitValue] = useState("");
  const taskTypes = [{id: 1, name: "Sem medição"}, {id: 2, name: "Com medição"}];
  const [taskTypeValue, setTaskTypeValue] = useState({id: 2, name: "Com medição"});

  const urlUnits = Url+"/units?start=0&limit=5000";
  const [units, setUnits] = useState([]);
  const [unitValue, setUnitValue] = useState("");
  
  const [deleteModalUnit, setDeleteModalUnit] = useState(false);
  const [addUnitForm, setAddUnitForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getTarefaDetails = (id) => {
    axios.get(Url+"/tasks/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        await setNameValue(res.data.name);
        await setUnitValue(res.data.unit ? res.data.unit : "");
        await setTaskTypeValue(res.data.unit ? taskTypes[1] : taskTypes[0]);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTarefaDetails(id) : window.location.reload();
      }
      await setLoading(false);
    })
  }

  const getUnits = async (urlUnits) => {
    axios.get(urlUnits, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
        await setUnits(res.data.detail);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getUnits(urlUnits) : window.location.reload();
      }
      else {
        await setUnits([]);
      }
    })
  };

  useEffect(() => {
    getUnits(urlUnits);
  }, [urlUnits]);

  useEffect(() => {
      if (id){
        if (EncryptDecryptStorage("decrypt").user.permissions.tasks && EncryptDecryptStorage("decrypt").user.permissions.tasks.PUT){
          getTarefaDetails(id);
        }
        else {
          navigate("/404");
        }
      }
      else {
        if (EncryptDecryptStorage("decrypt").user.permissions.tasks && EncryptDecryptStorage("decrypt").user.permissions.tasks.POST){
          setLoading(false);
        }
        else {
          navigate("/404");
        }
      }
  }, [id]);


  const addTask = async () => {
    await setAlert();
    if (nameValue === "" || (taskTypeValue.id === 2 && unitValue === "")){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    setSpinner(true);
    await axios
      .post(Url + "/tasks", {
        name: nameValue,
        unit_id: taskTypeValue.id === 2 ? unitValue.id : null
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tarefa criada com sucesso</Alert>);
        setSpinner(false);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addTask() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao criar tarefa"}</Alert>);
        }
      });
  }

  const editTask = async (id) => {
    await setAlert();
    if (nameValue === "" || (taskTypeValue.id === 2 && unitValue === "")){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);;
    }
    setSpinner(true);
    await axios
      .put(Url + "/tasks/"+id, {
        name: nameValue,
        unit_id: taskTypeValue.id === 2 ? unitValue.id : null
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tarefa editada com sucesso</Alert>);
        setTimeout(() => {
            navigate("/backoffice/trabalhos");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editTask(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao editar tarefa"}</Alert>);
        }
      });
  }

  const addUnit = async () => {
    await setAlert();
    if (nameUnitValue === ""){
      return setAlert(<Alert type={"error"}>Indique o nome da unidade de medida</Alert>);;
    }
    await axios
      .post(Url + "/units", {
        name: nameUnitValue,
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Unidade criada com sucesso</Alert>);
        setAddUnitForm(false);
        getUnits(urlUnits);
        setNameUnitValue("");
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addUnit() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao criar unidade"}</Alert>);
        }
      });
  }
  
  const deleteUnit = async (id) => {
    await setAlert();
    await axios
      .delete(Url + "/units/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Unidade eliminada com sucesso</Alert>);
        setUnitValue("");
        getUnits(urlUnits);
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteUnit(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao eliminar unidade"}</Alert>);
      });
  }

  return (
    <>
      <Layout tab={id ? "backoffice trabalhos - tarefa #"+id : "backoffice trabalhos - tarefas"}>
        {alert}
        {deleteModalUnit ? <DeleteModal onClose={setDeleteModalUnit} headerText={"ELIMINAR UNIDADE"} confirmationText={"Deseja eliminar "+unitValue.name+" ?"} deleteFunction={deleteUnit} objectId={unitValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/backoffice/trabalhos">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
              <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome da tarefa</p>
              <input
                  defaultValue={nameValue}
                  rows={3}
                  placeholder="Indique o nome da tarefa a adicionar..."
                  onChange={(value) => setNameValue(value.target.value)}
              />
            </div>
            <Dropdown
              label={"Tipo de tarefa"}
              placeholder={"Escolher o tipo de tarefa desejado"}
              dropdownWidth={"40vw"}
              itemsWidth={"40vw"}
              items={taskTypes}
              value={taskTypeValue}
              onValueChange={setTaskTypeValue}
              required={true}
            />
            {
              taskTypeValue.id === 2 ?
              <div className={styles.dropdownAdd}>
              <Dropdown
                label={"Unidade de medida"}
                placeholder={"Escolher unidade de medida"}
                dropdownWidth={"40vw"}
                itemsWidth={"40vw"}
                items={units}
                value={unitValue}
                onValueChange={setUnitValue}
                required={taskTypeValue.id === 2}
              />
              {
                EncryptDecryptStorage("decrypt").user.permissions.units && EncryptDecryptStorage("decrypt").user.permissions.units.POST ?
                <div className={styles.backgroundAddIcon} onClick={() => setAddUnitForm(!addUnitForm)}>
                  {addUnitForm ?
                  <HiMinus className={styles.addIcon} size={22} />
                  :
                  <HiPlus className={styles.addIcon} size={22} />}
                </div>
                : ""
              }
              {
                unitValue && (EncryptDecryptStorage("decrypt").user.permissions.units && EncryptDecryptStorage("decrypt").user.permissions.units.DELETE) ?
                <div className={styles.backgroundAddIcon} onClick={() => setDeleteModalUnit(true)}>
                    <HiTrash className={styles.addIcon} size={22} />
                </div>
                : ""
              }
            </div>
            : ""
            }

            {
                addUnitForm ?
                <div className={styles.formField}>
                <p className={ nameUnitValue ? styles.inputLabel : styles.inputLabelNull}>Nome da unidade</p>
                <input
                    defaultValue={nameUnitValue}
                    rows={3}
                    placeholder="Indique o nome da unidade de medida..."
                    onChange={(value) => setNameUnitValue(value.target.value)}
                />
                <button className={styles.submitUnit} onClick={() => addUnit()}>ADICIONAR UNIDADE</button>
            </div>
            : ""
            }
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editTask(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addTask()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarTarefas;