import React, { useState, useRef } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./DetalhesTrabalhos.module.css";
import Alert from "../../../../Components/Alert/Alert";
import GetDate from "../../../../Components/GetDate/GetDate";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import { MdOutlineHistory } from "react-icons/md";
import { CiSquareQuestion, CiSquareCheck } from "react-icons/ci";
import { IoLocationSharp, IoPersonCircle, IoTime, IoTimerOutline, IoTimer, IoCheckmark } from "react-icons/io5";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import ProgressBar from "@ramonak/react-progress-bar";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import LoadingAnimationOnly from "../../../../Components/LoadingAnimationOnly/LoadingAnimationOnly";

function Tasks({task, jobstatus}) {

  const [taskDone, setTaskDone] = useState(task.done);
  const [scrollIntervalId, setScrollIntervalId] = useState(null);
  const [animationRequestId, setAnimationRequestId] = useState(null);
  const [newQuantity, setNewQuantity] = useState(task?.quantity ?? "");

  const componentRef = useRef(null);
  
  function handleMouseOver() {
    const intervalId = setInterval(() => {
      if (componentRef.current) { 
        componentRef.current.scrollLeft += 3;
      }
    }, 10);
    setScrollIntervalId(intervalId);
  }

  function handleMouseOut() {
    clearInterval(scrollIntervalId);
    setScrollIntervalId(null);
    cancelAnimationFrame(animationRequestId);
    if (componentRef.current) {
      setAnimationRequestId(requestAnimationFrame(scrollBackToStart));
    }
  }

  function scrollBackToStart() {
    if (componentRef.current) {
      const scrollLeft = componentRef.current.scrollLeft;
      if (scrollLeft > 0) {
        componentRef.current.scrollLeft -= 6;
        setAnimationRequestId(requestAnimationFrame(scrollBackToStart));
      }
    }
  }

  function roundThisNumber(number) {
    if (Number.isInteger(parseFloat(number))){
      return number;
    }
    else {
      return number.toFixed(2);
    }
  }

  return (
    <>
      {
      ((jobstatus === 2 || jobstatus === 4) && task.unit) ?
        <>
          <div className={styles.taskContainer}>
            <p className={styles.taskTextName}>{task.name}:</p>
            <input
              disabled={jobstatus === 4}
              placeholder={jobstatus === 4 ? "Valor em falta..." : "Insira o valor..."}
              defaultValue={task.quantity !== null ? task.quantity.toString() : ""}
              onChange={(e) => {
                if((e.target.value) && e.target.value.toString().includes(",")){
                  setNewQuantity(e.target.value.replace(",","."));
                }
                else if (isNaN(e.target.value)){
                  setNewQuantity("")
                }
                else {
                  setNewQuantity(e.target.value)
                }
                task.quantity = e.target.value;}}
            />
            <p className={styles.taskTextRight}>{task.unit ? task.unit.name : ""}</p>
          </div>
          {
            task.last_record?.quantity && typeof task.last_record?.quantity === 'number' && newQuantity && !isNaN(parseFloat(newQuantity)) && typeof parseFloat(newQuantity) === "number" ?
            <p style={{fontSize: "9pt", textAlign: "center"}}>
              Último valor: {task.last_record.quantity}
              <label style={{color: newQuantity - task.last_record.quantity < 0 ? "#ff4f5a" : newQuantity - task.last_record.quantity > 0 ? "#21ba45" : ""}}> ({newQuantity - task.last_record.quantity > 0 ? `+${roundThisNumber(newQuantity - task.last_record.quantity)}` : `${roundThisNumber(newQuantity - task.last_record.quantity)}`})</label>
            </p>
            :""
          }
        </>
        :
        ((jobstatus === 2 || jobstatus === 4) && !task.unit) ?
          <p className={task.done ? styles.taskTextNameCheckboxChecked : styles.taskTextNameCheckbox} onClick={() => {if (jobstatus === 2) {task.done = !task.done; setTaskDone(!taskDone)}}}>{task.name} {task.done ? <CiSquareCheck size={30} style={{transition: "0.3s ease-in-out"}} color={"#21ba45"}/> : <CiSquareQuestion size={30}/>}</p>
        :
        (jobstatus === 1) ?
        <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
          <p className={styles.onlyTask}>{task.name}</p>
          <div className={styles.measurementNameContainer}>
            {task.unit ? <p style={{fontSize: "12pt", color: "#888"}}>{task.unit.name}</p> : ""}
          </div>
        </div>
        :
        (jobstatus === 3) ?
        <div className={styles.containerResults}>
          {task.unit ? <p className={styles.quantityResult} ref={componentRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >{task.quantity} <label style={{margin: "0", fontSize: "11pt"}}>{task.unit ? task.unit.name : ""}</label></p>: ""}
          <label className={styles.resultTaskName} style={task.unit ? {} : {padding: "12px"}}>{task.name}</label>
        </div>
        :""
        }
    </>
  )
}

function DetalhesTrabalhos() {

  const divRef = useRef(null);
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [job, setJob] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [localValue, setLocalValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [loadingOnlyAnimation, setLoadingOnlyAnimation] = useState(false);

  const getJob = () => {
    axios.get(Url+"/jobs/schedules/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setJob(res.data);
      if (res.data.percentage === 100){
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getJob() : window.location.reload();
      }
      setLoading(false);
    })
  };
  
  const getInterventions = async (jobId) => {
    setLoadingOnlyAnimation(true);
    await setInterventions([]);
    await axios.get(Url+"/jobs/schedules/"+jobId+"/local/"+localValue.id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
      await setInterventions(res.data);
      setLoadingOnlyAnimation(false);
      if (job.status.id === 2){
        setTimeout(() => {
          divRef.current.scrollTop = divRef.current.scrollHeight;
        }, 100);
      }
    }).catch(async (err) => {
      setLoadingOnlyAnimation(false);
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getInterventions(jobId) : window.location.reload();
      }
    })
  };

  const saveRecords = async (interventions) => {
    var finalValues = [];
    await setAlert();
    for(let i = 0; i < interventions.length; i++){
      for (let x = 0; x < interventions[i].tasks.length; x++){
        if((interventions[i].tasks[x].quantity) && interventions[i].tasks[x].quantity.toString().includes(",")){
          interventions[i].tasks[x].quantity = interventions[i].tasks[x].quantity.replace(",",".");
        }
        if (interventions[i].tasks[x].quantity !== "" && isNaN(interventions[i].tasks[x].quantity)){
          return setAlert(<Alert type={"error"}>Apresenta valores em branco ou inválidos</Alert>);
        }
        else {
          finalValues.push({quantity: interventions[i].tasks[x].quantity === "" ? null : interventions[i].tasks[x].quantity, done: interventions[i].tasks[x].unit === null ? interventions[i].tasks[x].done : (interventions[i].tasks[x].quantity === "" || interventions[i].tasks[x].quantity === null ? false : true), intervention_id: interventions[i].intervention_id, task_id: interventions[i].tasks[x].id})
        }
      }
    }

    await axios.put(Url + "/jobs/schedules/"+id+"/local/"+localValue.id, finalValues, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(async () => {
      await setAlert(<Alert type={"success"}>Gravação concluída com sucesso</Alert>);
      await getJob();
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? saveRecords(interventions) : window.location.reload();
      }
      else {
        setAlert(<Alert type={"error"}>{err.response.data.detail ? err.response.data.detail : "Erro ao gravar"}</Alert>);
      }
    });
  }

  const statusJob = (status) => {
    axios.put(Url +"/jobs/schedules/"+id, {
      status_id: status
    }, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(async () => {
      await navigate("/manutencao/trabalhos", {state: { from: status === 3 ? "completos" : status === 4 ? "pausados" : "ativos"}})
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? statusJob(status) : window.location.reload();
      }
      else {
        setAlert(<Alert type={"error"}>{err.response.data.detail ? err.response.data.detail : status === 3 ? "Erro ao concluir trabalho" : "Erro ao iniciar trabalho"}</Alert>);
      }
    });
  }

  useEffect(() => {
    if (localValue) {
      getInterventions(id);
    }
  }, [localValue])

  useEffect(() => {
    if (EncryptDecryptStorage("decrypt").user.permissions.jobs && EncryptDecryptStorage("decrypt").user.permissions.jobs.GET){
      getJob();
    }
    else {
      navigate("/404");
    }
  }, []);

  return (
    <>
      <Layout tab={"manutenção - trabalhos #"+id}>
        {alert}
        { 
        loading ? <></> :
          <div ref={divRef} className={styles.content}>
            <NavLink className={styles.goBack} to="/manutencao/trabalhos" state={{from: state ? state.from : "", page: state?.page ? state.page : ""}}>
              <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
            </NavLink>
            {
              job.status.id !== 1 ?
              <div className={styles.history} style={{top:"45vh"}}>
                <NavLink title={"Histórico"} to={"/manutencao/trabalhos/logs/"+id} state={{from: state ? state.from : "", page: state?.page ? state.page : ""}}>
                  <MdOutlineHistory size={54} color={"var(--goback-btn)"}/>
                </NavLink>
              </div>
              : ""
            }
            <div className={styles.iconArea}>
              <div className={styles.malfunctionInfo}>
                <h4 style={{display:"flex", alignItems: "center"}}>
                  {job.job.name}
                  {job.status.id === 3 ? <IoCheckmark color={"#21ba4590"} size={28} style={{marginLeft: "5px"}}/> : ""}
                </h4>
                {
                  job.status.id === 2 || job.status.id === 4 ?
                    <p style={{color: job.status.id === 4 ? "#ffab40" : "#38d189"}}>{ job.status.id === 4 ? "Em pausa: "+job.percentage+" %" : job.percentage === 0 ? "Em curso..." : "Em curso: "+job.percentage+" %"}</p>
                  : ""
                }
                {
                  job.status.id === 2 || job.status.id === 4 ?
                    <ProgressBar completed={job.percentage === 0 ? 1 : job.percentage} height={"7px"} labelSize={"0px"} bgColor={job.status.id === 4 ? "#ffab40" : "#38d189"} />
                  : ""
                }
                <div className={styles.infoAndDescription}>
                  <div className={styles.iconsAndLabels}>
                    <div className={styles.icons}>
                      { job.users ? <IoPersonCircle className={styles.icon} title={"Realizado por"} size={24}/> : ""}
                      { job.date ? <IoTime className={styles.icon} title={"Data e hora de agendamento"} size={24}/> : ""}
                      { job.date_start ? <IoTimerOutline className={styles.icon} title={"Iniciado a"} size={24}/> : ""}
                      { job.date_end ? <IoTimer className={styles.icon} title={"Concluído a"} size={24}/> : ""}
                    </div>
                    <div className={styles.iconsLabels}>
                      { job.users ? <h5 title={"Realizado por"}>{job.users}</h5> : ""}
                      { job.date ? <h5 title={"Agendado para"}>{GetDate(job.date, true)} {job.hour}</h5> : ""}
                      { job.date_start ? <h5 title={"Iniciado a"}>{GetDate(job.date_start)}</h5> : ""}
                      { job.date_end ? <h5 title={"Concluído a"}>{GetDate(job.date_end)}</h5> : ""}
                    </div>
                  </div>
                  <div className={styles.description}>
                    <p>{job.job.description ? job.job.description : ""}</p>
                  </div>
                </div>
                {
                  job.status.id === 1 ?
                    <div className={styles.containerData}>
                      <div>
                        <div className={styles.localsContainer} style={{gridTemplateColumns: job.job.locals[0].interventions.interventions.length === 1 ? "repeat(1, calc(50% - 10px))" : "repeat(2, calc(50% - 10px))", justifyContent: "center", borderRadius: (job.status.id === 3 || job.status.id === 1 || (job.status.id === 2 && job.percentage === 100)) ? "10px" : "" }}>
                          {
                            job.job.locals[0].interventions.interventions.map((intervention) => 
                              <>
                                <h5 className={styles.interventionName}>{intervention.reference ? intervention.reference+" - " : ""} {intervention.name}</h5>
                                <div className={styles.containerIntervention}>
                                  {
                                    intervention.tasks.map((task) => 
                                      <Tasks task={task} jobstatus={job.status.id}/>
                                    )
                                  }
                                </div>
                              </>
                            )
                          }
                        </div>
                      </div>
                      <div>
                        {
                          job.job.locals.map((local) => 
                            <h5 className={styles.localsStatus1}><IoLocationSharp/>{local ? local.full_name : ""}</h5>
                          )
                        }
                      </div>
                    </div>
                  :
                    <div>
                      { job.status.id === 2 ? <h3 className={styles.labelLocals}>Escolha um local</h3> : ""}
                      <div className={styles.containerDataLocals}>
                        {
                          job.job.locals.map((local) => 
                            <div onClick={() => setLocalValue(local)} className={ localValue.id === local.id ? styles.selectedLocal : styles.localsStatus2 }>
                              <h5 style={{ display: "flex", margin: "0", marginBottom: "5px", gap: "3px"}} title={"Local"}><IoLocationSharp style={{marginTop: "2px", minWidth: "18px", minHeight: "18px"}}/>{local ? local.full_name : ""}</h5>
                              <p className={local.interventions.done_tasks === local.interventions.missing_tasks + local.interventions.done_tasks ? styles.doneTasks100 : styles.doneTasks}>{local.interventions.done_tasks} / {local.interventions.missing_tasks + local.interventions.done_tasks} {local.interventions.done_tasks === local.interventions.missing_tasks + local.interventions.done_tasks ? <IoCheckmark size={16}/> : ""}</p>
                            </div>
                          )
                        }
                      </div>
                      {
                        loadingOnlyAnimation ?
                        <LoadingAnimationOnly/>
                        :
                        interventions.length > 0 ?
                        <div style={interventions.length === 1 ? {gridTemplateColumns: "340px"} : {}} className={styles.containerDataRecords}>
                          {
                            interventions.map((intervention) => 
                              <>
                                <div className={styles.containerIntervention} style={ job.status.id === 3 ? { backgroundColor: "var(--background-content)", paddingTop: 0, marginTop: "20px"} : {}}>
                                  <h5 className={styles.interventionName}>{intervention.intervention_name}</h5>
                                  {
                                    intervention.tasks.map((task) => 
                                      <Tasks task={task} jobstatus={job.status.id}/>
                                    )
                                  }
                                </div>
                              </>
                            )
                          }
                        </div>
                      : ""
                      }
                    </div>
                }
              </div>
              {
                (localValue && job.status.id === 2 && (EncryptDecryptStorage("decrypt").user.permissions.jobs && EncryptDecryptStorage("decrypt").user.permissions.jobs.PUT)) ?
                  <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                    <button className={styles.btnGravar} onClick={() => saveRecords(interventions)}>Gravar</button>
                  </div>
                :""
              }
              {
                (EncryptDecryptStorage("decrypt").user.permissions.jobs && EncryptDecryptStorage("decrypt").user.permissions.jobs.PUT) ?
                job.status.id === 1 ?
                  <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                    <button className={styles.btnSave} onClick={() => statusJob(2)}>Iniciar trabalho</button>
                  </div>
                :
                job.status.id === 2 && job.percentage === 100 ?
                  <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                    <button className={styles.btnSave} onClick={() => statusJob(3)}>Concluir trabalho</button>
                  </div>
                :
                job.status.id === 2 && job.percentage !== 100 ?
                  <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                    <button className={styles.btnSave} onClick={() => statusJob(4)}>Pausar trabalho</button>
                  </div>
                :
                job.status.id === 4 ?
                  <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                    <button className={styles.btnSave} onClick={() => statusJob(2)}>Retomar trabalho</button>
                  </div>
                : ""
                : ""
              }
            </div>
          </div>
        }
      </Layout>
    </>
  );
}

export default DetalhesTrabalhos;