import React, { useState, useCallback } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./BackofficeDuties.module.css";
import { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp, IoAddCircleSharp } from "react-icons/io5";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function BackofficeDuties() {

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteModalArea, setDeleteModalArea] = useState(false);
  const [deleteModalTipo, setDeleteModalTipo] = useState(false);

  const [firstLoadAreas, setFirstLoadAreas] = useState(true);
  const urlAreas = Url+"/duty/areas/?start=0&limit=15";
  const [areas, setAreas] = useState([]);
  const [areaValue, setAreaValue] = useState(state ? state.from : "");
  const [nextAreas, setNextAreas] = useState([]);

  const [tipos, setTipos] = useState([]);

  const getAreas = useCallback(async (urlAreas) => {
    axios.get(urlAreas, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setNextAreas(res.data.next);
      if (firstLoadAreas) {
        setAreas(res.data.detail);
        setFirstLoadAreas(false);
      } else {
        setAreas(prevAreas => [...prevAreas, ...res.data.detail]);
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAreas(urlAreas) : window.location.reload();
      }
      else {
        setAreas([]);
        setNextAreas("");
      }
    })
  }, [areas]);

  const getTipos = async (url) => {
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        },
      }).then((res) => {
        setTipos(res.data.duties);
      }).catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? getTipos(url) : window.location.reload();
        }
        else {
          setTipos([]);
        }
      })
  };

  const TiposList = () => {
    return (
      areaValue !== "" ? 
        <>
        <p className={styles.areaTitle} style={{marginTop: "15px"}}>Tipos de {areaValue.name}
          {
            (EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE) ?
              <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/duty/tipos/adicionar"} state={{from: areaValue}}><IoAddCircleSharp className={styles.hoverAdd} size={22}/></NavLink>
            :""
          }
        </p>
        {
          tipos.map((tipo) => (
            <div className={styles.tiposContainer} key={tipo.id}>
              {deleteModalTipo === tipo.id ? <DeleteModal onClose={setDeleteModalTipo} headerText={"ELIMINAR TIPO"} confirmationText={"Deseja eliminar "+tipo.name+" ?"} deleteFunction={deleteTipo} objectId={tipo.id} areaId={areaValue.id}/> : ""}
              <p>
                  {tipo.name}
              </p>
              <div className={styles.tiposActions}>
                  {
                    (EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE) ?
                      <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/duty/tipos/editar/"+tipo.id} state={{from: areaValue}}><IoPencilSharp className={styles.hoverEdit} size={20}/></NavLink>
                    :""
                  }
                  {
                    (EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE) ?
                    <IoTrashSharp className={styles.hoverDelete} size={20} onClick={() => setDeleteModalTipo(tipo.id)}/>
                    :""
                  }
              </div>
            </div>
          ))
        }
      </>
      : ""
    );
  }

  const deleteArea = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/duty/areas/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Área apagada com sucesso</Alert>);
        setTimeout(async () => {
          navigate(0);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteArea(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao apagar área</Alert>);
        }
      });
  }

  const deleteTipo = async (areaID, typeID) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/duty/duties/"+typeID, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tipo apagado com sucesso</Alert>);
        setTimeout(() => {
          setTimeout(() => {
            setSpinner(false);
          }, 500);
          getTipos(Url+"/duty/areas/"+areaValue.id+"?start=0&limit=3000");
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteTipo(areaID, typeID) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao apagar tipo</Alert>);
        }
      });
  }

  useEffect(() => {
    getAreas(urlAreas);
        if (state) {
          getTipos(Url+"/duty/areas/"+areaValue.id+"?start=0&limit=3000");
        }
      setLoading(false);
  }, [urlAreas])
  

  useEffect(() => {
    if (!EncryptDecryptStorage("decrypt").user.permissions.duty || !(EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE)){
      navigate("/404");
    }
  }, [navigate]);

  return (
    <>
      <Layout tab={"backoffice duty"}>
        {alert}
        {deleteModalArea ? <DeleteModal onClose={setDeleteModalArea} headerText={"ELIMINAR ÁREA"} confirmationText={"Deseja eliminar "+areaValue.name+" ?"} warningText={"Todos os tipos associados a esta área serão eliminados!"} deleteFunction={deleteArea} objectId={areaValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
            <p className={styles.areaTitle}>Área de duty
              {
                (EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE) ?
                <NavLink style={{color: "var(--text-primary)"}} to="/backoffice/duty/areas/adicionar"><IoAddCircleSharp className={styles.hoverAdd} size={22}/></NavLink>
                :""
              }
            </p>
            <div className={styles.dropdown}>
                <div className={styles.dropdownActions}>
                    <Dropdown 
                        label={false}
                        placeholder={"Escolher a área de duty"}
                        dropdownWidth={"45vw"}
                        itemsWidth={"45vw"}
                        value={areaValue}
                        onValueChange={setAreaValue}
                        items={areas}
                        onUrlChange={getAreas}
                        nextUrl={nextAreas}
                        callbackFunction={getTipos}
                        callbackLink={Url+"/duty/areas/"}
                    />
                    {
                      areaValue ?
                      <div className={styles.tiposActions}>
                        {
                          (EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE) ?
                          <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/duty/areas/editar/"+areaValue.id} state={{from: areaValue}}><IoPencilSharp className={styles.hoverEdit} size={22}/></NavLink>
                         :""
                        }
                        {
                          (EncryptDecryptStorage("decrypt").user.permissions.duty && EncryptDecryptStorage("decrypt").user.permissions.duty.DELETE) ?
                          <IoTrashSharp className={styles.hoverDelete} size={22} onClick={() => setDeleteModalArea(true)}/>
                          :""
                        }
                    </div>
                    : ""
                    }
                </div>
                <TiposList/>
            </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default BackofficeDuties;