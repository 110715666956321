import React, { useState, useCallback } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./BackofficeTrabalhos.module.css";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Alert from "../../../Components/Alert/Alert";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import { IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoSearch, IoPencilSharp, IoTrashSharp } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { useDebounce } from "../../../Components/Debounce/Debounce";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function BackofficeTrabalhos() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);

  const [urlTasks, setUrlTasks] = useState(Url+"/tasks?start=0&limit=15");
  const [tasks, setTasks] = useState([]);
  const [nextTasks, setNextTasks] = useState("");
  const [previousTasks, setPreviousTasks] = useState("");
  const [searchNameTasks, setSearchNameTasks] = useState("");
  const debouncedSearchNameTasks = useDebounce(searchNameTasks, 250);
  const [deleteModalTasks, setDeleteModalTasks] = useState(false);
  const [selectedTask, setSelectedTask] = useState("");

  const [urlInterventions, setUrlInterventions] = useState(Url+"/interventions?start=0&limit=15");
  const [interventions, setInterventions] = useState([]);
  const [nextInterventions, setNextInterventions] = useState("");
  const [previousInterventions, setPreviousInterventions] = useState("");
  const [searchNameInterventions, setSearchNameInterventions] = useState("");
  const debouncedSearchNameInterventions = useDebounce(searchNameInterventions, 250);
  const [deleteModalInterventions, setDeleteModalInterventions] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState("");

  const getTasks = useCallback(async (urlTasks) => {
    axios.get(urlTasks, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
        await setTasks(res.data.detail);
        await setNextTasks(res.data.next);
        await setPreviousTasks(res.data.previous);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTasks(urlTasks) : window.location.reload();
      }
      else {
        await setTasks([]);
        await setNextTasks("");
        await setPreviousTasks("");
        await setLoading(false);
      }
    })
  }, []);

  const getInterventions = useCallback(async (urlInterventions) => {
    axios.get(urlInterventions, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
        await setInterventions(res.data.detail);
        await setNextInterventions(res.data.next);
        await setPreviousInterventions(res.data.previous);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getInterventions(urlInterventions) : window.location.reload();
      }
      else {
        await setInterventions([]);
        await setNextInterventions("");
        await setPreviousInterventions("");
      }
    })
  }, []);

  const searchInterventionsData = (name) => {
    setSearchNameInterventions(name);
  }

  const handleInterventionsPageChange = (url) => {
    setUrlInterventions(url);
  }

  const searchTasksData = (name) => {
    setSearchNameTasks(name);
  }

  const handleTasksPageChange = (url) => {
    setUrlTasks(url);
  }

  useEffect(() => {
    if (debouncedSearchNameInterventions.length > 0) {
      setUrlInterventions(Url+"/interventions?name="+debouncedSearchNameInterventions+"&start=0&limit=15");
    }
    else {
        setUrlInterventions(Url+"/interventions?start=0&limit=15");
    }
  }, [debouncedSearchNameInterventions]);

  useEffect(() => {
    if (debouncedSearchNameTasks.length > 0) {
      setUrlTasks(Url+"/tasks?name="+debouncedSearchNameTasks+"&start=0&limit=15");
    }
    else {
        setUrlTasks(Url+"/tasks?start=0&limit=15");
    }
  }, [debouncedSearchNameTasks]);

  useEffect(() => {
    if(EncryptDecryptStorage("decrypt").user.permissions.jobs && EncryptDecryptStorage("decrypt").user.permissions.jobs.DELETE){
      getTasks(urlTasks);
    }
    else {
      navigate("/404");
    }
  }, [urlTasks, getTasks, navigate]);

  useEffect(() => {
    getInterventions(urlInterventions);
  }, [urlInterventions, getInterventions]);

  const deleteIntervention = async (id) => {
    await setAlert();
    await axios
      .delete(Url + "/interventions/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Intervenção apagada com sucesso</Alert>);
        getInterventions(urlInterventions);
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteIntervention(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar intervenção</Alert>);
      });
  }

  const deleteTask = async (id) => {
    await setAlert();
    await axios
      .delete(Url + "/tasks/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tarefa apagada com sucesso</Alert>);
        getTasks(urlTasks);
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteTask(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar tarefa</Alert>);
      });
  }
  
  return (
    <>
      <Layout tab={"backoffice trabalhos"}>
        {alert}
        {deleteModalInterventions ? <DeleteModal onClose={setDeleteModalInterventions} headerText={"ELIMINAR INTERVENÇÃO"} confirmationText={"Deseja eliminar "+selectedIntervention.name+" ?"} deleteFunction={deleteIntervention} objectId={selectedIntervention.id}/> : ""}
        {deleteModalTasks ? <DeleteModal onClose={setDeleteModalTasks} headerText={"ELIMINAR TAREFA"} confirmationText={"Deseja eliminar "+selectedTask.name+" ?"} deleteFunction={deleteTask} objectId={selectedTask.id}/> : ""}
        { 
        loading ? <></> :
        <div className={styles.content}>
            <div className={styles.container}>
                <h3>INTERVENÇÕES</h3>
                <div style={{display:"flex", alignItems: "center", gap: "10px", marginBottom: "20px"}}>
                  {
                    (EncryptDecryptStorage("decrypt").user.permissions.interventions && EncryptDecryptStorage("decrypt").user.permissions.interventions.POST) ?
                    <NavLink className={styles.addBtnInterventions} to={"/backoffice/trabalhos/intervencoes/adicionar"}>
                      <MdAdd className={styles.addIcon} size={22} />
                    </NavLink>
                    : ""
                  }
                  <div className={styles.search}>
                    <IoSearch className={styles.searchIcon}  />
                    <input placeholder={"Procurar"} onChange={(e) => { searchInterventionsData(e.target.value) }}/>
                  </div>
                </div>
                <div style={{width: "calc(100% - 20px)"}}>
                  <div className={styles.infoContainerLabels}>
                    <p>Nome</p>
                    <p>Referência</p>
                    <p>Tipo</p>
                  </div>
                </div>
                <div className={styles.containerScroll}>
                {
                    interventions.map((intervention) => (
                      <div key={intervention.id} style={{display: "flex", width: "100%"}}>
                        <div className={styles.infoContainer}>
                            <p>{intervention.name}</p>
                            <p>{intervention.reference}</p>
                            <p>{intervention.intervention_type.name}</p>
                        </div>
                        {
                          EncryptDecryptStorage("decrypt").user.permissions.interventions && EncryptDecryptStorage("decrypt").user.permissions.interventions.PUT ?
                          <NavLink className={styles.backgroundIcon} to={"/backoffice/trabalhos/intervencoes/"+intervention.id}>
                            <IoPencilSharp className={styles.hoverEdit}/>
                          </NavLink>
                          :""
                        }
                        {
                          EncryptDecryptStorage("decrypt").user.permissions.interventions && EncryptDecryptStorage("decrypt").user.permissions.interventions.DELETE ?
                          <div className={styles.backgroundIcon}>
                            <IoTrashSharp className={styles.hoverDelete} onClick={() => {setSelectedIntervention(intervention); setDeleteModalInterventions(true)}}/>
                          </div>
                          :""
                        }
                      </div>
                    ))
                }
                </div>
                <div className={styles.changePages}>
                  {previousInterventions === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handleInterventionsPageChange(previousInterventions) }}/>}
                  {nextInterventions === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handleInterventionsPageChange(nextInterventions) }}/>}
                </div>
            </div>
            <div className={styles.container}>
                <h3>TAREFAS</h3>
                <div style={{display:"flex", alignItems: "center", gap: "10px", marginBottom: "20px"}}>
                  {
                    EncryptDecryptStorage("decrypt").user.permissions.tasks && EncryptDecryptStorage("decrypt").user.permissions.tasks.POST ?
                    <NavLink className={styles.addBtnTasks} to={"/backoffice/trabalhos/tarefas/adicionar"}>
                      <MdAdd className={styles.addIcon} size={22} />
                    </NavLink>
                    : ""
                  }
                  <div className={styles.search}>
                    <IoSearch className={styles.searchIcon}  />
                    <input placeholder={"Procurar"} onChange={(e) => { searchTasksData(e.target.value) }}/>
                  </div>
                </div>
                <div style={{width: "calc(100% - 20px)"}}>
                  <div className={styles.infoContainerLabels}>
                    <p>Nome</p>
                    <p>Unidade</p>
                  </div>
                </div>
                <div className={styles.containerScroll}>
                {
                    tasks.map((task) => (
                      <div key={task.id} style={{display: "flex", width: "100%"}}>
                        <div className={styles.infoContainer}>
                            <p>{task.name}</p>
                            <p>{task.unit ? task.unit.name : "n/a"}</p>
                        </div>
                        {
                          EncryptDecryptStorage("decrypt").user.permissions.tasks && EncryptDecryptStorage("decrypt").user.permissions.tasks.PUT ?
                          <NavLink className={styles.backgroundIcon} to={"/backoffice/trabalhos/tarefas/"+task.id}>
                            <IoPencilSharp className={styles.hoverEdit}/>
                          </NavLink>
                          :""
                        }
                        {
                          EncryptDecryptStorage("decrypt").user.permissions.tasks && EncryptDecryptStorage("decrypt").user.permissions.tasks.DELETE ?
                          <div className={styles.backgroundIcon}>
                            <IoTrashSharp className={styles.hoverDelete} onClick={() => {setSelectedTask(task); setDeleteModalTasks(true);}}/>
                          </div>
                          :""
                        }
                      </div>
                    ))
                }
                </div>
                <div className={styles.changePages}>
                  {previousTasks ? <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handleTasksPageChange(previousTasks) }}/> : ""}
                  {nextTasks ? <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handleTasksPageChange(nextTasks) }}/> : ""}
                </div>
            </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default BackofficeTrabalhos;