import { useCallback } from "react";
import styles from "./DeleteModal.module.css"


function DeleteModal({onClose, onValueChange, headerText, confirmationText, warningText, deleteFunction, objectId, areaId}) {


    const closeModal = useCallback(() => {
        onClose(false);
    });

    const deleteThis = useCallback(id => {
        onClose(false);
        areaId ? deleteFunction(areaId, objectId) : deleteFunction(id);
    });

    return (
        <>
        {
            <div className={styles.body}>
                <div className={styles.box}>
                <div className={styles.headerText}>
                    <p>{headerText}</p>
                </div>
                <div className={styles.content}>
                    <p className={styles.confirmationText}>{confirmationText}</p>
                    {warningText ?<p className={styles.warningText}>{warningText}</p> : ""}
                </div>
                <div className={styles.btns}>
                    <button onClick={() => {closeModal();}}>
                        Não, regressar
                    </button>
                    <button onClick={() => { areaId ? deleteThis(areaId, objectId) : deleteThis(objectId)}}>Sim, confirmar</button>
                </div>
                </div>
            </div>
        }
        </>
    );
  }


export default DeleteModal;