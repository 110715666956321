import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarAreas.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarAreas() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [nameValue, setNameValue] = useState("");
  const area = state ? state.from : "";


  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getAreaDetails = (id) => {
    axios.get(Url+"/malfunctions/malfunction-areas/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setNameValue(res.data.name);
        setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAreaDetails(id) : window.location.reload();
      }
      setLoading(false);
    })
  }


  useEffect(() => {
    if (id){
      if ((EncryptDecryptStorage("decrypt").user.permissions.malfunctions && EncryptDecryptStorage("decrypt").user.permissions.malfunctions.DELETE)){
        getAreaDetails(id);
      }
      else {
        navigate("/404");
      }
    }
    else {
      if ((EncryptDecryptStorage("decrypt").user.permissions.malfunctions && EncryptDecryptStorage("decrypt").user.permissions.malfunctions.PUT)){
        setLoading(false);
      }
      else {
        navigate("/404");
      }
    }
  }, [id]);


  const addArea = async () => {
    if (nameValue === ""){
      return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .post(Url + "/malfunctions/malfunction-areas/", {
        name: nameValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Área criada com sucesso</Alert>);
        setTimeout(() => {
            navigate("/backoffice/avarias", res.data ? {state: { from: res.data}} : "");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addArea() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar área</Alert>);
        }
      });
  }

  const editArea = async (id) => {
    if (nameValue === ""){
        return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .put(Url + "/malfunctions/malfunction-areas/"+id, {
        name: nameValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Área editada com sucesso</Alert>);
        setTimeout(() => {
            navigate("/backoffice/avarias", area ? {state: { from: area}} : "");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editArea() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao editar área</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "backoffice avarias area #"+id : "backoffice avarias"}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/backoffice/avarias" state={area ? {from: area} : ""}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome da área</p>
                <input
                    defaultValue={nameValue}
                    rows={3}
                    placeholder="Indique o nome da área de avaria a adicionar..."
                    onChange={(value) => setNameValue(value.target.value)}
                />
            </div>
            
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editArea(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addArea()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarAreas;