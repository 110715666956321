import React, { useEffect, useState } from "react";

import styles from "./Layout.module.css";
import LeftBar from "../LeftBar/LeftBar";
import TopBar from "../TopBar/TopBar";
import { Url } from "../../Constants/globals";
import axios from "axios";
import RefreshToken from "../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../EncryptDecryptStorage/EncryptDecryptStorage";

function Layout({children, tab}) {

  const me = () => {
    var jason = EncryptDecryptStorage("decrypt");
    axios
      .get(Url + "/users/me/", {
        headers: {
          Authorization: "Bearer "+jason.token,
        },
      })
      .then((res) => {
        jason.user = res.data;
        EncryptDecryptStorage("encrypt", jason);
        //force logout se o utilizador for editado
        // if (jason.user.force_logout){
        //   localStorage.removeItem("user");
        //   window.location = "/login";
        // }
      })
      .catch((err) => {
        if (err.request && err.request.status === 401){
          RefreshToken(err);
        }
        else {
          localStorage.removeItem("user");
          window.location = "/login";
        }
      });
  }

  useEffect(() => {
    me();
  }, []);

  return (
    <>
      <div className={styles.divider}>
        <LeftBar tab={tab}
        />
        <TopBar tabName={tab}/>
        <div className={styles.container}>
          <div className={styles.scrollContent}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
