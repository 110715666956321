import styles from "./TopBar.module.css";

function TopBar({tabName}) {

  return (
    <>
      <div className={styles.topBar}>
        <h1>{tabName}</h1>
      </div>
    </>
  );
}

export default TopBar;
