import React, { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./DetalhesHousekeeping.module.css";
import GetDate from "../../../Components/GetDate/GetDate";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import ChangeStatusPage from "../../../Components/ChangeStatus/ChangeStatus";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function LogsHousekeeping() {

  const location = useLocation();
  const { state } = location;

  const { id } = useParams();
  const [logs, setLogs] = useState([]);
  const navigate = useNavigate();

  const [changeStatusModal, setChangeStatusModal] = useState([false, 0, 0]);
  const [loading, setLoading] = useState(true);

  const getRequest = async () => {
    var jason = EncryptDecryptStorage("decrypt");
    axios.get(Url+"/housekeeping/"+id+"/logs", {
      headers: {
        Authorization: "Bearer "+jason.token,
      },
    }).then((res) => {
      
      setLogs(res.data);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getRequest() : window.location.reload();
      }
      setLoading(false);
    })
  };

  useEffect(() => {
    if (EncryptDecryptStorage("decrypt").user.permissions.housekeeping && EncryptDecryptStorage("decrypt").user.permissions.housekeeping.PUT){
      getRequest();
    }
    else {
      navigate("/404");
    }
  }, []);

  return (
    <>
      <Layout tab={"housekeeping #"+id}>
        {changeStatusModal[0] ? <ChangeStatusPage malfunctionId={changeStatusModal[1]} status={changeStatusModal[2]} showModal={changeStatusModal} setShowModal={setChangeStatusModal} /> : ""}
        { 
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to={"/housekeeping/detalhes/"+id} state={state ? {from: state.from ? state.from : "", page: state.page ? state.page : "", previous: state.previous ? state.previous : "", next: state.next ? state.next : ""}: ""}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>

          <div className={styles.logs}>
        {
          logs.map((log, index) => (
            <div className={styles.log} style={{backgroundColor: index % 2 === 0 ? "var(--background-content)" : "" }}>
                <p>{GetDate(log.date)}</p>
                {log.user} {log.log}.
                {log.reason ?
                <div className={styles.reason}>
                     {log.reason}
                </div>
            : ""}
            </div>
            ))
        }
        </div>

        
        </div>
        }
      </Layout>
    </>
  );
}

export default LogsHousekeeping;